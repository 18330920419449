/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Northwind PR API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from "url";
import * as isomorphicFetch from "isomorphic-fetch";
import { Configuration } from "./configuration";
import { EnPrrTypeDetail } from "../../../components/Pages/Nw/PersonnelRequisition/CreateNew/PrrReducer";

const BASE_PATH = "/".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = isomorphicFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface AddressContact
 */
export interface AddressContact {
    /**
     * 
     * @type {string}
     * @memberof AddressContact
     */
    addressContactGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressContact
     */
    vendorAddressGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressContact
     */
    vendorGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressContact
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressContact
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressContact
     */
    titleName?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressContact
     */
    emailID?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressContact
     */
    phoneID?: string;
    /**
     * 
     * @type {number}
     * @memberof AddressContact
     */
    sequenceNo?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressContact
     */
    addressCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressContact
     */
    companyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressContact
     */
    vendorID?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressContact
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressContact
     */
    tranType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddressContact
     */
    isPrimary?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof AddressContact
     */
    createdOn?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AddressContact
     */
    updatedOn?: Date;
}
/**
 * 
 * @export
 * @interface ApproveComment
 */
export interface ApproveComment {
    /**
     * 
     * @type {number}
     * @memberof ApproveComment
     */
    currentLevel?: number;
    /**
     * 
     * @type {string}
     * @memberof ApproveComment
     */
    comment?: string;
}
/**
 * 
 * @export
 * @interface Assembly
 */
export interface Assembly {
    /**
     * 
     * @type {Array<TypeInfo>}
     * @memberof Assembly
     */
    definedTypes?: Array<TypeInfo>;
    /**
     * 
     * @type {Array<Type>}
     * @memberof Assembly
     */
    exportedTypes?: Array<Type>;
    /**
     * 
     * @type {string}
     * @memberof Assembly
     */
    codeBase?: string;
    /**
     * 
     * @type {MethodInfo}
     * @memberof Assembly
     */
    entryPoint?: MethodInfo;
    /**
     * 
     * @type {string}
     * @memberof Assembly
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof Assembly
     */
    imageRuntimeVersion?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Assembly
     */
    isDynamic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Assembly
     */
    location?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Assembly
     */
    reflectionOnly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Assembly
     */
    isCollectible?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Assembly
     */
    isFullyTrusted?: boolean;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof Assembly
     */
    customAttributes?: Array<CustomAttributeData>;
    /**
     * 
     * @type {string}
     * @memberof Assembly
     */
    escapedCodeBase?: string;
    /**
     * 
     * @type {Module}
     * @memberof Assembly
     */
    manifestModule?: Module;
    /**
     * 
     * @type {Array<Module>}
     * @memberof Assembly
     */
    modules?: Array<Module>;
    /**
     * 
     * @type {boolean}
     * @memberof Assembly
     */
    globalAssemblyCache?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Assembly
     */
    hostContext?: number;
    /**
     * 
     * @type {SecurityRuleSet}
     * @memberof Assembly
     */
    securityRuleSet?: SecurityRuleSet;
}
/**
 * 
 * @export
 * @interface Attachment
 */
export interface Attachment {
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    attachmentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    filePath?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    resourceGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    mimeType?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    attachmentType?: string;
    /**
     * 
     * @type {Date}
     * @memberof Attachment
     */
    createdOn?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Attachment
     */
    updatedOn?: Date;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CallingConventions {
    NUMBER_1 = <any>1,
    NUMBER_2 = <any>2,
    NUMBER_3 = <any>3,
    NUMBER_32 = <any>32,
    NUMBER_64 = <any>64
}
/**
 * 
 * @export
 * @interface ConstructorInfo
 */
export interface ConstructorInfo {
    /**
     * 
     * @type {string}
     * @memberof ConstructorInfo
     */
    name?: string;
    /**
     * 
     * @type {Type}
     * @memberof ConstructorInfo
     */
    declaringType?: Type;
    /**
     * 
     * @type {Type}
     * @memberof ConstructorInfo
     */
    reflectedType?: Type;
    /**
     * 
     * @type {Module}
     * @memberof ConstructorInfo
     */
    module?: Module;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof ConstructorInfo
     */
    customAttributes?: Array<CustomAttributeData>;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isCollectible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ConstructorInfo
     */
    metadataToken?: number;
    /**
     * 
     * @type {MethodAttributes}
     * @memberof ConstructorInfo
     */
    attributes?: MethodAttributes;
    /**
     * 
     * @type {MethodImplAttributes}
     * @memberof ConstructorInfo
     */
    methodImplementationFlags?: MethodImplAttributes;
    /**
     * 
     * @type {CallingConventions}
     * @memberof ConstructorInfo
     */
    callingConvention?: CallingConventions;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isAbstract?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isConstructor?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isFinal?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isHideBySig?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isSpecialName?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isStatic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isVirtual?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isFamily?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isFamilyAndAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isFamilyOrAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isPrivate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isPublic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isConstructedGenericMethod?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isGenericMethod?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isGenericMethodDefinition?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    containsGenericParameters?: boolean;
    /**
     * 
     * @type {RuntimeMethodHandle}
     * @memberof ConstructorInfo
     */
    methodHandle?: RuntimeMethodHandle;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isSecurityCritical?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isSecuritySafeCritical?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isSecurityTransparent?: boolean;
    /**
     * 
     * @type {MemberTypes}
     * @memberof ConstructorInfo
     */
    memberType?: MemberTypes;
}
/**
 * 
 * @export
 * @interface Country
 */
export interface Country {
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    countryId?: string;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    countryName?: string;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    alpha2Code?: string;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    alpha3Code?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Country
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Country
     */
    numericCode?: number;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    tld?: string;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    callingCode?: string;
}
/**
 * 
 * @export
 * @interface CountryDelta
 */
export interface CountryDelta {
    /**
     * 
     * @type {DeltaItemKind}
     * @memberof CountryDelta
     */
    kind?: DeltaItemKind;
    /**
     * 
     * @type {Type}
     * @memberof CountryDelta
     */
    structuredType?: Type;
    /**
     * 
     * @type {Type}
     * @memberof CountryDelta
     */
    expectedClrType?: Type;
    /**
     * 
     * @type {Array<string>}
     * @memberof CountryDelta
     */
    updatableProperties?: Array<string>;
}
/**
 * 
 * @export
 * @interface CountrySingleResult
 */
export interface CountrySingleResult {
    /**
     * 
     * @type {Array<Country>}
     * @memberof CountrySingleResult
     */
    queryable?: Array<Country>;
}
/**
 * 
 * @export
 * @interface CustomAttributeData
 */
export interface CustomAttributeData {
    /**
     * 
     * @type {Type}
     * @memberof CustomAttributeData
     */
    attributeType?: Type;
    /**
     * 
     * @type {ConstructorInfo}
     * @memberof CustomAttributeData
     */
    constructor?: ConstructorInfo;
    /**
     * 
     * @type {Array<CustomAttributeTypedArgument>}
     * @memberof CustomAttributeData
     */
    constructorArguments?: Array<CustomAttributeTypedArgument>;
    /**
     * 
     * @type {Array<CustomAttributeNamedArgument>}
     * @memberof CustomAttributeData
     */
    namedArguments?: Array<CustomAttributeNamedArgument>;
}
/**
 * 
 * @export
 * @interface CustomAttributeNamedArgument
 */
export interface CustomAttributeNamedArgument {
    /**
     * 
     * @type {MemberInfo}
     * @memberof CustomAttributeNamedArgument
     */
    memberInfo?: MemberInfo;
    /**
     * 
     * @type {CustomAttributeTypedArgument}
     * @memberof CustomAttributeNamedArgument
     */
    typedValue?: CustomAttributeTypedArgument;
    /**
     * 
     * @type {string}
     * @memberof CustomAttributeNamedArgument
     */
    memberName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomAttributeNamedArgument
     */
    isField?: boolean;
}
/**
 * 
 * @export
 * @interface CustomAttributeTypedArgument
 */
export interface CustomAttributeTypedArgument {
    /**
     * 
     * @type {Type}
     * @memberof CustomAttributeTypedArgument
     */
    argumentType?: Type;
    /**
     * 
     * @type {any}
     * @memberof CustomAttributeTypedArgument
     */
    value?: any;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DeltaItemKind {
    NUMBER_0 = <any>0,
    NUMBER_1 = <any>1,
    NUMBER_2 = <any>2,
    NUMBER_3 = <any>3,
    NUMBER_4 = <any>4
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum EdmContainerElementKind {
    NUMBER_0 = <any>0,
    NUMBER_1 = <any>1,
    NUMBER_2 = <any>2,
    NUMBER_3 = <any>3,
    NUMBER_4 = <any>4
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum EdmExpressionKind {
    NUMBER_0 = <any>0,
    NUMBER_1 = <any>1,
    NUMBER_2 = <any>2,
    NUMBER_3 = <any>3,
    NUMBER_4 = <any>4,
    NUMBER_5 = <any>5,
    NUMBER_6 = <any>6,
    NUMBER_7 = <any>7,
    NUMBER_8 = <any>8,
    NUMBER_9 = <any>9,
    NUMBER_10 = <any>10,
    NUMBER_11 = <any>11,
    NUMBER_12 = <any>12,
    NUMBER_13 = <any>13,
    NUMBER_14 = <any>14,
    NUMBER_15 = <any>15,
    NUMBER_16 = <any>16,
    NUMBER_17 = <any>17,
    NUMBER_18 = <any>18,
    NUMBER_19 = <any>19,
    NUMBER_20 = <any>20,
    NUMBER_21 = <any>21,
    NUMBER_22 = <any>22,
    NUMBER_23 = <any>23,
    NUMBER_24 = <any>24,
    NUMBER_25 = <any>25
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum EdmSchemaElementKind {
    NUMBER_0 = <any>0,
    NUMBER_1 = <any>1,
    NUMBER_2 = <any>2,
    NUMBER_3 = <any>3,
    NUMBER_4 = <any>4,
    NUMBER_5 = <any>5
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum EdmTypeKind {
    NUMBER_0 = <any>0,
    NUMBER_1 = <any>1,
    NUMBER_2 = <any>2,
    NUMBER_3 = <any>3,
    NUMBER_4 = <any>4,
    NUMBER_5 = <any>5,
    NUMBER_6 = <any>6,
    NUMBER_7 = <any>7,
    NUMBER_8 = <any>8,
    NUMBER_9 = <any>9
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum EventAttributes {
    NUMBER_0 = <any>0,
    NUMBER_512 = <any>512,
    NUMBER_1024 = <any>1024
}
/**
 * 
 * @export
 * @interface EventInfo
 */
export interface EventInfo {
    /**
     * 
     * @type {string}
     * @memberof EventInfo
     */
    name?: string;
    /**
     * 
     * @type {Type}
     * @memberof EventInfo
     */
    declaringType?: Type;
    /**
     * 
     * @type {Type}
     * @memberof EventInfo
     */
    reflectedType?: Type;
    /**
     * 
     * @type {Module}
     * @memberof EventInfo
     */
    module?: Module;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof EventInfo
     */
    customAttributes?: Array<CustomAttributeData>;
    /**
     * 
     * @type {boolean}
     * @memberof EventInfo
     */
    isCollectible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EventInfo
     */
    metadataToken?: number;
    /**
     * 
     * @type {MemberTypes}
     * @memberof EventInfo
     */
    memberType?: MemberTypes;
    /**
     * 
     * @type {EventAttributes}
     * @memberof EventInfo
     */
    attributes?: EventAttributes;
    /**
     * 
     * @type {boolean}
     * @memberof EventInfo
     */
    isSpecialName?: boolean;
    /**
     * 
     * @type {MethodInfo}
     * @memberof EventInfo
     */
    addMethod?: MethodInfo;
    /**
     * 
     * @type {MethodInfo}
     * @memberof EventInfo
     */
    removeMethod?: MethodInfo;
    /**
     * 
     * @type {MethodInfo}
     * @memberof EventInfo
     */
    raiseMethod?: MethodInfo;
    /**
     * 
     * @type {boolean}
     * @memberof EventInfo
     */
    isMulticast?: boolean;
    /**
     * 
     * @type {Type}
     * @memberof EventInfo
     */
    eventHandlerType?: Type;
}
/**
 * 
 * @export
 * @interface ExternalApprovalLog
 */
export interface ExternalApprovalLog {
    /**
     * 
     * @type {string}
     * @memberof ExternalApprovalLog
     */
    externalApprovalLogGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalApprovalLog
     */
    action?: string;
    /**
     * 
     * @type {NotificationType}
     * @memberof ExternalApprovalLog
     */
    type?: NotificationType;
    /**
     * 
     * @type {string}
     * @memberof ExternalApprovalLog
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalApprovalLog
     */
    secondaryId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalApprovalLog
     */
    approverId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalApprovalLog
     */
    designatorId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalApprovalLog
     */
    comments?: string;
    /**
     * 
     * @type {number}
     * @memberof ExternalApprovalLog
     */
    approverLevel?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ExternalApprovalLog
     */
    isExecuted?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FieldAttributes {
    NUMBER_0 = <any>0,
    NUMBER_1 = <any>1,
    NUMBER_2 = <any>2,
    NUMBER_3 = <any>3,
    NUMBER_4 = <any>4,
    NUMBER_5 = <any>5,
    NUMBER_6 = <any>6,
    NUMBER_7 = <any>7,
    NUMBER_16 = <any>16,
    NUMBER_32 = <any>32,
    NUMBER_64 = <any>64,
    NUMBER_128 = <any>128,
    NUMBER_256 = <any>256,
    NUMBER_512 = <any>512,
    NUMBER_1024 = <any>1024,
    NUMBER_4096 = <any>4096,
    NUMBER_8192 = <any>8192,
    NUMBER_32768 = <any>32768,
    NUMBER_38144 = <any>38144
}
/**
 * 
 * @export
 * @interface FieldInfo
 */
export interface FieldInfo {
    /**
     * 
     * @type {string}
     * @memberof FieldInfo
     */
    name?: string;
    /**
     * 
     * @type {Type}
     * @memberof FieldInfo
     */
    declaringType?: Type;
    /**
     * 
     * @type {Type}
     * @memberof FieldInfo
     */
    reflectedType?: Type;
    /**
     * 
     * @type {Module}
     * @memberof FieldInfo
     */
    module?: Module;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof FieldInfo
     */
    customAttributes?: Array<CustomAttributeData>;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    isCollectible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FieldInfo
     */
    metadataToken?: number;
    /**
     * 
     * @type {MemberTypes}
     * @memberof FieldInfo
     */
    memberType?: MemberTypes;
    /**
     * 
     * @type {FieldAttributes}
     * @memberof FieldInfo
     */
    attributes?: FieldAttributes;
    /**
     * 
     * @type {Type}
     * @memberof FieldInfo
     */
    fieldType?: Type;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    isInitOnly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    isLiteral?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    isNotSerialized?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    isPinvokeImpl?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    isSpecialName?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    isStatic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    isAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    isFamily?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    isFamilyAndAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    isFamilyOrAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    isPrivate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    isPublic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    isSecurityCritical?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    isSecuritySafeCritical?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    isSecurityTransparent?: boolean;
    /**
     * 
     * @type {RuntimeFieldHandle}
     * @memberof FieldInfo
     */
    fieldHandle?: RuntimeFieldHandle;
}
/**
 * 
 * @export
 * @interface FileUpload
 */
export interface FileUpload {
    /**
     * 
     * @type {string}
     * @memberof FileUpload
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof FileUpload
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof FileUpload
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof FileUpload
     */
    size?: string;
    /**
     * 
     * @type {string}
     * @memberof FileUpload
     */
    data?: string;
    /**
     * 
     * @type {string}
     * @memberof FileUpload
     */
    attachmentType?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum GenericParameterAttributes {
    NUMBER_0 = <any>0,
    NUMBER_1 = <any>1,
    NUMBER_2 = <any>2,
    NUMBER_3 = <any>3,
    NUMBER_4 = <any>4,
    NUMBER_8 = <any>8,
    NUMBER_16 = <any>16,
    NUMBER_28 = <any>28
}
/**
 * 
 * @export
 * @interface Group
 */
export interface Group {
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    groupGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    groupName?: string;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface GroupPermissionPost
 */
export interface GroupPermissionPost {
    /**
     * 
     * @type {string}
     * @memberof GroupPermissionPost
     */
    groupPermissionGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupPermissionPost
     */
    groupGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupPermissionPost
     */
    resourceGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupPermissionPost
     */
    resourceActionGuid?: string;
}
/**
 * 
 * @export
 * @interface GroupPost
 */
export interface GroupPost {
    /**
     * 
     * @type {string}
     * @memberof GroupPost
     */
    groupUserGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupPost
     */
    groupGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupPost
     */
    userGuid?: string;
}
/**
 * 
 * @export
 * @interface GroupSingleResult
 */
export interface GroupSingleResult {
    /**
     * 
     * @type {Array<Group>}
     * @memberof GroupSingleResult
     */
    queryable?: Array<Group>;
}
/**
 * 
 * @export
 * @interface GroupUser
 */
export interface GroupUser {
    /**
     * 
     * @type {string}
     * @memberof GroupUser
     */
    groupUserGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupUser
     */
    groupGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupUser
     */
    userGuid?: string;
    /**
     * 
     * @type {Group}
     * @memberof GroupUser
     */
    group?: Group;
    /**
     * 
     * @type {User}
     * @memberof GroupUser
     */
    user?: User;
}
/**
 * 
 * @export
 * @interface ICustomAttributeProvider
 */
export interface ICustomAttributeProvider {
}
/**
 * 
 * @export
 * @interface IEdmDirectValueAnnotationsManager
 */
export interface IEdmDirectValueAnnotationsManager {
}
/**
 * 
 * @export
 * @interface IEdmEntityContainer
 */
export interface IEdmEntityContainer {
    /**
     * 
     * @type {Array<IEdmEntityContainerElement>}
     * @memberof IEdmEntityContainer
     */
    elements?: Array<IEdmEntityContainerElement>;
    /**
     * 
     * @type {EdmSchemaElementKind}
     * @memberof IEdmEntityContainer
     */
    schemaElementKind?: EdmSchemaElementKind;
    /**
     * 
     * @type {string}
     * @memberof IEdmEntityContainer
     */
    namespace?: string;
    /**
     * 
     * @type {string}
     * @memberof IEdmEntityContainer
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface IEdmEntityContainerElement
 */
export interface IEdmEntityContainerElement {
    /**
     * 
     * @type {EdmContainerElementKind}
     * @memberof IEdmEntityContainerElement
     */
    containerElementKind?: EdmContainerElementKind;
    /**
     * 
     * @type {IEdmEntityContainer}
     * @memberof IEdmEntityContainerElement
     */
    container?: IEdmEntityContainer;
    /**
     * 
     * @type {string}
     * @memberof IEdmEntityContainerElement
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface IEdmExpression
 */
export interface IEdmExpression {
    /**
     * 
     * @type {EdmExpressionKind}
     * @memberof IEdmExpression
     */
    expressionKind?: EdmExpressionKind;
}
/**
 * 
 * @export
 * @interface IEdmModel
 */
export interface IEdmModel {
    /**
     * 
     * @type {Array<IEdmSchemaElement>}
     * @memberof IEdmModel
     */
    schemaElements?: Array<IEdmSchemaElement>;
    /**
     * 
     * @type {Array<IEdmVocabularyAnnotation>}
     * @memberof IEdmModel
     */
    vocabularyAnnotations?: Array<IEdmVocabularyAnnotation>;
    /**
     * 
     * @type {Array<IEdmModel>}
     * @memberof IEdmModel
     */
    referencedModels?: Array<IEdmModel>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IEdmModel
     */
    declaredNamespaces?: Array<string>;
    /**
     * 
     * @type {IEdmDirectValueAnnotationsManager}
     * @memberof IEdmModel
     */
    directValueAnnotationsManager?: IEdmDirectValueAnnotationsManager;
    /**
     * 
     * @type {IEdmEntityContainer}
     * @memberof IEdmModel
     */
    entityContainer?: IEdmEntityContainer;
}
/**
 * 
 * @export
 * @interface IEdmSchemaElement
 */
export interface IEdmSchemaElement {
    /**
     * 
     * @type {EdmSchemaElementKind}
     * @memberof IEdmSchemaElement
     */
    schemaElementKind?: EdmSchemaElementKind;
    /**
     * 
     * @type {string}
     * @memberof IEdmSchemaElement
     */
    namespace?: string;
    /**
     * 
     * @type {string}
     * @memberof IEdmSchemaElement
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface IEdmTerm
 */
export interface IEdmTerm {
    /**
     * 
     * @type {IEdmTypeReference}
     * @memberof IEdmTerm
     */
    type?: IEdmTypeReference;
    /**
     * 
     * @type {string}
     * @memberof IEdmTerm
     */
    appliesTo?: string;
    /**
     * 
     * @type {string}
     * @memberof IEdmTerm
     */
    defaultValue?: string;
    /**
     * 
     * @type {EdmSchemaElementKind}
     * @memberof IEdmTerm
     */
    schemaElementKind?: EdmSchemaElementKind;
    /**
     * 
     * @type {string}
     * @memberof IEdmTerm
     */
    namespace?: string;
    /**
     * 
     * @type {string}
     * @memberof IEdmTerm
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface IEdmType
 */
export interface IEdmType {
    /**
     * 
     * @type {EdmTypeKind}
     * @memberof IEdmType
     */
    typeKind?: EdmTypeKind;
}
/**
 * 
 * @export
 * @interface IEdmTypeReference
 */
export interface IEdmTypeReference {
    /**
     * 
     * @type {boolean}
     * @memberof IEdmTypeReference
     */
    isNullable?: boolean;
    /**
     * 
     * @type {IEdmType}
     * @memberof IEdmTypeReference
     */
    definition?: IEdmType;
}
/**
 * 
 * @export
 * @interface IEdmVocabularyAnnotatable
 */
export interface IEdmVocabularyAnnotatable {
}
/**
 * 
 * @export
 * @interface IEdmVocabularyAnnotation
 */
export interface IEdmVocabularyAnnotation {
    /**
     * 
     * @type {string}
     * @memberof IEdmVocabularyAnnotation
     */
    qualifier?: string;
    /**
     * 
     * @type {IEdmTerm}
     * @memberof IEdmVocabularyAnnotation
     */
    term?: IEdmTerm;
    /**
     * 
     * @type {IEdmVocabularyAnnotatable}
     * @memberof IEdmVocabularyAnnotation
     */
    target?: IEdmVocabularyAnnotatable;
    /**
     * 
     * @type {IEdmExpression}
     * @memberof IEdmVocabularyAnnotation
     */
    value?: IEdmExpression;
}
/**
 * 
 * @export
 * @interface IntPtr
 */
export interface IntPtr {
}
/**
 * 
 * @export
 * @interface Invoice
 */
export interface Invoice {
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    invoiceGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    fiscalYear?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    pdNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    invoiceID?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    pdSubNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    vendorInvoiceNo?: string;
    /**
     * 
     * @type {Date}
     * @memberof Invoice
     */
    invoiceDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    poNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    voucherNo?: string;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    poTotalAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    poRunningTotalAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    vendorAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    representative?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    companyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    vendorName?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    vendorCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    addressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    addressLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    addressLine3?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    zip?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof Invoice
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    createdOn?: Date;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    updatedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof Invoice
     */
    updatedOn?: Date;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    status?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    isRejected?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    isRevised?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    isEditable?: boolean;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof Invoice
     */
    attachments?: Array<Attachment>;
    /**
     * 
     * @type {Array<InvoiceCoding>}
     * @memberof Invoice
     */
    invoiceCodings?: Array<InvoiceCoding>;
    /**
     * 
     * @type {Array<InvoiceApprovalFlow>}
     * @memberof Invoice
     */
    reviewers?: Array<InvoiceApprovalFlow>;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    projectNumber?: string;
}
/**
 * 
 * @export
 * @interface InvoiceAdditionalComment
 */
export interface InvoiceAdditionalComment {
    /**
     * 
     * @type {string}
     * @memberof InvoiceAdditionalComment
     */
    comments?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAdditionalComment
     */
    resourceGuid?: string;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceAdditionalComment
     */
    createdOn?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceAdditionalComment
     */
    updatedOn?: Date;
}
/**
 * 
 * @export
 * @interface InvoiceApproval
 */
export interface InvoiceApproval {
    /**
     * 
     * @type {string}
     * @memberof InvoiceApproval
     */
    invoiceApprovalFlowGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceApproval
     */
    invoiceGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceApproval
     */
    role?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceApproval
     */
    roleGuid?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceApproval
     */
    level?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceApproval
     */
    stage?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceApproval
     */
    userGuid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceApproval
     */
    isRejected?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceApproval
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceApproval
     */
    isCompleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceApproval
     */
    isRevised?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceApproval
     */
    createdOn?: Date;
    /**
     * 
     * @type {string}
     * @memberof InvoiceApproval
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceApproval
     */
    updatedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceApproval
     */
    updatedOn?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceApproval
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceApproval
     */
    isOptional?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceApproval
     */
    displayName?: string;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceApproval
     */
    timeStamp?: Date;
    /**
     * 
     * @type {string}
     * @memberof InvoiceApproval
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceApproval
     */
    completedBy?: string;
}
/**
 * 
 * @export
 * @interface InvoiceApprovalFlow
 */
export interface InvoiceApprovalFlow {
    /**
     * 
     * @type {string}
     * @memberof InvoiceApprovalFlow
     */
    invoiceApprovalFlowGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceApprovalFlow
     */
    invoiceGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceApprovalFlow
     */
    role?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceApprovalFlow
     */
    roleGuid?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceApprovalFlow
     */
    level?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceApprovalFlow
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceApprovalFlow
     */
    userGuid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceApprovalFlow
     */
    isRejected?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceApprovalFlow
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceApprovalFlow
     */
    isCompleted?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceApprovalFlow
     */
    createdOn?: Date;
    /**
     * 
     * @type {string}
     * @memberof InvoiceApprovalFlow
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceApprovalFlow
     */
    updatedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceApprovalFlow
     */
    updatedOn?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceApprovalFlow
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceApprovalFlow
     */
    isOptional?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceApprovalFlow
     */
    stage?: string;
}
/**
 * 
 * @export
 * @interface InvoiceApprovalModel
 */
export interface InvoiceApprovalModel {
    /**
     * 
     * @type {string}
     * @memberof InvoiceApprovalModel
     */
    invoiceGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceApprovalModel
     */
    role?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceApprovalModel
     */
    roleGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceApprovalModel
     */
    userGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceApprovalModel
     */
    level?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceApprovalModel
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceApprovalModel
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceApprovalModel
     */
    createdOn?: Date;
}
/**
 * 
 * @export
 * @interface InvoiceCoding
 */
export interface InvoiceCoding {
    /**
     * 
     * @type {string}
     * @memberof InvoiceCoding
     */
    invoiceCodingGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCoding
     */
    invoiceGuid?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceCoding
     */
    sno?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceCoding
     */
    poLineNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceCoding
     */
    voucherLineNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCoding
     */
    poNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCoding
     */
    projectNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCoding
     */
    orgId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCoding
     */
    accountType?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCoding
     */
    accountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCoding
     */
    accountGroupCode?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceCoding
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceCoding
     */
    total?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCoding
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceCoding
     */
    createdOn?: Date;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCoding
     */
    updatedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceCoding
     */
    updatedOn?: Date;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCoding
     */
    accountName?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCoding
     */
    projectName?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCoding
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCoding
     */
    poLineDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceCoding
     */
    committedAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceCoding
     */
    runningTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceCoding
     */
    remainingAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceCoding
     */
    costToDateAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCoding
     */
    emplID?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCoding
     */
    voucherNumber?: string;
}
/**
 * 
 * @export
 * @interface InvoiceIntegration
 */
export interface InvoiceIntegration {
    /**
     * 
     * @type {string}
     * @memberof InvoiceIntegration
     */
    year?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceIntegration
     */
    month?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceIntegration
     */
    subPeriod?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceIntegration
     */
    pushToCostPoint?: boolean;
    /**
     * 
     * @type {Invoice}
     * @memberof InvoiceIntegration
     */
    invoice?: Invoice;
}
/**
 * 
 * @export
 * @interface InvoiceModel
 */
export interface InvoiceModel {
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    invoiceGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    vendorInvoiceNo?: string;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceModel
     */
    invoiceDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    poNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    invoiceID?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceModel
     */
    poTotalAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceModel
     */
    poRunningTotalAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceModel
     */
    vendorAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    representative?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    companyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    vendorName?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    vendorCode?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    status?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceModel
     */
    isRejected?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceModel
     */
    isRevised?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    actionType?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceModel
     */
    createdOn?: Date;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    updatedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceModel
     */
    updatedOn?: Date;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    approvalComment?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    projectNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    voucherNo?: string;
    /**
     * 
     * @type {Array<FileUpload>}
     * @memberof InvoiceModel
     */
    attachments?: Array<FileUpload>;
    /**
     * 
     * @type {Array<InvoiceCoding>}
     * @memberof InvoiceModel
     */
    invoiceCodings?: Array<InvoiceCoding>;
    /**
     * 
     * @type {Array<InvoiceApprovalFlow>}
     * @memberof InvoiceModel
     */
    reviewers?: Array<InvoiceApprovalFlow>;
}
/**
 * 
 * @export
 * @interface InvoiceSingleResult
 */
export interface InvoiceSingleResult {
    /**
     * 
     * @type {Array<Invoice>}
     * @memberof InvoiceSingleResult
     */
    queryable?: Array<Invoice>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum LayoutKind {
    NUMBER_0 = <any>0,
    NUMBER_2 = <any>2,
    NUMBER_3 = <any>3
}
/**
 * 
 * @export
 * @interface LineItemAccountPost
 */
export interface LineItemAccountPost {
    /**
     * 
     * @type {string}
     * @memberof LineItemAccountPost
     */
    projID?: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemAccountPost
     */
    accountID?: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemAccountPost
     */
    orgID?: string;
}
/**
 * 
 * @export
 * @interface MemberInfo
 */
export interface MemberInfo {
    /**
     * 
     * @type {MemberTypes}
     * @memberof MemberInfo
     */
    memberType?: MemberTypes;
    /**
     * 
     * @type {Type}
     * @memberof MemberInfo
     */
    declaringType?: Type;
    /**
     * 
     * @type {Type}
     * @memberof MemberInfo
     */
    reflectedType?: Type;
    /**
     * 
     * @type {string}
     * @memberof MemberInfo
     */
    name?: string;
    /**
     * 
     * @type {Module}
     * @memberof MemberInfo
     */
    module?: Module;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof MemberInfo
     */
    customAttributes?: Array<CustomAttributeData>;
    /**
     * 
     * @type {boolean}
     * @memberof MemberInfo
     */
    isCollectible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MemberInfo
     */
    metadataToken?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum MemberTypes {
    NUMBER_1 = <any>1,
    NUMBER_2 = <any>2,
    NUMBER_4 = <any>4,
    NUMBER_8 = <any>8,
    NUMBER_16 = <any>16,
    NUMBER_32 = <any>32,
    NUMBER_64 = <any>64,
    NUMBER_128 = <any>128,
    NUMBER_191 = <any>191
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum MethodAttributes {
    NUMBER_0 = <any>0,
    NUMBER_1 = <any>1,
    NUMBER_2 = <any>2,
    NUMBER_3 = <any>3,
    NUMBER_4 = <any>4,
    NUMBER_5 = <any>5,
    NUMBER_6 = <any>6,
    NUMBER_7 = <any>7,
    NUMBER_8 = <any>8,
    NUMBER_16 = <any>16,
    NUMBER_32 = <any>32,
    NUMBER_64 = <any>64,
    NUMBER_128 = <any>128,
    NUMBER_256 = <any>256,
    NUMBER_512 = <any>512,
    NUMBER_1024 = <any>1024,
    NUMBER_2048 = <any>2048,
    NUMBER_4096 = <any>4096,
    NUMBER_8192 = <any>8192,
    NUMBER_16384 = <any>16384,
    NUMBER_32768 = <any>32768,
    NUMBER_53248 = <any>53248
}
/**
 * 
 * @export
 * @interface MethodBase
 */
export interface MethodBase {
    /**
     * 
     * @type {MemberTypes}
     * @memberof MethodBase
     */
    memberType?: MemberTypes;
    /**
     * 
     * @type {string}
     * @memberof MethodBase
     */
    name?: string;
    /**
     * 
     * @type {Type}
     * @memberof MethodBase
     */
    declaringType?: Type;
    /**
     * 
     * @type {Type}
     * @memberof MethodBase
     */
    reflectedType?: Type;
    /**
     * 
     * @type {Module}
     * @memberof MethodBase
     */
    module?: Module;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof MethodBase
     */
    customAttributes?: Array<CustomAttributeData>;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isCollectible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MethodBase
     */
    metadataToken?: number;
    /**
     * 
     * @type {MethodAttributes}
     * @memberof MethodBase
     */
    attributes?: MethodAttributes;
    /**
     * 
     * @type {MethodImplAttributes}
     * @memberof MethodBase
     */
    methodImplementationFlags?: MethodImplAttributes;
    /**
     * 
     * @type {CallingConventions}
     * @memberof MethodBase
     */
    callingConvention?: CallingConventions;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isAbstract?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isConstructor?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isFinal?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isHideBySig?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isSpecialName?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isStatic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isVirtual?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isFamily?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isFamilyAndAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isFamilyOrAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isPrivate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isPublic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isConstructedGenericMethod?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isGenericMethod?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isGenericMethodDefinition?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    containsGenericParameters?: boolean;
    /**
     * 
     * @type {RuntimeMethodHandle}
     * @memberof MethodBase
     */
    methodHandle?: RuntimeMethodHandle;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isSecurityCritical?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isSecuritySafeCritical?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isSecurityTransparent?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum MethodImplAttributes {
    NUMBER_0 = <any>0,
    NUMBER_1 = <any>1,
    NUMBER_2 = <any>2,
    NUMBER_3 = <any>3,
    NUMBER_4 = <any>4,
    NUMBER_8 = <any>8,
    NUMBER_16 = <any>16,
    NUMBER_32 = <any>32,
    NUMBER_64 = <any>64,
    NUMBER_128 = <any>128,
    NUMBER_256 = <any>256,
    NUMBER_512 = <any>512,
    NUMBER_4096 = <any>4096,
    NUMBER_65535 = <any>65535
}
/**
 * 
 * @export
 * @interface MethodInfo
 */
export interface MethodInfo {
    /**
     * 
     * @type {string}
     * @memberof MethodInfo
     */
    name?: string;
    /**
     * 
     * @type {Type}
     * @memberof MethodInfo
     */
    declaringType?: Type;
    /**
     * 
     * @type {Type}
     * @memberof MethodInfo
     */
    reflectedType?: Type;
    /**
     * 
     * @type {Module}
     * @memberof MethodInfo
     */
    module?: Module;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof MethodInfo
     */
    customAttributes?: Array<CustomAttributeData>;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isCollectible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MethodInfo
     */
    metadataToken?: number;
    /**
     * 
     * @type {MethodAttributes}
     * @memberof MethodInfo
     */
    attributes?: MethodAttributes;
    /**
     * 
     * @type {MethodImplAttributes}
     * @memberof MethodInfo
     */
    methodImplementationFlags?: MethodImplAttributes;
    /**
     * 
     * @type {CallingConventions}
     * @memberof MethodInfo
     */
    callingConvention?: CallingConventions;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isAbstract?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isConstructor?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isFinal?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isHideBySig?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isSpecialName?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isStatic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isVirtual?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isFamily?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isFamilyAndAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isFamilyOrAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isPrivate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isPublic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isConstructedGenericMethod?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isGenericMethod?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isGenericMethodDefinition?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    containsGenericParameters?: boolean;
    /**
     * 
     * @type {RuntimeMethodHandle}
     * @memberof MethodInfo
     */
    methodHandle?: RuntimeMethodHandle;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isSecurityCritical?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isSecuritySafeCritical?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isSecurityTransparent?: boolean;
    /**
     * 
     * @type {MemberTypes}
     * @memberof MethodInfo
     */
    memberType?: MemberTypes;
    /**
     * 
     * @type {ParameterInfo}
     * @memberof MethodInfo
     */
    returnParameter?: ParameterInfo;
    /**
     * 
     * @type {Type}
     * @memberof MethodInfo
     */
    returnType?: Type;
    /**
     * 
     * @type {ICustomAttributeProvider}
     * @memberof MethodInfo
     */
    returnTypeCustomAttributes?: ICustomAttributeProvider;
}
/**
 * 
 * @export
 * @interface Module
 */
export interface Module {
    /**
     * 
     * @type {Assembly}
     * @memberof Module
     */
    assembly?: Assembly;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    fullyQualifiedName?: string;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof Module
     */
    mdStreamVersion?: number;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    moduleVersionId?: string;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    scopeName?: string;
    /**
     * 
     * @type {ModuleHandle}
     * @memberof Module
     */
    moduleHandle?: ModuleHandle;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof Module
     */
    customAttributes?: Array<CustomAttributeData>;
    /**
     * 
     * @type {number}
     * @memberof Module
     */
    metadataToken?: number;
}
/**
 * 
 * @export
 * @interface ModuleHandle
 */
export interface ModuleHandle {
    /**
     * 
     * @type {number}
     * @memberof ModuleHandle
     */
    mdStreamVersion?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum NotificationType {
    NUMBER_0 = <any>0,
    NUMBER_1 = <any>1,
    NUMBER_2 = <any>2
}
/**
 * 
 * @export
 * @interface ODataEntitySetInfo
 */
export interface ODataEntitySetInfo {
    /**
     * 
     * @type {ODataTypeAnnotation}
     * @memberof ODataEntitySetInfo
     */
    typeAnnotation?: ODataTypeAnnotation;
    /**
     * 
     * @type {string}
     * @memberof ODataEntitySetInfo
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof ODataEntitySetInfo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ODataEntitySetInfo
     */
    title?: string;
}
/**
 * 
 * @export
 * @interface ODataFunctionImportInfo
 */
export interface ODataFunctionImportInfo {
    /**
     * 
     * @type {ODataTypeAnnotation}
     * @memberof ODataFunctionImportInfo
     */
    typeAnnotation?: ODataTypeAnnotation;
    /**
     * 
     * @type {string}
     * @memberof ODataFunctionImportInfo
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof ODataFunctionImportInfo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ODataFunctionImportInfo
     */
    title?: string;
}
/**
 * 
 * @export
 * @interface ODataServiceDocument
 */
export interface ODataServiceDocument {
    /**
     * 
     * @type {ODataTypeAnnotation}
     * @memberof ODataServiceDocument
     */
    typeAnnotation?: ODataTypeAnnotation;
    /**
     * 
     * @type {Array<ODataEntitySetInfo>}
     * @memberof ODataServiceDocument
     */
    entitySets?: Array<ODataEntitySetInfo>;
    /**
     * 
     * @type {Array<ODataSingletonInfo>}
     * @memberof ODataServiceDocument
     */
    singletons?: Array<ODataSingletonInfo>;
    /**
     * 
     * @type {Array<ODataFunctionImportInfo>}
     * @memberof ODataServiceDocument
     */
    functionImports?: Array<ODataFunctionImportInfo>;
}
/**
 * 
 * @export
 * @interface ODataSingletonInfo
 */
export interface ODataSingletonInfo {
    /**
     * 
     * @type {ODataTypeAnnotation}
     * @memberof ODataSingletonInfo
     */
    typeAnnotation?: ODataTypeAnnotation;
    /**
     * 
     * @type {string}
     * @memberof ODataSingletonInfo
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof ODataSingletonInfo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ODataSingletonInfo
     */
    title?: string;
}
/**
 * 
 * @export
 * @interface ODataTypeAnnotation
 */
export interface ODataTypeAnnotation {
    /**
     * 
     * @type {string}
     * @memberof ODataTypeAnnotation
     */
    typeName?: string;
}
/**
 * 
 * @export
 * @interface POLineAccount
 */
export interface POLineAccount {
    /**
     * 
     * @type {string}
     * @memberof POLineAccount
     */
    accTID?: string;
    /**
     * 
     * @type {string}
     * @memberof POLineAccount
     */
    accTNAME?: string;
    /**
     * 
     * @type {string}
     * @memberof POLineAccount
     */
    orGID?: string;
    /**
     * 
     * @type {string}
     * @memberof POLineAccount
     */
    orGNAME?: string;
    /**
     * 
     * @type {string}
     * @memberof POLineAccount
     */
    mMPOLNACCTPROJID?: string;
    /**
     * 
     * @type {string}
     * @memberof POLineAccount
     */
    proJNAME?: string;
    /**
     * 
     * @type {string}
     * @memberof POLineAccount
     */
    pOID?: string;
    /**
     * 
     * @type {string}
     * @memberof POLineAccount
     */
    cpPurchaseOrderItemGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof POLineAccount
     */
    cpLineItemAccountGuid?: string;
    /**
     * 
     * @type {number}
     * @memberof POLineAccount
     */
    pOLNNO?: number;
    /**
     * 
     * @type {Date}
     * @memberof POLineAccount
     */
    createdOn?: Date;
    /**
     * 
     * @type {Date}
     * @memberof POLineAccount
     */
    updatedOn?: Date;
    /**
     * 
     * @type {string}
     * @memberof POLineAccount
     */
    accountGroupCode?: string;
}
/**
 * 
 * @export
 * @interface POLineItem
 */
export interface POLineItem {
    /**
     * 
     * @type {number}
     * @memberof POLineItem
     */
    orderedAmount?: number;
    /**
     * 
     * @type {Date}
     * @memberof POLineItem
     */
    endDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof POLineItem
     */
    startDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof POLineItem
     */
    lineDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof POLineItem
     */
    lineNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof POLineItem
     */
    trNVCHRDAMT?: number;
    /**
     * 
     * @type {number}
     * @memberof POLineItem
     */
    costToDateAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof POLineItem
     */
    committedAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof POLineItem
     */
    runningTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof POLineItem
     */
    remainingAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof POLineItem
     */
    opeNAMT?: number;
    /**
     * 
     * @type {string}
     * @memberof POLineItem
     */
    itemID?: string;
    /**
     * 
     * @type {number}
     * @memberof POLineItem
     */
    ordereDAMTSUBPO?: number;
    /**
     * 
     * @type {string}
     * @memberof POLineItem
     */
    accountID?: string;
    /**
     * 
     * @type {string}
     * @memberof POLineItem
     */
    accountName?: string;
    /**
     * 
     * @type {string}
     * @memberof POLineItem
     */
    accountType?: string;
    /**
     * 
     * @type {string}
     * @memberof POLineItem
     */
    chargeCode?: string;
    /**
     * 
     * @type {string}
     * @memberof POLineItem
     */
    chargeCodeName?: string;
    /**
     * 
     * @type {string}
     * @memberof POLineItem
     */
    accountGroupCode?: string;
    /**
     * 
     * @type {number}
     * @memberof POLineItem
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof POLineItem
     */
    unitPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof POLineItem
     */
    unit?: string;
    /**
     * 
     * @type {string}
     * @memberof POLineItem
     */
    unitName?: string;
    /**
     * 
     * @type {string}
     * @memberof POLineItem
     */
    cpPurchaseOrderGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof POLineItem
     */
    cpPurchaseOrderItemGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof POLineItem
     */
    pOID?: string;
    /**
     * 
     * @type {Date}
     * @memberof POLineItem
     */
    createdOn?: Date;
    /**
     * 
     * @type {Date}
     * @memberof POLineItem
     */
    updatedOn?: Date;
    /**
     * 
     * @type {string}
     * @memberof POLineItem
     */
    typeName?: string;
    /**
     * 
     * @type {Array<POLineAccount>}
     * @memberof POLineItem
     */
    lineAccountList?: Array<POLineAccount>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ParameterAttributes {
    NUMBER_0 = <any>0,
    NUMBER_1 = <any>1,
    NUMBER_2 = <any>2,
    NUMBER_4 = <any>4,
    NUMBER_8 = <any>8,
    NUMBER_16 = <any>16,
    NUMBER_4096 = <any>4096,
    NUMBER_8192 = <any>8192,
    NUMBER_16384 = <any>16384,
    NUMBER_32768 = <any>32768,
    NUMBER_61440 = <any>61440
}
/**
 * 
 * @export
 * @interface ParameterInfo
 */
export interface ParameterInfo {
    /**
     * 
     * @type {ParameterAttributes}
     * @memberof ParameterInfo
     */
    attributes?: ParameterAttributes;
    /**
     * 
     * @type {MemberInfo}
     * @memberof ParameterInfo
     */
    member?: MemberInfo;
    /**
     * 
     * @type {string}
     * @memberof ParameterInfo
     */
    name?: string;
    /**
     * 
     * @type {Type}
     * @memberof ParameterInfo
     */
    parameterType?: Type;
    /**
     * 
     * @type {number}
     * @memberof ParameterInfo
     */
    position?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterInfo
     */
    isIn?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterInfo
     */
    isLcid?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterInfo
     */
    isOptional?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterInfo
     */
    isOut?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterInfo
     */
    isRetval?: boolean;
    /**
     * 
     * @type {any}
     * @memberof ParameterInfo
     */
    defaultValue?: any;
    /**
     * 
     * @type {any}
     * @memberof ParameterInfo
     */
    rawDefaultValue?: any;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterInfo
     */
    hasDefaultValue?: boolean;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof ParameterInfo
     */
    customAttributes?: Array<CustomAttributeData>;
    /**
     * 
     * @type {number}
     * @memberof ParameterInfo
     */
    metadataToken?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum PropertyAttributes {
    NUMBER_0 = <any>0,
    NUMBER_512 = <any>512,
    NUMBER_1024 = <any>1024,
    NUMBER_4096 = <any>4096,
    NUMBER_8192 = <any>8192,
    NUMBER_16384 = <any>16384,
    NUMBER_32768 = <any>32768,
    NUMBER_62464 = <any>62464
}
/**
 * 
 * @export
 * @interface PropertyInfo
 */
export interface PropertyInfo {
    /**
     * 
     * @type {string}
     * @memberof PropertyInfo
     */
    name?: string;
    /**
     * 
     * @type {Type}
     * @memberof PropertyInfo
     */
    declaringType?: Type;
    /**
     * 
     * @type {Type}
     * @memberof PropertyInfo
     */
    reflectedType?: Type;
    /**
     * 
     * @type {Module}
     * @memberof PropertyInfo
     */
    module?: Module;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof PropertyInfo
     */
    customAttributes?: Array<CustomAttributeData>;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyInfo
     */
    isCollectible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PropertyInfo
     */
    metadataToken?: number;
    /**
     * 
     * @type {MemberTypes}
     * @memberof PropertyInfo
     */
    memberType?: MemberTypes;
    /**
     * 
     * @type {Type}
     * @memberof PropertyInfo
     */
    propertyType?: Type;
    /**
     * 
     * @type {PropertyAttributes}
     * @memberof PropertyInfo
     */
    attributes?: PropertyAttributes;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyInfo
     */
    isSpecialName?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyInfo
     */
    canRead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyInfo
     */
    canWrite?: boolean;
    /**
     * 
     * @type {MethodInfo}
     * @memberof PropertyInfo
     */
    getMethod?: MethodInfo;
    /**
     * 
     * @type {MethodInfo}
     * @memberof PropertyInfo
     */
    setMethod?: MethodInfo;
}
/**
 * 
 * @export
 * @interface PurchaseItem
 */
export interface PurchaseItem {
    /**
     * 
     * @type {string}
     * @memberof PurchaseItem
     */
    purchaseItemGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseItem
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseItem
     */
    chargeCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseItem
     */
    orgId?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseItem
     */
    accountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseItem
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof PurchaseItem
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseItem
     */
    lastQuantity?: number;
    /**
     * 
     * @type {string}
     * @memberof PurchaseItem
     */
    unit?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseItem
     */
    unitName?: string;
    /**
     * 
     * @type {number}
     * @memberof PurchaseItem
     */
    unitPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseItem
     */
    lastUnitPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseItem
     */
    total?: number;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseItem
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseItem
     */
    endDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof PurchaseItem
     */
    lineNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseItem
     */
    lineItemNumber?: number;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseItem
     */
    createdOn?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseItem
     */
    updatedOn?: Date;
    /**
     * 
     * @type {string}
     * @memberof PurchaseItem
     */
    tranType?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseItem
     */
    accountGroupCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseItem
     */
    typeName?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseItem
     */
    chargeCodeName?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseItem
     */
    accountName?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseItem
     */
    emplID?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseItem
     */
    poStatus?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PurchaseItem
     */
    isUpdated?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PurchaseItem
     */
    purchaseRequisitionGuid?: string;
}
/**
 * 
 * @export
 * @interface PurchaseItemSingleResult
 */
export interface PurchaseItemSingleResult {
    /**
     * 
     * @type {Array<PurchaseItem>}
     * @memberof PurchaseItemSingleResult
     */
    queryable?: Array<PurchaseItem>;
}
/**
 * 
 * @export
 * @interface PurchaseOrderInvoiceDetail
 */
export interface PurchaseOrderInvoiceDetail {
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderInvoiceDetail
     */
    poNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderInvoiceDetail
     */
    totalAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderInvoiceDetail
     */
    poStatusType?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderInvoiceDetail
     */
    poType?: string;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderInvoiceDetail
     */
    unreLAMT?: number;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderInvoiceDetail
     */
    vendorID?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderInvoiceDetail
     */
    vendorName?: string;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseOrderInvoiceDetail
     */
    endDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseOrderInvoiceDetail
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseOrderInvoiceDetail
     */
    orderDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderInvoiceDetail
     */
    buyerID?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderInvoiceDetail
     */
    buyerName?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderInvoiceDetail
     */
    projectNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderInvoiceDetail
     */
    costToDateAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderInvoiceDetail
     */
    runningTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderInvoiceDetail
     */
    remainingAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderInvoiceDetail
     */
    companyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderInvoiceDetail
     */
    companyName?: string;
    /**
     * 
     * @type {Array<POLineItem>}
     * @memberof PurchaseOrderInvoiceDetail
     */
    poLineItemList?: Array<POLineItem>;
}
/**
 * 
 * @export
 * @interface PurchaseOrderInvoiceDetailSingleResult
 */
export interface PurchaseOrderInvoiceDetailSingleResult {
    /**
     * 
     * @type {Array<PurchaseOrderInvoiceDetail>}
     * @memberof PurchaseOrderInvoiceDetailSingleResult
     */
    queryable?: Array<PurchaseOrderInvoiceDetail>;
}
/**
 * 
 * @export
 * @interface PurchaseOrderItemPost
 */
export interface PurchaseOrderItemPost {
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderItemPost
     */
    itemID?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderItemPost
     */
    revisionID?: string;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderItemPost
     */
    quantity?: number;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseOrderItemPost
     */
    origDueDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderItemPost
     */
    autoVoucherFlag?: string;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseOrderItemPost
     */
    desiredDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseOrderItemPost
     */
    polnDueDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseOrderItemPost
     */
    orderDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseOrderItemPost
     */
    performanceEndDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseOrderItemPost
     */
    performanceStartDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderItemPost
     */
    lineDesc?: string;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderItemPost
     */
    lineNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderItemPost
     */
    lineTax?: string;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderItemPost
     */
    unitAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderItemPost
     */
    netAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderItemPost
     */
    extraAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderItemPost
     */
    lineCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderItemPost
     */
    confirmFlag?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderItemPost
     */
    requiredFlag?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderItemPost
     */
    inspFlag?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderItemPost
     */
    tranType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PurchaseOrderItemPost
     */
    isUpdated?: boolean;
    /**
     * 
     * @type {Array<LineItemAccountPost>}
     * @memberof PurchaseOrderItemPost
     */
    lineItemAccountList?: Array<LineItemAccountPost>;
}
/**
 * 
 * @export
 * @interface PurchaseOrderPost
 */
export interface PurchaseOrderPost {
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderPost
     */
    buyerID?: string;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseOrderPost
     */
    desiredDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseOrderPost
     */
    dueDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseOrderPost
     */
    orderDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderPost
     */
    poStatusType?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderPost
     */
    taxableType?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderPost
     */
    poType?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderPost
     */
    termsDC?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderPost
     */
    poNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderPost
     */
    vendorID?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderPost
     */
    addRDC?: string;
    /**
     * 
     * @type {VendorPost}
     * @memberof PurchaseOrderPost
     */
    vendor?: VendorPost;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderPost
     */
    companyCode?: string;
    /**
     * 
     * @type {Array<PurchaseOrderItemPost>}
     * @memberof PurchaseOrderPost
     */
    purchaseOrderItemList?: Array<PurchaseOrderItemPost>;
}
/**
 * 
 * @export
 * @interface PurchaseRequisition
 */
export interface PurchaseRequisition {
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    purchaseRequisitionGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    parentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    prType?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    prNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    poNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    chargeType?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    companyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    projectNumber?: string;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseRequisition
     */
    dateNeeded?: Date;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    requisitionType?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    leaseType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PurchaseRequisition
     */
    isCompeted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    vendorName?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    vendorCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    primaryAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    secondaryAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    paymentType?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    alertMsg?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    qualityLevel?: string;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseRequisition
     */
    createdOn?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseRequisition
     */
    updatedOn?: Date;
    /**
     * 
     * @type {number}
     * @memberof PurchaseRequisition
     */
    totalAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseRequisition
     */
    shippingCost?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseRequisition
     */
    taxAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseRequisition
     */
    otherAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    specialNote?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    specialPaymentTerm?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    comments?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    actionType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PurchaseRequisition
     */
    isEditable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    accountGrpCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    vendorAddressGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    addressContactGuid?: string;
    /**
     * 
     * @type {Array<PurchaseItem>}
     * @memberof PurchaseRequisition
     */
    purchaseItem?: Array<PurchaseItem>;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    employeeName?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    approverName?: string;
    /**
     * 
     * @type {Array<FileUpload>}
     * @memberof PurchaseRequisition
     */
    quotesFileList?: Array<FileUpload>;
    /**
     * 
     * @type {Array<FileUpload>}
     * @memberof PurchaseRequisition
     */
    ssjFormFileList?: Array<FileUpload>;
    /**
     * 
     * @type {Array<FileUpload>}
     * @memberof PurchaseRequisition
     */
    additionalAttachmentFileList?: Array<FileUpload>;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof PurchaseRequisition
     */
    attachmentList?: Array<Attachment>;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    accountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    chargeCode?: string;
    /**
     * 
     * @type {number}
     * @memberof PurchaseRequisition
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisition
     */
    companyName?: string;
}
/**
 * 
 * @export
 * @interface PurchaseRequisitionAdditionalComment
 */
export interface PurchaseRequisitionAdditionalComment {
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequisitionAdditionalComment
     */
    comments?: string;
}
/**
 * 
 * @export
 * @interface PurchaseRequisitionSingleResult
 */
export interface PurchaseRequisitionSingleResult {
    /**
     * 
     * @type {Array<PurchaseRequisition>}
     * @memberof PurchaseRequisitionSingleResult
     */
    queryable?: Array<PurchaseRequisition>;
}
/**
 * 
 * @export
 * @interface RenameFolder
 */
export interface RenameFolder {
    /**
     * 
     * @type {string}
     * @memberof RenameFolder
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RenameFolder
     */
    newName?: string;
}
/**
 * 
 * @export
 * @interface RequisitionApprovalFlow
 */
export interface RequisitionApprovalFlow {
    /**
     * 
     * @type {string}
     * @memberof RequisitionApprovalFlow
     */
    requisitionApprovalFlowGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof RequisitionApprovalFlow
     */
    purchaseRequisitionGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof RequisitionApprovalFlow
     */
    roleGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof RequisitionApprovalFlow
     */
    role?: string;
    /**
     * 
     * @type {number}
     * @memberof RequisitionApprovalFlow
     */
    level?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RequisitionApprovalFlow
     */
    isCompleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequisitionApprovalFlow
     */
    isOptional?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequisitionApprovalFlow
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RequisitionApprovalFlow
     */
    stage?: string;
    /**
     * 
     * @type {number}
     * @memberof RequisitionApprovalFlow
     */
    revisionCount?: number;
    /**
     * 
     * @type {string}
     * @memberof RequisitionApprovalFlow
     */
    userGuid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RequisitionApprovalFlow
     */
    isRejected?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof RequisitionApprovalFlow
     */
    createdOn?: Date;
    /**
     * 
     * @type {string}
     * @memberof RequisitionApprovalFlow
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof RequisitionApprovalFlow
     */
    updatedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof RequisitionApprovalFlow
     */
    updatedOn?: Date;
    /**
     * 
     * @type {string}
     * @memberof RequisitionApprovalFlow
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof RequisitionApprovalFlow
     */
    completedBy?: string;
}
/**
 * 
 * @export
 * @interface RequisitionModel
 */
export interface RequisitionModel {
    /**
     * 
     * @type {string}
     * @memberof RequisitionModel
     */
    purchaseRequisitionGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof RequisitionModel
     */
    parentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof RequisitionModel
     */
    prType?: string;
    /**
     * 
     * @type {string}
     * @memberof RequisitionModel
     */
    prNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof RequisitionModel
     */
    poNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof RequisitionModel
     */
    chargeType?: string;
    /**
     * 
     * @type {string}
     * @memberof RequisitionModel
     */
    companyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof RequisitionModel
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof RequisitionModel
     */
    projectNumber?: string;
    /**
     * 
     * @type {Date}
     * @memberof RequisitionModel
     */
    dateNeeded?: Date;
    /**
     * 
     * @type {string}
     * @memberof RequisitionModel
     */
    requisitionType?: string;
    /**
     * 
     * @type {string}
     * @memberof RequisitionModel
     */
    leaseType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RequisitionModel
     */
    isCompleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RequisitionModel
     */
    vendorName?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    vendorCode?: string;
    /**
     * 
     * @type {string}
     * @memberof RequisitionModel
     */
    paymentType?: string;
    /**
     * 
     * @type {string}
     * @memberof RequisitionModel
     */
    qualityLevel?: string;
    /**
     * 
     * @type {string}
     * @memberof RequisitionModel
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof RequisitionModel
     */
    createdByDisplayName?: string;
    /**
     * 
     * @type {Date}
     * @memberof RequisitionModel
     */
    createdOn?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RequisitionModel
     */
    updatedOn?: Date;
    /**
     * 
     * @type {number}
     * @memberof RequisitionModel
     */
    subTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof RequisitionModel
     */
    totalAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof RequisitionModel
     */
    shippingCost?: number;
    /**
     * 
     * @type {number}
     * @memberof RequisitionModel
     */
    taxAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof RequisitionModel
     */
    otherAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof RequisitionModel
     */
    specialNote?: string;
    /**
     * 
     * @type {string}
     * @memberof RequisitionModel
     */
    specialPaymentTerm?: string;
    /**
     * 
     * @type {string}
     * @memberof RequisitionModel
     */
    comments?: string;
    /**
     * 
     * @type {string}
     * @memberof RequisitionModel
     */
    projectManager?: string;
    /**
     * 
     * @type {string}
     * @memberof RequisitionModel
     */
    subContractAdmin?: string;
    /**
     * 
     * @type {string}
     * @memberof RequisitionModel
     */
    projectControl?: string;
    /**
     * 
     * @type {string}
     * @memberof RequisitionModel
     */
    procurementRepresentative?: string;
}
/**
 * 
 * @export
 * @interface RequisitionModelSingleResult
 */
export interface RequisitionModelSingleResult {
    /**
     * 
     * @type {Array<RequisitionModel>}
     * @memberof RequisitionModelSingleResult
     */
    queryable?: Array<RequisitionModel>;
}
/**
 * 
 * @export
 * @interface RequisitionPermission
 */
export interface RequisitionPermission {
    /**
     * 
     * @type {boolean}
     * @memberof RequisitionPermission
     */
    isApprover?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequisitionPermission
     */
    allowedToIntegrate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequisitionPermission
     */
    isEditable?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RequisitionPermission
     */
    level?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RequisitionPermission
     */
    isSAUser?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequisitionPermission
     */
    isRejected?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RequisitionPermission
     */
    stage?: number;
    /**
     * 
     * @type {Array<User>}
     * @memberof RequisitionPermission
     */
    designatorList?: Array<User>;
}
/**
 * 
 * @export
 * @interface RequisitionPermissionSingleResult
 */
export interface RequisitionPermissionSingleResult {
    /**
     * 
     * @type {Array<RequisitionPermission>}
     * @memberof RequisitionPermissionSingleResult
     */
    queryable?: Array<RequisitionPermission>;
}
/**
 * 
 * @export
 * @interface RuntimeFieldHandle
 */
export interface RuntimeFieldHandle {
    /**
     * 
     * @type {IntPtr}
     * @memberof RuntimeFieldHandle
     */
    value?: IntPtr;
}
/**
 * 
 * @export
 * @interface RuntimeMethodHandle
 */
export interface RuntimeMethodHandle {
    /**
     * 
     * @type {IntPtr}
     * @memberof RuntimeMethodHandle
     */
    value?: IntPtr;
}
/**
 * 
 * @export
 * @interface RuntimeTypeHandle
 */
export interface RuntimeTypeHandle {
    /**
     * 
     * @type {IntPtr}
     * @memberof RuntimeTypeHandle
     */
    value?: IntPtr;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SecurityRuleSet {
    NUMBER_0 = <any>0,
    NUMBER_1 = <any>1,
    NUMBER_2 = <any>2
}
/**
 * 
 * @export
 * @interface StructLayoutAttribute
 */
export interface StructLayoutAttribute {
    /**
     * 
     * @type {any}
     * @memberof StructLayoutAttribute
     */
    typeId?: any;
    /**
     * 
     * @type {LayoutKind}
     * @memberof StructLayoutAttribute
     */
    value?: LayoutKind;
}
/**
 * 
 * @export
 * @interface Test
 */
export interface Test {
    /**
     * 
     * @type {string}
     * @memberof Test
     */
    name?: string;
    /**
     * 
     * @type {Array<FileUpload>}
     * @memberof Test
     */
    file1?: Array<FileUpload>;
}
/**
 * 
 * @export
 * @interface Type
 */
export interface Type {
    /**
     * 
     * @type {string}
     * @memberof Type
     */
    name?: string;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof Type
     */
    customAttributes?: Array<CustomAttributeData>;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isCollectible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Type
     */
    metadataToken?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isInterface?: boolean;
    /**
     * 
     * @type {MemberTypes}
     * @memberof Type
     */
    memberType?: MemberTypes;
    /**
     * 
     * @type {string}
     * @memberof Type
     */
    namespace?: string;
    /**
     * 
     * @type {string}
     * @memberof Type
     */
    assemblyQualifiedName?: string;
    /**
     * 
     * @type {string}
     * @memberof Type
     */
    fullName?: string;
    /**
     * 
     * @type {Assembly}
     * @memberof Type
     */
    assembly?: Assembly;
    /**
     * 
     * @type {Module}
     * @memberof Type
     */
    module?: Module;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isNested?: boolean;
    /**
     * 
     * @type {Type}
     * @memberof Type
     */
    declaringType?: Type;
    /**
     * 
     * @type {MethodBase}
     * @memberof Type
     */
    declaringMethod?: MethodBase;
    /**
     * 
     * @type {Type}
     * @memberof Type
     */
    reflectedType?: Type;
    /**
     * 
     * @type {Type}
     * @memberof Type
     */
    underlyingSystemType?: Type;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isTypeDefinition?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isArray?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isByRef?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isPointer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isConstructedGenericType?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isGenericParameter?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isGenericTypeParameter?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isGenericMethodParameter?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isGenericType?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isGenericTypeDefinition?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isSZArray?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isVariableBoundArray?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isByRefLike?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    hasElementType?: boolean;
    /**
     * 
     * @type {Array<Type>}
     * @memberof Type
     */
    genericTypeArguments?: Array<Type>;
    /**
     * 
     * @type {number}
     * @memberof Type
     */
    genericParameterPosition?: number;
    /**
     * 
     * @type {GenericParameterAttributes}
     * @memberof Type
     */
    genericParameterAttributes?: GenericParameterAttributes;
    /**
     * 
     * @type {TypeAttributes}
     * @memberof Type
     */
    attributes?: TypeAttributes;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isAbstract?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isImport?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isSealed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isSpecialName?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isClass?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isNestedAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isNestedFamANDAssem?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isNestedFamily?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isNestedFamORAssem?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isNestedPrivate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isNestedPublic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isNotPublic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isPublic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isAutoLayout?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isExplicitLayout?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isLayoutSequential?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isAnsiClass?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isAutoClass?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isUnicodeClass?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isCOMObject?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isContextful?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isEnum?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isMarshalByRef?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isPrimitive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isValueType?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isSignatureType?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isSecurityCritical?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isSecuritySafeCritical?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isSecurityTransparent?: boolean;
    /**
     * 
     * @type {StructLayoutAttribute}
     * @memberof Type
     */
    structLayoutAttribute?: StructLayoutAttribute;
    /**
     * 
     * @type {ConstructorInfo}
     * @memberof Type
     */
    typeInitializer?: ConstructorInfo;
    /**
     * 
     * @type {RuntimeTypeHandle}
     * @memberof Type
     */
    typeHandle?: RuntimeTypeHandle;
    /**
     * 
     * @type {string}
     * @memberof Type
     */
    guid?: string;
    /**
     * 
     * @type {Type}
     * @memberof Type
     */
    baseType?: Type;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isSerializable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    containsGenericParameters?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isVisible?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TypeAttributes {
    NUMBER_0 = <any>0,
    NUMBER_1 = <any>1,
    NUMBER_2 = <any>2,
    NUMBER_3 = <any>3,
    NUMBER_4 = <any>4,
    NUMBER_5 = <any>5,
    NUMBER_6 = <any>6,
    NUMBER_7 = <any>7,
    NUMBER_8 = <any>8,
    NUMBER_16 = <any>16,
    NUMBER_24 = <any>24,
    NUMBER_32 = <any>32,
    NUMBER_128 = <any>128,
    NUMBER_256 = <any>256,
    NUMBER_1024 = <any>1024,
    NUMBER_2048 = <any>2048,
    NUMBER_4096 = <any>4096,
    NUMBER_8192 = <any>8192,
    NUMBER_16384 = <any>16384,
    NUMBER_65536 = <any>65536,
    NUMBER_131072 = <any>131072,
    NUMBER_196608 = <any>196608,
    NUMBER_262144 = <any>262144,
    NUMBER_264192 = <any>264192,
    NUMBER_1048576 = <any>1048576,
    NUMBER_12582912 = <any>12582912
}
/**
 * 
 * @export
 * @interface TypeInfo
 */
export interface TypeInfo {
    /**
     * 
     * @type {string}
     * @memberof TypeInfo
     */
    name?: string;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof TypeInfo
     */
    customAttributes?: Array<CustomAttributeData>;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isCollectible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TypeInfo
     */
    metadataToken?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isInterface?: boolean;
    /**
     * 
     * @type {MemberTypes}
     * @memberof TypeInfo
     */
    memberType?: MemberTypes;
    /**
     * 
     * @type {string}
     * @memberof TypeInfo
     */
    namespace?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeInfo
     */
    assemblyQualifiedName?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeInfo
     */
    fullName?: string;
    /**
     * 
     * @type {Assembly}
     * @memberof TypeInfo
     */
    assembly?: Assembly;
    /**
     * 
     * @type {Module}
     * @memberof TypeInfo
     */
    module?: Module;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isNested?: boolean;
    /**
     * 
     * @type {Type}
     * @memberof TypeInfo
     */
    declaringType?: Type;
    /**
     * 
     * @type {MethodBase}
     * @memberof TypeInfo
     */
    declaringMethod?: MethodBase;
    /**
     * 
     * @type {Type}
     * @memberof TypeInfo
     */
    reflectedType?: Type;
    /**
     * 
     * @type {Type}
     * @memberof TypeInfo
     */
    underlyingSystemType?: Type;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isTypeDefinition?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isArray?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isByRef?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isPointer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isConstructedGenericType?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isGenericParameter?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isGenericTypeParameter?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isGenericMethodParameter?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isGenericType?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isGenericTypeDefinition?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isSZArray?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isVariableBoundArray?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isByRefLike?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    hasElementType?: boolean;
    /**
     * 
     * @type {Array<Type>}
     * @memberof TypeInfo
     */
    genericTypeArguments?: Array<Type>;
    /**
     * 
     * @type {number}
     * @memberof TypeInfo
     */
    genericParameterPosition?: number;
    /**
     * 
     * @type {GenericParameterAttributes}
     * @memberof TypeInfo
     */
    genericParameterAttributes?: GenericParameterAttributes;
    /**
     * 
     * @type {TypeAttributes}
     * @memberof TypeInfo
     */
    attributes?: TypeAttributes;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isAbstract?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isImport?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isSealed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isSpecialName?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isClass?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isNestedAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isNestedFamANDAssem?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isNestedFamily?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isNestedFamORAssem?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isNestedPrivate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isNestedPublic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isNotPublic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isPublic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isAutoLayout?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isExplicitLayout?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isLayoutSequential?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isAnsiClass?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isAutoClass?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isUnicodeClass?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isCOMObject?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isContextful?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isEnum?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isMarshalByRef?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isPrimitive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isValueType?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isSignatureType?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isSecurityCritical?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isSecuritySafeCritical?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isSecurityTransparent?: boolean;
    /**
     * 
     * @type {StructLayoutAttribute}
     * @memberof TypeInfo
     */
    structLayoutAttribute?: StructLayoutAttribute;
    /**
     * 
     * @type {ConstructorInfo}
     * @memberof TypeInfo
     */
    typeInitializer?: ConstructorInfo;
    /**
     * 
     * @type {RuntimeTypeHandle}
     * @memberof TypeInfo
     */
    typeHandle?: RuntimeTypeHandle;
    /**
     * 
     * @type {string}
     * @memberof TypeInfo
     */
    guid?: string;
    /**
     * 
     * @type {Type}
     * @memberof TypeInfo
     */
    baseType?: Type;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isSerializable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    containsGenericParameters?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isVisible?: boolean;
    /**
     * 
     * @type {Array<Type>}
     * @memberof TypeInfo
     */
    genericTypeParameters?: Array<Type>;
    /**
     * 
     * @type {Array<ConstructorInfo>}
     * @memberof TypeInfo
     */
    declaredConstructors?: Array<ConstructorInfo>;
    /**
     * 
     * @type {Array<EventInfo>}
     * @memberof TypeInfo
     */
    declaredEvents?: Array<EventInfo>;
    /**
     * 
     * @type {Array<FieldInfo>}
     * @memberof TypeInfo
     */
    declaredFields?: Array<FieldInfo>;
    /**
     * 
     * @type {Array<MemberInfo>}
     * @memberof TypeInfo
     */
    declaredMembers?: Array<MemberInfo>;
    /**
     * 
     * @type {Array<MethodInfo>}
     * @memberof TypeInfo
     */
    declaredMethods?: Array<MethodInfo>;
    /**
     * 
     * @type {Array<TypeInfo>}
     * @memberof TypeInfo
     */
    declaredNestedTypes?: Array<TypeInfo>;
    /**
     * 
     * @type {Array<PropertyInfo>}
     * @memberof TypeInfo
     */
    declaredProperties?: Array<PropertyInfo>;
    /**
     * 
     * @type {Array<Type>}
     * @memberof TypeInfo
     */
    implementedInterfaces?: Array<Type>;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    userGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastname?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    givenname?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    displayname?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    userStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    workEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    personalEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    workPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    homePhone?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    mobilePhone?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    jobStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    jobTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    department?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    extension?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    bambooHRID?: string;
}
/**
 * 
 * @export
 * @interface UserSingleResult
 */
export interface UserSingleResult {
    /**
     * 
     * @type {Array<User>}
     * @memberof UserSingleResult
     */
    queryable?: Array<User>;
}
/**
 * 
 * @export
 * @interface Vendor
 */
export interface Vendor {
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    vendorGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    taxID?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    accountDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    autoVoucherFlag?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    cageCD?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    cashAccountDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    dunsNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    voucherPayVendorFlag?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    termsCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    vendor1099Name?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    vendorID?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    vendorLongName?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    vendorName?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    companyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    oldVendorId?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    tranType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Vendor
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Vendor
     */
    isIntegrated?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Vendor
     */
    createdOn?: Date;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof Vendor
     */
    updatedOn?: Date;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    updatedBy?: string;
    /**
     * 
     * @type {Array<VendorAddress>}
     * @memberof Vendor
     */
    vendorAddressList?: Array<VendorAddress>;
}
/**
 * 
 * @export
 * @interface VendorAddUpdateResult
 */
export interface VendorAddUpdateResult {
    /**
     * 
     * @type {string}
     * @memberof VendorAddUpdateResult
     */
    vendorGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddUpdateResult
     */
    vendorAddressGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddUpdateResult
     */
    vendorContactGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddUpdateResult
     */
    vendorCode?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddUpdateResult
     */
    addressCode?: string;
    /**
     * 
     * @type {number}
     * @memberof VendorAddUpdateResult
     */
    contactSequenceNo?: number;
}
/**
 * 
 * @export
 * @interface VendorAddress
 */
export interface VendorAddress {
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    cpmvenDVENDADDRCHLDCITYNAME?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    cpmvenDVENDADDRCHLDCOUNTRYCD?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    cpmvenDVENDADDRCHLDLN1ADR?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    cpmvenDVENDADDRCHLDLN2ADR?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    cpmvenDVENDADDRCHLDLN3ADR?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    cpmvenDVENDADDRCHLDMAILSTATEDC?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    cpmvenDVENDADDRCHLDPOSTALCD?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    addressCode?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    oldAddressCode?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    vendorID?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    cityName?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    emailID?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    addressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    addressLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    addressLine3?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    mailStateCode?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    otherPhoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    phoneID?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    orderAddressCode?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    pmtAddressCode?: string;
    /**
     * 
     * @type {number}
     * @memberof VendorAddress
     */
    sequenceNo?: number;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    companyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    tranType?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    vendorAddressGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    vendorGuid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VendorAddress
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VendorAddress
     */
    isPrimary?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof VendorAddress
     */
    createdOn?: Date;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof VendorAddress
     */
    updatedOn?: Date;
    /**
     * 
     * @type {string}
     * @memberof VendorAddress
     */
    updatedBy?: string;
    /**
     * 
     * @type {Array<AddressContact>}
     * @memberof VendorAddress
     */
    contactList?: Array<AddressContact>;
}
/**
 * 
 * @export
 * @interface VendorAddressPost
 */
export interface VendorAddressPost {
    /**
     * 
     * @type {string}
     * @memberof VendorAddressPost
     */
    addressCode?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddressPost
     */
    cityName?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddressPost
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddressPost
     */
    emailID?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddressPost
     */
    addressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddressPost
     */
    addressLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddressPost
     */
    addressLine3?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddressPost
     */
    mailStateCode?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddressPost
     */
    otherPhoneID?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddressPost
     */
    phoneID?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddressPost
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddressPost
     */
    orderAddressCode?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddressPost
     */
    pmtAddressCode?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddressPost
     */
    tranType?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorAddressPost
     */
    oldAddressCode?: string;
    /**
     * 
     * @type {Array<VendorContactPost>}
     * @memberof VendorAddressPost
     */
    contactList?: Array<VendorContactPost>;
}
/**
 * 
 * @export
 * @interface VendorContactPost
 */
export interface VendorContactPost {
    /**
     * 
     * @type {string}
     * @memberof VendorContactPost
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorContactPost
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorContactPost
     */
    titleName?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorContactPost
     */
    emailID?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorContactPost
     */
    phoneID?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorContactPost
     */
    sequenceNo?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorContactPost
     */
    tranType?: string;
}
/**
 * 
 * @export
 * @interface VendorPost
 */
export interface VendorPost {
    /**
     * 
     * @type {string}
     * @memberof VendorPost
     */
    taxID?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorPost
     */
    apAccountDesc?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorPost
     */
    autoVoucherFlag?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorPost
     */
    cageCD?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorPost
     */
    cashAccountDesc?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorPost
     */
    dunsNo?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorPost
     */
    voucherPayVendorFlag?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorPost
     */
    termsCode?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorPost
     */
    vendor1099Name?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorPost
     */
    vendorID?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorPost
     */
    vendorLongName?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorPost
     */
    vendorName?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorPost
     */
    oldVendorID?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorPost
     */
    companyCode?: string;
    /**
     * 
     * @type {Array<VendorAddressPost>}
     * @memberof VendorPost
     */
    vendorAddressList?: Array<VendorAddressPost>;
}
/**
 * 
 * @export
 * @interface VendorSingleResult
 */
export interface VendorSingleResult {
    /**
     * 
     * @type {Array<Vendor>}
     * @memberof VendorSingleResult
     */
    queryable?: Array<Vendor>;
}
/**
 * AdvancedSearchApi - fetch parameter creator
 * @export
 */
export const AdvancedSearchApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedSearchPROptionsGet(loggedonuser: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling advancedSearchPROptionsGet.');
            }
            const localVarPath = `/AdvancedSearch/PROptions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        advancedSearchPRROptionsGet(loggedonuser: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling advancedSearchPROptionsGet.');
            }
            const localVarPath = `/AdvancedSearch/PRROptions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdvancedSearchApi - functional programming interface
 * @export
 */
export const AdvancedSearchApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedSearchPROptionsGet(loggedonuser: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdvancedSearchApiFetchParamCreator(configuration).advancedSearchPROptionsGet(loggedonuser, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        advancedSearchPRROptionsGet(loggedonuser: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdvancedSearchApiFetchParamCreator(configuration).advancedSearchPRROptionsGet(loggedonuser, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AdvancedSearchApi - factory interface
 * @export
 */
export const AdvancedSearchApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedSearchPROptionsGet(loggedonuser: string, options?: any) {
            return AdvancedSearchApiFp(configuration).advancedSearchPROptionsGet(loggedonuser, options)(fetch, basePath);
        },
    };
};

/**
 * AdvancedSearchApi - object-oriented interface
 * @export
 * @class AdvancedSearchApi
 * @extends {BaseAPI}
 */
export class AdvancedSearchApi extends BaseAPI {
    /**
     * 
     * @param {string} loggedonuser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvancedSearchApi
     */
    public advancedSearchPROptionsGet(loggedonuser: string, options?: any) {
        return AdvancedSearchApiFp(this.configuration).advancedSearchPROptionsGet(loggedonuser, options)(this.fetch, this.basePath);
    }
    public advancedSearchPRROptionsGet(loggedonuser: string, options?: any) {
        return AdvancedSearchApiFp(this.configuration).advancedSearchPRROptionsGet(loggedonuser, options)(this.fetch, this.basePath);
    }

}
/**
 * AttachmentApi - fetch parameter creator
 * @export
 */
export const AttachmentApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [attachmentGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentControllerGet(loggedonuser: string, attachmentGuid?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling attachmentControllerGet.');
            }
            const localVarPath = `/AttachmentController`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (attachmentGuid !== undefined) {
                localVarQueryParameter['attachmentGuid'] = attachmentGuid;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Array<FileUpload>} [body] 
         * @param {string} [resourceGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentControllerPost(loggedonuser: string, body?: Array<FileUpload>, resourceGuid?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling attachmentControllerPost.');
            }
            const localVarPath = `/AttachmentController`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (resourceGuid !== undefined) {
                localVarQueryParameter['resourceGuid'] = resourceGuid;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;FileUpload&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentKeyDelete(loggedonuser: string, key?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling attachmentKeyDelete.');
            }
            const localVarPath = `/Attachment({key})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AttachmentApi - functional programming interface
 * @export
 */
export const AttachmentApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [attachmentGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentControllerGet(loggedonuser: string, attachmentGuid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AttachmentApiFetchParamCreator(configuration).attachmentControllerGet(loggedonuser, attachmentGuid, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Array<FileUpload>} [body] 
         * @param {string} [resourceGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentControllerPost(loggedonuser: string, body?: Array<FileUpload>, resourceGuid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AttachmentApiFetchParamCreator(configuration).attachmentControllerPost(loggedonuser, body, resourceGuid, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentKeyDelete(loggedonuser: string, key?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AttachmentApiFetchParamCreator(configuration).attachmentKeyDelete(loggedonuser, key, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AttachmentApi - factory interface
 * @export
 */
export const AttachmentApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [attachmentGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentControllerGet(loggedonuser: string, attachmentGuid?: string, options?: any) {
            return AttachmentApiFp(configuration).attachmentControllerGet(loggedonuser, attachmentGuid, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Array<FileUpload>} [body] 
         * @param {string} [resourceGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentControllerPost(loggedonuser: string, body?: Array<FileUpload>, resourceGuid?: string, options?: any) {
            return AttachmentApiFp(configuration).attachmentControllerPost(loggedonuser, body, resourceGuid, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentKeyDelete(loggedonuser: string, key?: string, options?: any) {
            return AttachmentApiFp(configuration).attachmentKeyDelete(loggedonuser, key, options)(fetch, basePath);
        },
    };
};

/**
 * AttachmentApi - object-oriented interface
 * @export
 * @class AttachmentApi
 * @extends {BaseAPI}
 */
export class AttachmentApi extends BaseAPI {
    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [attachmentGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public attachmentControllerGet(loggedonuser: string, attachmentGuid?: string, options?: any) {
        return AttachmentApiFp(this.configuration).attachmentControllerGet(loggedonuser, attachmentGuid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {Array<FileUpload>} [body] 
     * @param {string} [resourceGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public attachmentControllerPost(loggedonuser: string, body?: Array<FileUpload>, resourceGuid?: string, options?: any) {
        return AttachmentApiFp(this.configuration).attachmentControllerPost(loggedonuser, body, resourceGuid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public attachmentKeyDelete(loggedonuser: string, key?: string, options?: any) {
        return AttachmentApiFp(this.configuration).attachmentKeyDelete(loggedonuser, key, options)(this.fetch, this.basePath);
    }

}
/**
 * CountryApi - fetch parameter creator
 * @export
 */
export const CountryApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryGet(loggedonuser: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling countryGet.');
            }
            const localVarPath = `/Country`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryKeyDelete(loggedonuser: string, key?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling countryKeyDelete.');
            }
            const localVarPath = `/Country({key})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryKeyGet(loggedonuser: string, key?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling countryKeyGet.');
            }
            const localVarPath = `/Country({key})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Country} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryPost(loggedonuser: string, body?: Country, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling countryPost.');
            }
            const localVarPath = `/Country`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Country" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {CountryDelta} [body] 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryPut(loggedonuser: string, body?: CountryDelta, key?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling countryPut.');
            }
            const localVarPath = `/Country`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CountryDelta" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CountryApi - functional programming interface
 * @export
 */
export const CountryApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryGet(loggedonuser: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CountryApiFetchParamCreator(configuration).countryGet(loggedonuser, search, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryKeyDelete(loggedonuser: string, key?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CountryApiFetchParamCreator(configuration).countryKeyDelete(loggedonuser, key, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryKeyGet(loggedonuser: string, key?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CountrySingleResult> {
            const localVarFetchArgs = CountryApiFetchParamCreator(configuration).countryKeyGet(loggedonuser, key, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Country} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryPost(loggedonuser: string, body?: Country, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CountryApiFetchParamCreator(configuration).countryPost(loggedonuser, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {CountryDelta} [body] 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryPut(loggedonuser: string, body?: CountryDelta, key?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CountryApiFetchParamCreator(configuration).countryPut(loggedonuser, body, key, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CountryApi - factory interface
 * @export
 */
export const CountryApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryGet(loggedonuser: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return CountryApiFp(configuration).countryGet(loggedonuser, search, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryKeyDelete(loggedonuser: string, key?: string, options?: any) {
            return CountryApiFp(configuration).countryKeyDelete(loggedonuser, key, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryKeyGet(loggedonuser: string, key?: string, options?: any) {
            return CountryApiFp(configuration).countryKeyGet(loggedonuser, key, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Country} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryPost(loggedonuser: string, body?: Country, options?: any) {
            return CountryApiFp(configuration).countryPost(loggedonuser, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {CountryDelta} [body] 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryPut(loggedonuser: string, body?: CountryDelta, key?: string, options?: any) {
            return CountryApiFp(configuration).countryPut(loggedonuser, body, key, options)(fetch, basePath);
        },
    };
};

/**
 * CountryApi - object-oriented interface
 * @export
 * @class CountryApi
 * @extends {BaseAPI}
 */
export class CountryApi extends BaseAPI {
    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [search] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryApi
     */
    public countryGet(loggedonuser: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return CountryApiFp(this.configuration).countryGet(loggedonuser, search, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryApi
     */
    public countryKeyDelete(loggedonuser: string, key?: string, options?: any) {
        return CountryApiFp(this.configuration).countryKeyDelete(loggedonuser, key, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryApi
     */
    public countryKeyGet(loggedonuser: string, key?: string, options?: any) {
        return CountryApiFp(this.configuration).countryKeyGet(loggedonuser, key, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {Country} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryApi
     */
    public countryPost(loggedonuser: string, body?: Country, options?: any) {
        return CountryApiFp(this.configuration).countryPost(loggedonuser, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {CountryDelta} [body] 
     * @param {string} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryApi
     */
    public countryPut(loggedonuser: string, body?: CountryDelta, key?: string, options?: any) {
        return CountryApiFp(this.configuration).countryPut(loggedonuser, body, key, options)(this.fetch, this.basePath);
    }

}
/**
 * ExternalApprovalApi - fetch parameter creator
 * @export
 */
export const ExternalApprovalApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalApprovalAuthorizedGet(loggedonuser: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling externalApprovalAuthorizedGet.');
            }
            const localVarPath = `/externalApproval/Authorized`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {ExternalApprovalLog} [body] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalApprovalPost(loggedonuser: string, body?: ExternalApprovalLog, authorization?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling externalApprovalPost.');
            }
            const localVarPath = `/externalApproval`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ExternalApprovalLog" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExternalApprovalApi - functional programming interface
 * @export
 */
export const ExternalApprovalApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalApprovalAuthorizedGet(loggedonuser: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ExternalApprovalApiFetchParamCreator(configuration).externalApprovalAuthorizedGet(loggedonuser, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {ExternalApprovalLog} [body] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalApprovalPost(loggedonuser: string, body?: ExternalApprovalLog, authorization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ExternalApprovalApiFetchParamCreator(configuration).externalApprovalPost(loggedonuser, body, authorization, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ExternalApprovalApi - factory interface
 * @export
 */
export const ExternalApprovalApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalApprovalAuthorizedGet(loggedonuser: string, options?: any) {
            return ExternalApprovalApiFp(configuration).externalApprovalAuthorizedGet(loggedonuser, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {ExternalApprovalLog} [body] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalApprovalPost(loggedonuser: string, body?: ExternalApprovalLog, authorization?: string, options?: any) {
            return ExternalApprovalApiFp(configuration).externalApprovalPost(loggedonuser, body, authorization, options)(fetch, basePath);
        },
    };
};

/**
 * ExternalApprovalApi - object-oriented interface
 * @export
 * @class ExternalApprovalApi
 * @extends {BaseAPI}
 */
export class ExternalApprovalApi extends BaseAPI {
    /**
     * 
     * @param {string} loggedonuser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApprovalApi
     */
    public externalApprovalAuthorizedGet(loggedonuser: string, options?: any) {
        return ExternalApprovalApiFp(this.configuration).externalApprovalAuthorizedGet(loggedonuser, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {ExternalApprovalLog} [body] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApprovalApi
     */
    public externalApprovalPost(loggedonuser: string, body?: ExternalApprovalLog, authorization?: string, options?: any) {
        return ExternalApprovalApiFp(this.configuration).externalApprovalPost(loggedonuser, body, authorization, options)(this.fetch, this.basePath);
    }

}
/**
 * GenericApi - fetch parameter creator
 * @export
 */
export const GenericApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [searchKey] 
         * @param {string} [actionFilter] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        genericMyActionItemGet(loggedonuser: string, searchKey?: string, actionFilter?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling genericMyActionItemGet.');
            }
            const localVarPath = `/Generic/MyActionItem`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (searchKey !== undefined) {
                localVarQueryParameter['searchKey'] = searchKey;
            }

            if (actionFilter !== undefined) {
                localVarQueryParameter['actionFilter'] = actionFilter;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GenericApi - functional programming interface
 * @export
 */
export const GenericApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [searchKey] 
         * @param {string} [actionFilter] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        genericMyActionItemGet(loggedonuser: string, searchKey?: string, actionFilter?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GenericApiFetchParamCreator(configuration).genericMyActionItemGet(loggedonuser, searchKey, actionFilter, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GenericApi - factory interface
 * @export
 */
export const GenericApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [searchKey] 
         * @param {string} [actionFilter] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        genericMyActionItemGet(loggedonuser: string, searchKey?: string, actionFilter?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return GenericApiFp(configuration).genericMyActionItemGet(loggedonuser, searchKey, actionFilter, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
    };
};

/**
 * GenericApi - object-oriented interface
 * @export
 * @class GenericApi
 * @extends {BaseAPI}
 */
export class GenericApi extends BaseAPI {
    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [searchKey] 
     * @param {string} [actionFilter] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericApi
     */
    public genericMyActionItemGet(loggedonuser: string, searchKey?: string, actionFilter?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return GenericApiFp(this.configuration).genericMyActionItemGet(loggedonuser, searchKey, actionFilter, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

}
/**
 * GroupApi - fetch parameter creator
 * @export
 */
export const GroupApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupGet(loggedonuser: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling groupGet.');
            }
            const localVarPath = `/Group`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupKeyDelete(loggedonuser: string, key?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling groupKeyDelete.');
            }
            const localVarPath = `/Group({key})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupKeyGet(loggedonuser: string, key?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling groupKeyGet.');
            }
            const localVarPath = `/Group({key})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Group} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupPost(loggedonuser: string, body?: Group, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling groupPost.');
            }
            const localVarPath = `/Group`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Group" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Group} [body] 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupPut(loggedonuser: string, body?: Group, key?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling groupPut.');
            }
            const localVarPath = `/Group`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Group" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupApi - functional programming interface
 * @export
 */
export const GroupApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupGet(loggedonuser: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GroupApiFetchParamCreator(configuration).groupGet(loggedonuser, search, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupKeyDelete(loggedonuser: string, key?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GroupApiFetchParamCreator(configuration).groupKeyDelete(loggedonuser, key, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupKeyGet(loggedonuser: string, key?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GroupSingleResult> {
            const localVarFetchArgs = GroupApiFetchParamCreator(configuration).groupKeyGet(loggedonuser, key, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Group} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupPost(loggedonuser: string, body?: Group, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GroupApiFetchParamCreator(configuration).groupPost(loggedonuser, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Group} [body] 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupPut(loggedonuser: string, body?: Group, key?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GroupApiFetchParamCreator(configuration).groupPut(loggedonuser, body, key, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GroupApi - factory interface
 * @export
 */
export const GroupApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupGet(loggedonuser: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return GroupApiFp(configuration).groupGet(loggedonuser, search, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupKeyDelete(loggedonuser: string, key?: string, options?: any) {
            return GroupApiFp(configuration).groupKeyDelete(loggedonuser, key, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupKeyGet(loggedonuser: string, key?: string, options?: any) {
            return GroupApiFp(configuration).groupKeyGet(loggedonuser, key, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Group} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupPost(loggedonuser: string, body?: Group, options?: any) {
            return GroupApiFp(configuration).groupPost(loggedonuser, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Group} [body] 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupPut(loggedonuser: string, body?: Group, key?: string, options?: any) {
            return GroupApiFp(configuration).groupPut(loggedonuser, body, key, options)(fetch, basePath);
        },
    };
};

/**
 * GroupApi - object-oriented interface
 * @export
 * @class GroupApi
 * @extends {BaseAPI}
 */
export class GroupApi extends BaseAPI {
    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [search] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public groupGet(loggedonuser: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return GroupApiFp(this.configuration).groupGet(loggedonuser, search, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public groupKeyDelete(loggedonuser: string, key?: string, options?: any) {
        return GroupApiFp(this.configuration).groupKeyDelete(loggedonuser, key, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public groupKeyGet(loggedonuser: string, key?: string, options?: any) {
        return GroupApiFp(this.configuration).groupKeyGet(loggedonuser, key, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {Group} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public groupPost(loggedonuser: string, body?: Group, options?: any) {
        return GroupApiFp(this.configuration).groupPost(loggedonuser, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {Group} [body] 
     * @param {string} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public groupPut(loggedonuser: string, body?: Group, key?: string, options?: any) {
        return GroupApiFp(this.configuration).groupPut(loggedonuser, body, key, options)(this.fetch, this.basePath);
    }

}
/**
 * GroupPermissionApi - fetch parameter creator
 * @export
 */
export const GroupPermissionApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [groupGuid] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupPermissionGet(loggedonuser: string, groupGuid?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling groupPermissionGet.');
            }
            const localVarPath = `/GroupPermission`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (groupGuid !== undefined) {
                localVarQueryParameter['groupGuid'] = groupGuid;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Array<GroupPermissionPost>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupPermissionPost(loggedonuser: string, body?: Array<GroupPermissionPost>, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling groupPermissionPost.');
            }
            const localVarPath = `/GroupPermission`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;GroupPermissionPost&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupPermissionApi - functional programming interface
 * @export
 */
export const GroupPermissionApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [groupGuid] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupPermissionGet(loggedonuser: string, groupGuid?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GroupPermissionApiFetchParamCreator(configuration).groupPermissionGet(loggedonuser, groupGuid, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Array<GroupPermissionPost>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupPermissionPost(loggedonuser: string, body?: Array<GroupPermissionPost>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GroupPermissionApiFetchParamCreator(configuration).groupPermissionPost(loggedonuser, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GroupPermissionApi - factory interface
 * @export
 */
export const GroupPermissionApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [groupGuid] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupPermissionGet(loggedonuser: string, groupGuid?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return GroupPermissionApiFp(configuration).groupPermissionGet(loggedonuser, groupGuid, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Array<GroupPermissionPost>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupPermissionPost(loggedonuser: string, body?: Array<GroupPermissionPost>, options?: any) {
            return GroupPermissionApiFp(configuration).groupPermissionPost(loggedonuser, body, options)(fetch, basePath);
        },
    };
};

/**
 * GroupPermissionApi - object-oriented interface
 * @export
 * @class GroupPermissionApi
 * @extends {BaseAPI}
 */
export class GroupPermissionApi extends BaseAPI {
    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [groupGuid] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupPermissionApi
     */
    public groupPermissionGet(loggedonuser: string, groupGuid?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return GroupPermissionApiFp(this.configuration).groupPermissionGet(loggedonuser, groupGuid, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {Array<GroupPermissionPost>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupPermissionApi
     */
    public groupPermissionPost(loggedonuser: string, body?: Array<GroupPermissionPost>, options?: any) {
        return GroupPermissionApiFp(this.configuration).groupPermissionPost(loggedonuser, body, options)(this.fetch, this.basePath);
    }

}
/**
 * InvoiceApi - fetch parameter creator
 * @export
 */
export const InvoiceApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} invoiceGuid 
         * @param {string} loggedonuser 
         * @param {InvoiceAdditionalComment} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceAddCommentInvoiceGuidPost(invoiceGuid: string, loggedonuser: string, body?: InvoiceAdditionalComment, options: any = {}): FetchArgs {
            // verify required parameter 'invoiceGuid' is not null or undefined
            if (invoiceGuid === null || invoiceGuid === undefined) {
                throw new RequiredError('invoiceGuid','Required parameter invoiceGuid was null or undefined when calling invoiceAddCommentInvoiceGuidPost.');
            }
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser','Required parameter loggedonuser was null or undefined when calling invoiceAddCommentInvoiceGuidPost.');
            }
            const localVarPath = `/Invoice/AddComment/{invoiceGuid}`
                .replace(`{${"invoiceGuid"}}`, encodeURIComponent(String(invoiceGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"InvoiceAdditionalComment" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceAddInvoiceAuthGet(loggedonuser: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling invoiceAddInvoiceAuthGet.');
            }
            const localVarPath = `/Invoice/AddInvoice/Auth`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} invoiceGuid 
         * @param {string} loggedonuser 
         * @param {InvoiceApprovalModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceAdditionalReviewerInvoiceGuidPost(invoiceGuid: string, loggedonuser: string, body?: InvoiceApprovalModel, options: any = {}): FetchArgs {
            // verify required parameter 'invoiceGuid' is not null or undefined
            if (invoiceGuid === null || invoiceGuid === undefined) {
                throw new RequiredError('invoiceGuid', 'Required parameter invoiceGuid was null or undefined when calling invoiceAdditionalReviewerInvoiceGuidPost.');
            }
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling invoiceAdditionalReviewerInvoiceGuidPost.');
            }
            const localVarPath = `/Invoice/AdditionalReviewer/{invoiceGuid}`
                .replace(`{${"invoiceGuid"}}`, encodeURIComponent(String(invoiceGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"InvoiceApprovalModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [pONumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceApprovalsPONumberGet(loggedonuser: string, pONumber?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling invoiceApprovalsPONumberGet.');
            }
            const localVarPath = `/Invoice/approvals({PONumber})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (pONumber !== undefined) {
                localVarQueryParameter['PONumber'] = pONumber;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {ApproveComment} [body] 
         * @param {string} [invoiceGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceApproveInvoiceGuidPost(loggedonuser: string, body?: ApproveComment, invoiceGuid?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling invoiceApproveInvoiceGuidPost.');
            }
            const localVarPath = `/Invoice/Approve({InvoiceGuid})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (invoiceGuid !== undefined) {
                localVarQueryParameter['InvoiceGuid'] = invoiceGuid;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ApproveComment" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [orgId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceCompanyOrgIdGet(loggedonuser: string, orgId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling invoiceCompanyOrgIdGet.');
            }
            const localVarPath = `/Invoice/Company({orgId})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} invoiceGuid 
         * @param {string} loggedonuser 
         * @param {string} [attachmentGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceDeleteInvoiceAttachmentInvoiceGuidDelete(invoiceGuid: string, loggedonuser: string, attachmentGuid?: string, options: any = {}): FetchArgs {
            // verify required parameter 'invoiceGuid' is not null or undefined
            if (invoiceGuid === null || invoiceGuid === undefined) {
                throw new RequiredError('invoiceGuid','Required parameter invoiceGuid was null or undefined when calling invoiceDeleteInvoiceAttachmentInvoiceGuidDelete.');
            }
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser','Required parameter loggedonuser was null or undefined when calling invoiceDeleteInvoiceAttachmentInvoiceGuidDelete.');
            }
            const localVarPath = `/Invoice/DeleteInvoiceAttachment/{invoiceGuid}`
                .replace(`{${"invoiceGuid"}}`, encodeURIComponent(String(invoiceGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (attachmentGuid !== undefined) {
                localVarQueryParameter['AttachmentGuid'] = attachmentGuid;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [search] 
         * @param {string} [view] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {string} [columnFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceExportExcelGet(loggedonuser: string, search?: string, view?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, columnFilter?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser','Required parameter loggedonuser was null or undefined when calling invoiceExportExcelGet.');
            }
            const localVarPath = `/Invoice/ExportExcel`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (view !== undefined) {
                localVarQueryParameter['view'] = view;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (columnFilter !== undefined) {
                localVarQueryParameter['columnFilter'] = columnFilter;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [search] 
         * @param {string} [view] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceGet(loggedonuser: string, search?: string, view?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling invoiceGet.');
            }
            const localVarPath = `/Invoice`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (view !== undefined) {
                localVarQueryParameter['view'] = view;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} invoiceGuid 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceHasInvoiceCommentPermissionInvoiceGuidGet(invoiceGuid: string, loggedonuser: string, options: any = {}): FetchArgs {
            // verify required parameter 'invoiceGuid' is not null or undefined
            if (invoiceGuid === null || invoiceGuid === undefined) {
                throw new RequiredError('invoiceGuid','Required parameter invoiceGuid was null or undefined when calling invoiceHasInvoiceCommentPermissionInvoiceGuidGet.');
            }
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser','Required parameter loggedonuser was null or undefined when calling invoiceHasInvoiceCommentPermissionInvoiceGuidGet.');
            }
            const localVarPath = `/Invoice/HasInvoiceCommentPermission/{invoiceGuid}`
                .replace(`{${"invoiceGuid"}}`, encodeURIComponent(String(invoiceGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
       /**
         * 
         * @param {string} invoiceGuid 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
       invoiceHasInvoiceAttachmentPermissionInvoiceGuidGet(invoiceGuid: string, loggedonuser: string, options: any = {}): FetchArgs {
        // verify required parameter 'invoiceGuid' is not null or undefined
        if (invoiceGuid === null || invoiceGuid === undefined) {
            throw new RequiredError('invoiceGuid','Required parameter invoiceGuid was null or undefined when calling invoiceHasInvoiceAttachmentPermissionInvoiceGuidGet.');
        }
        // verify required parameter 'loggedonuser' is not null or undefined
        if (loggedonuser === null || loggedonuser === undefined) {
            throw new RequiredError('loggedonuser','Required parameter loggedonuser was null or undefined when calling invoiceHasInvoiceAttachmentPermissionInvoiceGuidGet.');
        }
        const localVarPath = `/Invoice/HasInvoiceAttachmentPermission/{invoiceGuid}`
            .replace(`{${"invoiceGuid"}}`, encodeURIComponent(String(invoiceGuid)));
        const localVarUrlObj = url.parse(localVarPath, true);
        const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        // authentication Bearer required
        if (configuration && configuration.apiKey) {
            const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                ? configuration.apiKey("Authorization")
                : configuration.apiKey;
            localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
        }

        if (loggedonuser !== undefined && loggedonuser !== null) {
            localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
        }

        localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
        // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
        delete localVarUrlObj.search;
        localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

        return {
            url: url.format(localVarUrlObj),
            options: localVarRequestOptions,
        };
    },
        /**
         * 
         * @param {string} invoiceGuid 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceIntegrationAuthInvoiceGuidGet(invoiceGuid: string, loggedonuser: string, options: any = {}): FetchArgs {
            // verify required parameter 'invoiceGuid' is not null or undefined
            if (invoiceGuid === null || invoiceGuid === undefined) {
                throw new RequiredError('invoiceGuid', 'Required parameter invoiceGuid was null or undefined when calling invoiceIntegrationAuthInvoiceGuidGet.');
            }
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling invoiceIntegrationAuthInvoiceGuidGet.');
            }
            const localVarPath = `/Invoice/Integration/Auth({invoiceGuid})`
                .replace(`{${"invoiceGuid"}}`, encodeURIComponent(String(invoiceGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} invoiceGuid 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceInvoiceGuidGet(invoiceGuid: string, loggedonuser: string, options: any = {}): FetchArgs {
            // verify required parameter 'invoiceGuid' is not null or undefined
            if (invoiceGuid === null || invoiceGuid === undefined) {
                throw new RequiredError('invoiceGuid', 'Required parameter invoiceGuid was null or undefined when calling invoiceInvoiceGuidGet.');
            }
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling invoiceInvoiceGuidGet.');
            }
            const localVarPath = `/Invoice/{invoiceGuid}`
                .replace(`{${"invoiceGuid"}}`, encodeURIComponent(String(invoiceGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} invoiceGuid 
         * @param {string} loggedonuser 
         * @param {InvoiceIntegration} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceInvoiceGuidIntegrationPut(invoiceGuid: string, loggedonuser: string, body?: InvoiceIntegration, options: any = {}): FetchArgs {
            // verify required parameter 'invoiceGuid' is not null or undefined
            if (invoiceGuid === null || invoiceGuid === undefined) {
                throw new RequiredError('invoiceGuid', 'Required parameter invoiceGuid was null or undefined when calling invoiceInvoiceGuidIntegrationPut.');
            }
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling invoiceInvoiceGuidIntegrationPut.');
            }
            const localVarPath = `/Invoice/{InvoiceGuid}/Integration`
                .replace(`{${"InvoiceGuid"}}`, encodeURIComponent(String(invoiceGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"InvoiceIntegration" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} invoiceGuid 
         * @param {string} loggedonuser 
         * @param {InvoiceModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceInvoiceGuidPut(invoiceGuid: string, loggedonuser: string, body?: InvoiceModel, options: any = {}): FetchArgs {
            // verify required parameter 'invoiceGuid' is not null or undefined
            if (invoiceGuid === null || invoiceGuid === undefined) {
                throw new RequiredError('invoiceGuid', 'Required parameter invoiceGuid was null or undefined when calling invoiceInvoiceGuidPut.');
            }
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling invoiceInvoiceGuidPut.');
            }
            const localVarPath = `/Invoice/{InvoiceGuid}`
                .replace(`{${"InvoiceGuid"}}`, encodeURIComponent(String(invoiceGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"InvoiceModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [invoiceGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceNotifyInvoiceGuidGet(loggedonuser: string, invoiceGuid?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling invoiceNotifyInvoiceGuidGet.');
            }
            const localVarPath = `/Invoice/notify({InvoiceGuid})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (invoiceGuid !== undefined) {
                localVarQueryParameter['InvoiceGuid'] = invoiceGuid;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [invoiceGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicePermissionInvoiceGuidGet(loggedonuser: string, invoiceGuid?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling invoicePermissionInvoiceGuidGet.');
            }
            const localVarPath = `/Invoice/permission({InvoiceGuid})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (invoiceGuid !== undefined) {
                localVarQueryParameter['InvoiceGuid'] = invoiceGuid;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicePoNumbersGet(loggedonuser: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling invoicePoNumbersGet.');
            }
            const localVarPath = `/Invoice/poNumbers`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {InvoiceModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicePost(loggedonuser: string, body?: InvoiceModel, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling invoicePost.');
            }
            const localVarPath = `/Invoice`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"InvoiceModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [invoiceGuid] 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceRejectInvoiceGuidPost(loggedonuser: string, invoiceGuid?: string, comment?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling invoiceRejectInvoiceGuidPost.');
            }
            const localVarPath = `/Invoice/Reject({InvoiceGuid})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (invoiceGuid !== undefined) {
                localVarQueryParameter['InvoiceGuid'] = invoiceGuid;
            }

            if (comment !== undefined) {
                localVarQueryParameter['comment'] = comment;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [invoiceGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceReviewerHistoryInvoiceGuidGet(loggedonuser: string, invoiceGuid?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling invoiceReviewerHistoryInvoiceGuidGet.');
            }
            const localVarPath = `/Invoice/reviewerHistory({InvoiceGuid})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (invoiceGuid !== undefined) {
                localVarQueryParameter['InvoiceGuid'] = invoiceGuid;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [invoiceGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceReviewersInvoiceGuidGet(loggedonuser: string, invoiceGuid?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling invoiceReviewersInvoiceGuidGet.');
            }
            const localVarPath = `/Invoice/reviewers({InvoiceGuid})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (invoiceGuid !== undefined) {
                localVarQueryParameter['InvoiceGuid'] = invoiceGuid;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {InvoiceModel} [body] 
         * @param {string} [invoiceGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceReviseInvoiceGuidPost(loggedonuser: string, body?: InvoiceModel, invoiceGuid?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling invoiceReviseInvoiceGuidPost.');
            }
            const localVarPath = `/Invoice/Revise({InvoiceGuid})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (invoiceGuid !== undefined) {
                localVarQueryParameter['InvoiceGuid'] = invoiceGuid;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"InvoiceModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Array<FileUpload>} [body] 
         * @param {string} [invoiceGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceInvoiceAddAttachmentInvoiceGuidPost(loggedonuser: string, body?: Array<FileUpload>, invoiceGuid?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser','Required parameter loggedonuser was null or undefined when calling invoiceInvoiceAddAttachmentInvoiceGuidPost.');
            }
            const localVarPath = `/Invoice/InvoiceAddAttachment({InvoiceGuid})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (invoiceGuid !== undefined) {
                localVarQueryParameter['InvoiceGuid'] = invoiceGuid;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;FileUpload&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceApi - functional programming interface
 * @export
 */
export const InvoiceApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} invoiceGuid 
         * @param {string} loggedonuser 
         * @param {InvoiceAdditionalComment} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceAddCommentInvoiceGuidPost(invoiceGuid: string, loggedonuser: string, body?: InvoiceAdditionalComment, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoiceAddCommentInvoiceGuidPost(invoiceGuid, loggedonuser, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceAddInvoiceAuthGet(loggedonuser: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoiceAddInvoiceAuthGet(loggedonuser, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} invoiceGuid 
         * @param {string} loggedonuser 
         * @param {InvoiceApprovalModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceAdditionalReviewerInvoiceGuidPost(invoiceGuid: string, loggedonuser: string, body?: InvoiceApprovalModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<InvoiceApproval>> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoiceAdditionalReviewerInvoiceGuidPost(invoiceGuid, loggedonuser, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [pONumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceApprovalsPONumberGet(loggedonuser: string, pONumber?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<RequisitionApprovalFlow>> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoiceApprovalsPONumberGet(loggedonuser, pONumber, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {ApproveComment} [body] 
         * @param {string} [invoiceGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceApproveInvoiceGuidPost(loggedonuser: string, body?: ApproveComment, invoiceGuid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoiceApproveInvoiceGuidPost(loggedonuser, body, invoiceGuid, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [orgId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceCompanyOrgIdGet(loggedonuser: string, orgId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoiceCompanyOrgIdGet(loggedonuser, orgId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} invoiceGuid 
         * @param {string} loggedonuser 
         * @param {string} [attachmentGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceDeleteInvoiceAttachmentInvoiceGuidDelete(invoiceGuid: string, loggedonuser: string, attachmentGuid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoiceDeleteInvoiceAttachmentInvoiceGuidDelete(invoiceGuid, loggedonuser, attachmentGuid, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [search] 
         * @param {string} [view] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {string} [columnFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceExportExcelGet(loggedonuser: string, search?: string, view?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, columnFilter?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoiceExportExcelGet(loggedonuser, search, view, filter, top, skip, take, orderBy, columnFilter, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [search] 
         * @param {string} [view] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceGet(loggedonuser: string, search?: string, view?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoiceGet(loggedonuser, search, view, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
       /**
         * 
         * @param {string} invoiceGuid 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
       invoiceHasInvoiceAttachmentPermissionInvoiceGuidGet(invoiceGuid: string, loggedonuser: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
        const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoiceHasInvoiceAttachmentPermissionInvoiceGuidGet(invoiceGuid, loggedonuser, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
        /**
         * 
         * @param {string} invoiceGuid 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceHasInvoiceCommentPermissionInvoiceGuidGet(invoiceGuid: string, loggedonuser: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoiceHasInvoiceCommentPermissionInvoiceGuidGet(invoiceGuid, loggedonuser, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} invoiceGuid 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceIntegrationAuthInvoiceGuidGet(invoiceGuid: string, loggedonuser: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoiceIntegrationAuthInvoiceGuidGet(invoiceGuid, loggedonuser, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} invoiceGuid 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceInvoiceGuidGet(invoiceGuid: string, loggedonuser: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InvoiceSingleResult> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoiceInvoiceGuidGet(invoiceGuid, loggedonuser, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} invoiceGuid 
         * @param {string} loggedonuser 
         * @param {InvoiceIntegration} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceInvoiceGuidIntegrationPut(invoiceGuid: string, loggedonuser: string, body?: InvoiceIntegration, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoiceInvoiceGuidIntegrationPut(invoiceGuid, loggedonuser, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} invoiceGuid 
         * @param {string} loggedonuser 
         * @param {InvoiceModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceInvoiceGuidPut(invoiceGuid: string, loggedonuser: string, body?: InvoiceModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoiceInvoiceGuidPut(invoiceGuid, loggedonuser, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [invoiceGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceNotifyInvoiceGuidGet(loggedonuser: string, invoiceGuid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoiceNotifyInvoiceGuidGet(loggedonuser, invoiceGuid, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [invoiceGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicePermissionInvoiceGuidGet(loggedonuser: string, invoiceGuid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RequisitionPermission> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoicePermissionInvoiceGuidGet(loggedonuser, invoiceGuid, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicePoNumbersGet(loggedonuser: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<string>> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoicePoNumbersGet(loggedonuser, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {InvoiceModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicePost(loggedonuser: string, body?: InvoiceModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoicePost(loggedonuser, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [invoiceGuid] 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceRejectInvoiceGuidPost(loggedonuser: string, invoiceGuid?: string, comment?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoiceRejectInvoiceGuidPost(loggedonuser, invoiceGuid, comment, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [invoiceGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceReviewerHistoryInvoiceGuidGet(loggedonuser: string, invoiceGuid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<InvoiceApproval>> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoiceReviewerHistoryInvoiceGuidGet(loggedonuser, invoiceGuid, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [invoiceGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceReviewersInvoiceGuidGet(loggedonuser: string, invoiceGuid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<InvoiceApproval>> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoiceReviewersInvoiceGuidGet(loggedonuser, invoiceGuid, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {InvoiceModel} [body] 
         * @param {string} [invoiceGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceReviseInvoiceGuidPost(loggedonuser: string, body?: InvoiceModel, invoiceGuid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoiceReviseInvoiceGuidPost(loggedonuser, body, invoiceGuid, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
         /**
         * 
         * @param {string} loggedonuser 
         * @param {Array<FileUpload>} [body] 
         * @param {string} [invoiceGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceInvoiceAddAttachmentInvoiceGuidPost(loggedonuser: string, body?: Array<FileUpload>, invoiceGuid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoiceInvoiceAddAttachmentInvoiceGuidPost(loggedonuser, body, invoiceGuid, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * InvoiceApi - factory interface
 * @export
 */
export const InvoiceApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} invoiceGuid 
         * @param {string} loggedonuser 
         * @param {InvoiceAdditionalComment} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceAddCommentInvoiceGuidPost(invoiceGuid: string, loggedonuser: string, body?: InvoiceAdditionalComment, options?: any) {
            return InvoiceApiFp(configuration).invoiceAddCommentInvoiceGuidPost(invoiceGuid, loggedonuser, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceAddInvoiceAuthGet(loggedonuser: string, options?: any) {
            return InvoiceApiFp(configuration).invoiceAddInvoiceAuthGet(loggedonuser, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} invoiceGuid 
         * @param {string} loggedonuser 
         * @param {InvoiceApprovalModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceAdditionalReviewerInvoiceGuidPost(invoiceGuid: string, loggedonuser: string, body?: InvoiceApprovalModel, options?: any) {
            return InvoiceApiFp(configuration).invoiceAdditionalReviewerInvoiceGuidPost(invoiceGuid, loggedonuser, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [pONumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceApprovalsPONumberGet(loggedonuser: string, pONumber?: string, options?: any) {
            return InvoiceApiFp(configuration).invoiceApprovalsPONumberGet(loggedonuser, pONumber, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {ApproveComment} [body] 
         * @param {string} [invoiceGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceApproveInvoiceGuidPost(loggedonuser: string, body?: ApproveComment, invoiceGuid?: string, options?: any) {
            return InvoiceApiFp(configuration).invoiceApproveInvoiceGuidPost(loggedonuser, body, invoiceGuid, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [orgId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceCompanyOrgIdGet(loggedonuser: string, orgId?: string, options?: any) {
            return InvoiceApiFp(configuration).invoiceCompanyOrgIdGet(loggedonuser, orgId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} invoiceGuid 
         * @param {string} loggedonuser 
         * @param {string} [attachmentGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceDeleteInvoiceAttachmentInvoiceGuidDelete(invoiceGuid: string, loggedonuser: string, attachmentGuid?: string, options?: any) {
            return InvoiceApiFp(configuration).invoiceDeleteInvoiceAttachmentInvoiceGuidDelete(invoiceGuid, loggedonuser, attachmentGuid, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [search] 
         * @param {string} [view] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {string} [columnFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceExportExcelGet(loggedonuser: string, search?: string, view?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, columnFilter?: string, options?: any) {
            return InvoiceApiFp(configuration).invoiceExportExcelGet(loggedonuser, search, view, filter, top, skip, take, orderBy, columnFilter, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [search] 
         * @param {string} [view] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceGet(loggedonuser: string, search?: string, view?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return InvoiceApiFp(configuration).invoiceGet(loggedonuser, search, view, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
         /**
         * 
         * @param {string} invoiceGuid 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
      invoiceHasInvoiceAttachmentPermissionInvoiceGuidGet(invoiceGuid: string, loggedonuser: string, options?: any) {
        return InvoiceApiFp(configuration).invoiceHasInvoiceAttachmentPermissionInvoiceGuidGet(invoiceGuid, loggedonuser, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} invoiceGuid 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
         invoiceHasInvoiceCommentPermissionInvoiceGuidGet(invoiceGuid: string, loggedonuser: string, options?: any) {
            return InvoiceApiFp(configuration).invoiceHasInvoiceCommentPermissionInvoiceGuidGet(invoiceGuid, loggedonuser, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} invoiceGuid 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceIntegrationAuthInvoiceGuidGet(invoiceGuid: string, loggedonuser: string, options?: any) {
            return InvoiceApiFp(configuration).invoiceIntegrationAuthInvoiceGuidGet(invoiceGuid, loggedonuser, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} invoiceGuid 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceInvoiceGuidGet(invoiceGuid: string, loggedonuser: string, options?: any) {
            return InvoiceApiFp(configuration).invoiceInvoiceGuidGet(invoiceGuid, loggedonuser, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} invoiceGuid 
         * @param {string} loggedonuser 
         * @param {InvoiceIntegration} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceInvoiceGuidIntegrationPut(invoiceGuid: string, loggedonuser: string, body?: InvoiceIntegration, options?: any) {
            return InvoiceApiFp(configuration).invoiceInvoiceGuidIntegrationPut(invoiceGuid, loggedonuser, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} invoiceGuid 
         * @param {string} loggedonuser 
         * @param {InvoiceModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceInvoiceGuidPut(invoiceGuid: string, loggedonuser: string, body?: InvoiceModel, options?: any) {
            return InvoiceApiFp(configuration).invoiceInvoiceGuidPut(invoiceGuid, loggedonuser, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [invoiceGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceNotifyInvoiceGuidGet(loggedonuser: string, invoiceGuid?: string, options?: any) {
            return InvoiceApiFp(configuration).invoiceNotifyInvoiceGuidGet(loggedonuser, invoiceGuid, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [invoiceGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicePermissionInvoiceGuidGet(loggedonuser: string, invoiceGuid?: string, options?: any) {
            return InvoiceApiFp(configuration).invoicePermissionInvoiceGuidGet(loggedonuser, invoiceGuid, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicePoNumbersGet(loggedonuser: string, options?: any) {
            return InvoiceApiFp(configuration).invoicePoNumbersGet(loggedonuser, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {InvoiceModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicePost(loggedonuser: string, body?: InvoiceModel, options?: any) {
            return InvoiceApiFp(configuration).invoicePost(loggedonuser, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [invoiceGuid] 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceRejectInvoiceGuidPost(loggedonuser: string, invoiceGuid?: string, comment?: string, options?: any) {
            return InvoiceApiFp(configuration).invoiceRejectInvoiceGuidPost(loggedonuser, invoiceGuid, comment, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [invoiceGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceReviewerHistoryInvoiceGuidGet(loggedonuser: string, invoiceGuid?: string, options?: any) {
            return InvoiceApiFp(configuration).invoiceReviewerHistoryInvoiceGuidGet(loggedonuser, invoiceGuid, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [invoiceGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceReviewersInvoiceGuidGet(loggedonuser: string, invoiceGuid?: string, options?: any) {
            return InvoiceApiFp(configuration).invoiceReviewersInvoiceGuidGet(loggedonuser, invoiceGuid, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {InvoiceModel} [body] 
         * @param {string} [invoiceGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceReviseInvoiceGuidPost(loggedonuser: string, body?: InvoiceModel, invoiceGuid?: string, options?: any) {
            return InvoiceApiFp(configuration).invoiceReviseInvoiceGuidPost(loggedonuser, body, invoiceGuid, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Array<FileUpload>} [body] 
         * @param {string} [invoiceGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceInvoiceAddAttachmentInvoiceGuidPost(loggedonuser: string, body?: Array<FileUpload>, invoiceGuid?: string, options?: any) {
            return InvoiceApiFp(configuration).invoiceInvoiceAddAttachmentInvoiceGuidPost(loggedonuser, body, invoiceGuid, options)(fetch, basePath);
        },
    };
};

/**
 * InvoiceApi - object-oriented interface
 * @export
 * @class InvoiceApi
 * @extends {BaseAPI}
 */
export class InvoiceApi extends BaseAPI {
    /**
     * 
     * @param {string} invoiceGuid 
     * @param {string} loggedonuser 
     * @param {InvoiceAdditionalComment} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoiceAddCommentInvoiceGuidPost(invoiceGuid: string, loggedonuser: string, body?: InvoiceAdditionalComment, options?: any) {
        return InvoiceApiFp(this.configuration).invoiceAddCommentInvoiceGuidPost(invoiceGuid, loggedonuser, body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {string} loggedonuser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoiceAddInvoiceAuthGet(loggedonuser: string, options?: any) {
        return InvoiceApiFp(this.configuration).invoiceAddInvoiceAuthGet(loggedonuser, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} invoiceGuid 
     * @param {string} loggedonuser 
     * @param {InvoiceApprovalModel} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoiceAdditionalReviewerInvoiceGuidPost(invoiceGuid: string, loggedonuser: string, body?: InvoiceApprovalModel, options?: any) {
        return InvoiceApiFp(this.configuration).invoiceAdditionalReviewerInvoiceGuidPost(invoiceGuid, loggedonuser, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [pONumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoiceApprovalsPONumberGet(loggedonuser: string, pONumber?: string, options?: any) {
        return InvoiceApiFp(this.configuration).invoiceApprovalsPONumberGet(loggedonuser, pONumber, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {ApproveComment} [body] 
     * @param {string} [invoiceGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoiceApproveInvoiceGuidPost(loggedonuser: string, body?: ApproveComment, invoiceGuid?: string, options?: any) {
        return InvoiceApiFp(this.configuration).invoiceApproveInvoiceGuidPost(loggedonuser, body, invoiceGuid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [orgId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoiceCompanyOrgIdGet(loggedonuser: string, orgId?: string, options?: any) {
        return InvoiceApiFp(this.configuration).invoiceCompanyOrgIdGet(loggedonuser, orgId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} invoiceGuid 
     * @param {string} loggedonuser 
     * @param {string} [attachmentGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoiceDeleteInvoiceAttachmentInvoiceGuidDelete(invoiceGuid: string, loggedonuser: string, attachmentGuid?: string, options?: any) {
        return InvoiceApiFp(this.configuration).invoiceDeleteInvoiceAttachmentInvoiceGuidDelete(invoiceGuid, loggedonuser, attachmentGuid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [search] 
     * @param {string} [view] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {string} [columnFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoiceExportExcelGet(loggedonuser: string, search?: string, view?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, columnFilter?: string, options?: any) {
        return InvoiceApiFp(this.configuration).invoiceExportExcelGet(loggedonuser, search, view, filter, top, skip, take, orderBy, columnFilter, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [search] 
     * @param {string} [view] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoiceGet(loggedonuser: string, search?: string, view?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return InvoiceApiFp(this.configuration).invoiceGet(loggedonuser, search, view, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }
   /**
     * 
     * @param {string} invoiceGuid 
     * @param {string} loggedonuser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
   public invoiceHasInvoiceAttachmentPermissionInvoiceGuidGet(invoiceGuid: string, loggedonuser: string, options?: any) {
    return InvoiceApiFp(this.configuration).invoiceHasInvoiceAttachmentPermissionInvoiceGuidGet(invoiceGuid, loggedonuser, options)(this.fetch, this.basePath);
}
    /**
     * 
     * @param {string} invoiceGuid 
     * @param {string} loggedonuser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoiceHasInvoiceCommentPermissionInvoiceGuidGet(invoiceGuid: string, loggedonuser: string, options?: any) {
        return InvoiceApiFp(this.configuration).invoiceHasInvoiceCommentPermissionInvoiceGuidGet(invoiceGuid, loggedonuser, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} invoiceGuid 
     * @param {string} loggedonuser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoiceIntegrationAuthInvoiceGuidGet(invoiceGuid: string, loggedonuser: string, options?: any) {
        return InvoiceApiFp(this.configuration).invoiceIntegrationAuthInvoiceGuidGet(invoiceGuid, loggedonuser, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} invoiceGuid 
     * @param {string} loggedonuser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoiceInvoiceGuidGet(invoiceGuid: string, loggedonuser: string, options?: any) {
        return InvoiceApiFp(this.configuration).invoiceInvoiceGuidGet(invoiceGuid, loggedonuser, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} invoiceGuid 
     * @param {string} loggedonuser 
     * @param {InvoiceIntegration} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoiceInvoiceGuidIntegrationPut(invoiceGuid: string, loggedonuser: string, body?: InvoiceIntegration, options?: any) {
        return InvoiceApiFp(this.configuration).invoiceInvoiceGuidIntegrationPut(invoiceGuid, loggedonuser, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} invoiceGuid 
     * @param {string} loggedonuser 
     * @param {InvoiceModel} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoiceInvoiceGuidPut(invoiceGuid: string, loggedonuser: string, body?: InvoiceModel, options?: any) {
        return InvoiceApiFp(this.configuration).invoiceInvoiceGuidPut(invoiceGuid, loggedonuser, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [invoiceGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoiceNotifyInvoiceGuidGet(loggedonuser: string, invoiceGuid?: string, options?: any) {
        return InvoiceApiFp(this.configuration).invoiceNotifyInvoiceGuidGet(loggedonuser, invoiceGuid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [invoiceGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoicePermissionInvoiceGuidGet(loggedonuser: string, invoiceGuid?: string, options?: any) {
        return InvoiceApiFp(this.configuration).invoicePermissionInvoiceGuidGet(loggedonuser, invoiceGuid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoicePoNumbersGet(loggedonuser: string, options?: any) {
        return InvoiceApiFp(this.configuration).invoicePoNumbersGet(loggedonuser, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {InvoiceModel} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoicePost(loggedonuser: string, body?: InvoiceModel, options?: any) {
        return InvoiceApiFp(this.configuration).invoicePost(loggedonuser, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [invoiceGuid] 
     * @param {string} [comment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoiceRejectInvoiceGuidPost(loggedonuser: string, invoiceGuid?: string, comment?: string, options?: any) {
        return InvoiceApiFp(this.configuration).invoiceRejectInvoiceGuidPost(loggedonuser, invoiceGuid, comment, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [invoiceGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoiceReviewerHistoryInvoiceGuidGet(loggedonuser: string, invoiceGuid?: string, options?: any) {
        return InvoiceApiFp(this.configuration).invoiceReviewerHistoryInvoiceGuidGet(loggedonuser, invoiceGuid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [invoiceGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoiceReviewersInvoiceGuidGet(loggedonuser: string, invoiceGuid?: string, options?: any) {
        return InvoiceApiFp(this.configuration).invoiceReviewersInvoiceGuidGet(loggedonuser, invoiceGuid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {InvoiceModel} [body] 
     * @param {string} [invoiceGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoiceReviseInvoiceGuidPost(loggedonuser: string, body?: InvoiceModel, invoiceGuid?: string, options?: any) {
        return InvoiceApiFp(this.configuration).invoiceReviseInvoiceGuidPost(loggedonuser, body, invoiceGuid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {Array<FileUpload>} [body] 
     * @param {string} [invoiceGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoiceInvoiceAddAttachmentInvoiceGuidPost(loggedonuser: string, body?: Array<FileUpload>, invoiceGuid?: string, options?: any) {
        return InvoiceApiFp(this.configuration).invoiceInvoiceAddAttachmentInvoiceGuidPost(loggedonuser, body, invoiceGuid, options)(this.fetch, this.basePath);
    }

}
/**
 * MetadataApi - fetch parameter creator
 * @export
 */
export const MetadataApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odata(loggedonuser: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling odata.');
            }
            const localVarPath = `/odata`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odatametadata(loggedonuser: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling odatametadata.');
            }
            const localVarPath = `/odata/$metadata`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetadataApi - functional programming interface
 * @export
 */
export const MetadataApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odata(loggedonuser: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ODataServiceDocument> {
            const localVarFetchArgs = MetadataApiFetchParamCreator(configuration).odata(loggedonuser, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odatametadata(loggedonuser: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IEdmModel> {
            const localVarFetchArgs = MetadataApiFetchParamCreator(configuration).odatametadata(loggedonuser, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * MetadataApi - factory interface
 * @export
 */
export const MetadataApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odata(loggedonuser: string, options?: any) {
            return MetadataApiFp(configuration).odata(loggedonuser, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odatametadata(loggedonuser: string, options?: any) {
            return MetadataApiFp(configuration).odatametadata(loggedonuser, options)(fetch, basePath);
        },
    };
};

/**
 * MetadataApi - object-oriented interface
 * @export
 * @class MetadataApi
 * @extends {BaseAPI}
 */
export class MetadataApi extends BaseAPI {
    /**
     * 
     * @param {string} loggedonuser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadataApi
     */
    public odata(loggedonuser: string, options?: any) {
        return MetadataApiFp(this.configuration).odata(loggedonuser, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadataApi
     */
    public odatametadata(loggedonuser: string, options?: any) {
        return MetadataApiFp(this.configuration).odatametadata(loggedonuser, options)(this.fetch, this.basePath);
    }

}
/**
 * PurchaseItemApi - fetch parameter creator
 * @export
 */
export const PurchaseItemApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [purchaseRequisitionGuid] 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseItemGet(loggedonuser: string, purchaseRequisitionGuid?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling purchaseItemGet.');
            }
            const localVarPath = `/PurchaseItem`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (purchaseRequisitionGuid !== undefined) {
                localVarQueryParameter['purchaseRequisitionGuid'] = purchaseRequisitionGuid;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseItemKeyDelete(loggedonuser: string, key?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling purchaseItemKeyDelete.');
            }
            const localVarPath = `/PurchaseItem({key})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseItemKeyGet(loggedonuser: string, key?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling purchaseItemKeyGet.');
            }
            const localVarPath = `/PurchaseItem({key})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {PurchaseItem} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseItemPost(loggedonuser: string, body?: PurchaseItem, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling purchaseItemPost.');
            }
            const localVarPath = `/PurchaseItem`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PurchaseItem" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {PurchaseItem} [body] 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseItemPut(loggedonuser: string, body?: PurchaseItem, key?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling purchaseItemPut.');
            }
            const localVarPath = `/PurchaseItem`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PurchaseItem" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PurchaseItemApi - functional programming interface
 * @export
 */
export const PurchaseItemApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [purchaseRequisitionGuid] 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseItemGet(loggedonuser: string, purchaseRequisitionGuid?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseItemApiFetchParamCreator(configuration).purchaseItemGet(loggedonuser, purchaseRequisitionGuid, search, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseItemKeyDelete(loggedonuser: string, key?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseItemApiFetchParamCreator(configuration).purchaseItemKeyDelete(loggedonuser, key, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseItemKeyGet(loggedonuser: string, key?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PurchaseItemSingleResult> {
            const localVarFetchArgs = PurchaseItemApiFetchParamCreator(configuration).purchaseItemKeyGet(loggedonuser, key, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {PurchaseItem} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseItemPost(loggedonuser: string, body?: PurchaseItem, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseItemApiFetchParamCreator(configuration).purchaseItemPost(loggedonuser, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {PurchaseItem} [body] 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseItemPut(loggedonuser: string, body?: PurchaseItem, key?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseItemApiFetchParamCreator(configuration).purchaseItemPut(loggedonuser, body, key, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PurchaseItemApi - factory interface
 * @export
 */
export const PurchaseItemApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [purchaseRequisitionGuid] 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseItemGet(loggedonuser: string, purchaseRequisitionGuid?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return PurchaseItemApiFp(configuration).purchaseItemGet(loggedonuser, purchaseRequisitionGuid, search, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseItemKeyDelete(loggedonuser: string, key?: string, options?: any) {
            return PurchaseItemApiFp(configuration).purchaseItemKeyDelete(loggedonuser, key, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseItemKeyGet(loggedonuser: string, key?: string, options?: any) {
            return PurchaseItemApiFp(configuration).purchaseItemKeyGet(loggedonuser, key, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {PurchaseItem} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseItemPost(loggedonuser: string, body?: PurchaseItem, options?: any) {
            return PurchaseItemApiFp(configuration).purchaseItemPost(loggedonuser, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {PurchaseItem} [body] 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseItemPut(loggedonuser: string, body?: PurchaseItem, key?: string, options?: any) {
            return PurchaseItemApiFp(configuration).purchaseItemPut(loggedonuser, body, key, options)(fetch, basePath);
        },
    };
};

/**
 * PurchaseItemApi - object-oriented interface
 * @export
 * @class PurchaseItemApi
 * @extends {BaseAPI}
 */
export class PurchaseItemApi extends BaseAPI {
    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [purchaseRequisitionGuid] 
     * @param {string} [search] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseItemApi
     */
    public purchaseItemGet(loggedonuser: string, purchaseRequisitionGuid?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return PurchaseItemApiFp(this.configuration).purchaseItemGet(loggedonuser, purchaseRequisitionGuid, search, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseItemApi
     */
    public purchaseItemKeyDelete(loggedonuser: string, key?: string, options?: any) {
        return PurchaseItemApiFp(this.configuration).purchaseItemKeyDelete(loggedonuser, key, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseItemApi
     */
    public purchaseItemKeyGet(loggedonuser: string, key?: string, options?: any) {
        return PurchaseItemApiFp(this.configuration).purchaseItemKeyGet(loggedonuser, key, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {PurchaseItem} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseItemApi
     */
    public purchaseItemPost(loggedonuser: string, body?: PurchaseItem, options?: any) {
        return PurchaseItemApiFp(this.configuration).purchaseItemPost(loggedonuser, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {PurchaseItem} [body] 
     * @param {string} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseItemApi
     */
    public purchaseItemPut(loggedonuser: string, body?: PurchaseItem, key?: string, options?: any) {
        return PurchaseItemApiFp(this.configuration).purchaseItemPut(loggedonuser, body, key, options)(this.fetch, this.basePath);
    }

}
/**
 * PurchaseOrderApi - fetch parameter creator
 * @export
 */
export const PurchaseOrderApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} poID 
         * @param {string} companyCode 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderCompanyCodePoIDGet(poID: string, companyCode: string, loggedonuser: string, options: any = {}): FetchArgs {
            // verify required parameter 'poID' is not null or undefined
            if (poID === null || poID === undefined) {
                throw new RequiredError('poID', 'Required parameter poID was null or undefined when calling purchaseOrderCompanyCodePoIDGet.');
            }
            // verify required parameter 'companyCode' is not null or undefined
            if (companyCode === null || companyCode === undefined) {
                throw new RequiredError('companyCode', 'Required parameter companyCode was null or undefined when calling purchaseOrderCompanyCodePoIDGet.');
            }
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling purchaseOrderCompanyCodePoIDGet.');
            }
            const localVarPath = `/PurchaseOrder/{companyCode}/{poID}`
                .replace(`{${"poID"}}`, encodeURIComponent(String(poID)))
                .replace(`{${"companyCode"}}`, encodeURIComponent(String(companyCode)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [actionFilter] 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {string} [columnFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderExportExcelGet(loggedonuser: string, actionFilter?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, columnFilter?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser','Required parameter loggedonuser was null or undefined when calling purchaseOrderExportExcelGet.');
            }
            const localVarPath = `/PurchaseOrder/ExportExcel`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (actionFilter !== undefined) {
                localVarQueryParameter['actionFilter'] = actionFilter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (columnFilter !== undefined) {
                localVarQueryParameter['columnFilter'] = columnFilter;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [actionFilter] 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderGet(loggedonuser: string, actionFilter?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling purchaseOrderGet.');
            }
            const localVarPath = `/PurchaseOrder`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (actionFilter !== undefined) {
                localVarQueryParameter['actionFilter'] = actionFilter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderIsAuthorizeToViewAllGet(loggedonuser: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling purchaseOrderIsAuthorizeToViewAllGet.');
            }
            const localVarPath = `/PurchaseOrder/IsAuthorizeToViewAll`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} poID 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderPoIDGet(poID: string, loggedonuser: string, options: any = {}): FetchArgs {
            // verify required parameter 'poID' is not null or undefined
            if (poID === null || poID === undefined) {
                throw new RequiredError('poID', 'Required parameter poID was null or undefined when calling purchaseOrderPoIDGet.');
            }
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling purchaseOrderPoIDGet.');
            }
            const localVarPath = `/PurchaseOrder/{poID}`
                .replace(`{${"poID"}}`, encodeURIComponent(String(poID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
         /**
         * 
         * @param {string} poNumber 
         * @param {string} prNumber 
         * @param {string} companyCode 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
         purchaseOrderPoNumberPrNumberCompanyCodeSyncPost(poNumber: string, prNumber: string, companyCode: string, loggedonuser: string, options: any = {}): FetchArgs {
            // verify required parameter 'poNumber' is not null or undefined
            if (poNumber === null || poNumber === undefined) {
                throw new RequiredError('poNumber','Required parameter poNumber was null or undefined when calling purchaseOrderPoNumberPrNumberCompanyCodeSyncPost.');
            }
            // verify required parameter 'prNumber' is not null or undefined
            if (prNumber === null || prNumber === undefined) {
                throw new RequiredError('prNumber','Required parameter prNumber was null or undefined when calling purchaseOrderPoNumberPrNumberCompanyCodeSyncPost.');
            }
            // verify required parameter 'companyCode' is not null or undefined
            if (companyCode === null || companyCode === undefined) {
                throw new RequiredError('companyCode','Required parameter companyCode was null or undefined when calling purchaseOrderPoNumberPrNumberCompanyCodeSyncPost.');
            }
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser','Required parameter loggedonuser was null or undefined when calling purchaseOrderPoNumberPrNumberCompanyCodeSyncPost.');
            }
            const localVarPath = `/PurchaseOrder/{poNumber}/{prNumber}/{companyCode}/Sync`
                .replace(`{${"poNumber"}}`, encodeURIComponent(String(poNumber)))
                .replace(`{${"prNumber"}}`, encodeURIComponent(String(prNumber)))
                .replace(`{${"companyCode"}}`, encodeURIComponent(String(companyCode)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {PurchaseOrderPost} [body] 
         * @param {string} [prNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderPost(loggedonuser: string, body?: PurchaseOrderPost, prNumber?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling purchaseOrderPost.');
            }
            const localVarPath = `/PurchaseOrder`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (prNumber !== undefined) {
                localVarQueryParameter['prNumber'] = prNumber;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PurchaseOrderPost" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {PurchaseOrderPost} [body] 
         * @param {string} [prNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderPut(loggedonuser: string, body?: PurchaseOrderPost, prNumber?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling purchaseOrderPut.');
            }
            const localVarPath = `/PurchaseOrder`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (prNumber !== undefined) {
                localVarQueryParameter['prNumber'] = prNumber;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PurchaseOrderPost" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PurchaseOrderApi - functional programming interface
 * @export
 */
export const PurchaseOrderApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} poID 
         * @param {string} companyCode 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderCompanyCodePoIDGet(poID: string, companyCode: string, loggedonuser: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PurchaseOrderInvoiceDetailSingleResult> {
            const localVarFetchArgs = PurchaseOrderApiFetchParamCreator(configuration).purchaseOrderCompanyCodePoIDGet(poID, companyCode, loggedonuser, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [actionFilter] 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {string} [columnFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderExportExcelGet(loggedonuser: string, actionFilter?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, columnFilter?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseOrderApiFetchParamCreator(configuration).purchaseOrderExportExcelGet(loggedonuser, actionFilter, search, filter, top, skip, take, orderBy, columnFilter, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [actionFilter] 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderGet(loggedonuser: string, actionFilter?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseOrderApiFetchParamCreator(configuration).purchaseOrderGet(loggedonuser, actionFilter, search, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderIsAuthorizeToViewAllGet(loggedonuser: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseOrderApiFetchParamCreator(configuration).purchaseOrderIsAuthorizeToViewAllGet(loggedonuser, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} poID 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderPoIDGet(poID: string, loggedonuser: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PurchaseOrderInvoiceDetailSingleResult> {
            const localVarFetchArgs = PurchaseOrderApiFetchParamCreator(configuration).purchaseOrderPoIDGet(poID, loggedonuser, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
           /**
         * 
         * @param {string} poNumber 
         * @param {string} prNumber 
         * @param {string} companyCode 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
           purchaseOrderPoNumberPrNumberCompanyCodeSyncPost(poNumber: string, prNumber: string, companyCode: string, loggedonuser: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseOrderApiFetchParamCreator(configuration).purchaseOrderPoNumberPrNumberCompanyCodeSyncPost(poNumber, prNumber, companyCode, loggedonuser, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {PurchaseOrderPost} [body] 
         * @param {string} [prNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderPost(loggedonuser: string, body?: PurchaseOrderPost, prNumber?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseOrderApiFetchParamCreator(configuration).purchaseOrderPost(loggedonuser, body, prNumber, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {PurchaseOrderPost} [body] 
         * @param {string} [prNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderPut(loggedonuser: string, body?: PurchaseOrderPost, prNumber?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseOrderApiFetchParamCreator(configuration).purchaseOrderPut(loggedonuser, body, prNumber, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PurchaseOrderApi - factory interface
 * @export
 */
export const PurchaseOrderApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} poID 
         * @param {string} companyCode 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderCompanyCodePoIDGet(poID: string, companyCode: string, loggedonuser: string, options?: any) {
            return PurchaseOrderApiFp(configuration).purchaseOrderCompanyCodePoIDGet(poID, companyCode, loggedonuser, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [actionFilter] 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {string} [columnFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderExportExcelGet(loggedonuser: string, actionFilter?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, columnFilter?: string, options?: any) {
            return PurchaseOrderApiFp(configuration).purchaseOrderExportExcelGet(loggedonuser, actionFilter, search, filter, top, skip, take, orderBy, columnFilter, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [actionFilter] 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderGet(loggedonuser: string, actionFilter?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return PurchaseOrderApiFp(configuration).purchaseOrderGet(loggedonuser, actionFilter, search, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderIsAuthorizeToViewAllGet(loggedonuser: string, options?: any) {
            return PurchaseOrderApiFp(configuration).purchaseOrderIsAuthorizeToViewAllGet(loggedonuser, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} poID 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderPoIDGet(poID: string, loggedonuser: string, options?: any) {
            return PurchaseOrderApiFp(configuration).purchaseOrderPoIDGet(poID, loggedonuser, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} poNumber 
         * @param {string} prNumber 
         * @param {string} companyCode 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderPoNumberPrNumberCompanyCodeSyncPost(poNumber: string, prNumber: string, companyCode: string, loggedonuser: string, options?: any) {
            return PurchaseOrderApiFp(configuration).purchaseOrderPoNumberPrNumberCompanyCodeSyncPost(poNumber, prNumber, companyCode, loggedonuser, options)(fetch, basePath);
        },

        /**
         * 
         * @param {string} loggedonuser 
         * @param {PurchaseOrderPost} [body] 
         * @param {string} [prNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderPost(loggedonuser: string, body?: PurchaseOrderPost, prNumber?: string, options?: any) {
            return PurchaseOrderApiFp(configuration).purchaseOrderPost(loggedonuser, body, prNumber, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {PurchaseOrderPost} [body] 
         * @param {string} [prNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseOrderPut(loggedonuser: string, body?: PurchaseOrderPost, prNumber?: string, options?: any) {
            return PurchaseOrderApiFp(configuration).purchaseOrderPut(loggedonuser, body, prNumber, options)(fetch, basePath);
        },
    };
};

/**
 * PurchaseOrderApi - object-oriented interface
 * @export
 * @class PurchaseOrderApi
 * @extends {BaseAPI}
 */
export class PurchaseOrderApi extends BaseAPI {
    /**
     * 
     * @param {string} poID 
     * @param {string} companyCode 
     * @param {string} loggedonuser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrderApi
     */
    public purchaseOrderCompanyCodePoIDGet(poID: string, companyCode: string, loggedonuser: string, options?: any) {
        return PurchaseOrderApiFp(this.configuration).purchaseOrderCompanyCodePoIDGet(poID, companyCode, loggedonuser, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [actionFilter] 
     * @param {string} [search] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {string} [columnFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrderApi
     */
    public purchaseOrderExportExcelGet(loggedonuser: string, actionFilter?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, columnFilter?: string, options?: any) {
        return PurchaseOrderApiFp(this.configuration).purchaseOrderExportExcelGet(loggedonuser, actionFilter, search, filter, top, skip, take, orderBy, columnFilter, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [actionFilter] 
     * @param {string} [search] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrderApi
     */
    public purchaseOrderGet(loggedonuser: string, actionFilter?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return PurchaseOrderApiFp(this.configuration).purchaseOrderGet(loggedonuser, actionFilter, search, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrderApi
     */
    public purchaseOrderIsAuthorizeToViewAllGet(loggedonuser: string, options?: any) {
        return PurchaseOrderApiFp(this.configuration).purchaseOrderIsAuthorizeToViewAllGet(loggedonuser, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} poID 
     * @param {string} loggedonuser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrderApi
     */
    public purchaseOrderPoIDGet(poID: string, loggedonuser: string, options?: any) {
        return PurchaseOrderApiFp(this.configuration).purchaseOrderPoIDGet(poID, loggedonuser, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {string} poNumber 
     * @param {string} prNumber 
     * @param {string} companyCode 
     * @param {string} loggedonuser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrderApi
     */
    public purchaseOrderPoNumberPrNumberCompanyCodeSyncPost(poNumber: string, prNumber: string, companyCode: string, loggedonuser: string, options?: any) {
        return PurchaseOrderApiFp(this.configuration).purchaseOrderPoNumberPrNumberCompanyCodeSyncPost(poNumber, prNumber, companyCode, loggedonuser, options)(this.fetch, this.basePath);
    }
		
    /**
     * 
     * @param {string} loggedonuser 
     * @param {PurchaseOrderPost} [body] 
     * @param {string} [prNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrderApi
     */
    public purchaseOrderPost(loggedonuser: string, body?: PurchaseOrderPost, prNumber?: string, options?: any) {
        return PurchaseOrderApiFp(this.configuration).purchaseOrderPost(loggedonuser, body, prNumber, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {PurchaseOrderPost} [body] 
     * @param {string} [prNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrderApi
     */
    public purchaseOrderPut(loggedonuser: string, body?: PurchaseOrderPost, prNumber?: string, options?: any) {
        return PurchaseOrderApiFp(this.configuration).purchaseOrderPut(loggedonuser, body, prNumber, options)(this.fetch, this.basePath);
    }

}
/**
 * PurchaseRequisitionApi - fetch parameter creator
 * @export
 */
export const PurchaseRequisitionApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} prGuid 
         * @param {string} loggedonuser 
         * @param {PurchaseRequisitionAdditionalComment} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionAddCommentPrGuidPost(prGuid: string, loggedonuser: string, body?: PurchaseRequisitionAdditionalComment, options: any = {}): FetchArgs {
            // verify required parameter 'prGuid' is not null or undefined
            if (prGuid === null || prGuid === undefined) {
                throw new RequiredError('prGuid','Required parameter prGuid was null or undefined when calling purchaseRequisitionAddCommentPrGuidPost.');
            }
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser','Required parameter loggedonuser was null or undefined when calling purchaseRequisitionAddCommentPrGuidPost.');
            }
            const localVarPath = `/PurchaseRequisition/AddComment/{prGuid}`
                .replace(`{${"prGuid"}}`, encodeURIComponent(String(prGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PurchaseRequisitionAdditionalComment" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionApprovalFlowKeyGet(loggedonuser: string, key?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling purchaseRequisitionApprovalFlowKeyGet.');
            }
            const localVarPath = `/PurchaseRequisition/ApprovalFlow({key})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionApprovalFlowLogKeyGet(loggedonuser: string, key?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling purchaseRequisitionApprovalFlowLogKeyGet.');
            }
            const localVarPath = `/PurchaseRequisition/ApprovalFlowLog({key})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {string} [comment] 
         * @param {number} [currentLevel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionApproveKeyPost(loggedonuser: string, key?: string, comment?: string, currentLevel?: number, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling purchaseRequisitionApproveKeyPost.');
            }
            const localVarPath = `/PurchaseRequisition/Approve({key})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (comment !== undefined) {
                localVarQueryParameter['comment'] = comment;
            }

            if (currentLevel !== undefined) {
                localVarQueryParameter['currentLevel'] = currentLevel;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [flowGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionApproverDelete(loggedonuser: string, flowGuid?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling purchaseRequisitionApproverDelete.');
            }
            const localVarPath = `/PurchaseRequisition/Approver`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (flowGuid !== undefined) {
                localVarQueryParameter['flowGuid'] = flowGuid;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [requisitionGuid] 
         * @param {string} [approverGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionApproverPost(loggedonuser: string, requisitionGuid?: string, approverGuid?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling purchaseRequisitionApproverPost.');
            }
            const localVarPath = `/PurchaseRequisition/Approver`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (requisitionGuid !== undefined) {
                localVarQueryParameter['requisitionGuid'] = requisitionGuid;
            }

            if (approverGuid !== undefined) {
                localVarQueryParameter['approverGuid'] = approverGuid;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [requisitionApprovelFlowGuid] 
         * @param {string} [approverGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionApproverPut(loggedonuser: string, requisitionApprovelFlowGuid?: string, approverGuid?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling purchaseRequisitionApproverPut.');
            }
            const localVarPath = `/PurchaseRequisition/Approver`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (requisitionApprovelFlowGuid !== undefined) {
                localVarQueryParameter['requisitionApprovelFlowGuid'] = requisitionApprovelFlowGuid;
            }

            if (approverGuid !== undefined) {
                localVarQueryParameter['approverGuid'] = approverGuid;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
                /**
         * 
         * @param {string} prGuid 
         * @param {string} loggedonuser 
         * @param {string} [attachmentGuid] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
                purchaseRequisitionDeletePurchaseRequisitionAttachmentPrGuidDelete(prGuid: string, loggedonuser: string, attachmentGuid?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
                    // verify required parameter 'prGuid' is not null or undefined
                    if (prGuid === null || prGuid === undefined) {
                        throw new RequiredError('prGuid','Required parameter prGuid was null or undefined when calling purchaseRequisitionDeletePurchaseRequisitionAttachmentPrGuidDelete.');
                    }
                    // verify required parameter 'loggedonuser' is not null or undefined
                    if (loggedonuser === null || loggedonuser === undefined) {
                        throw new RequiredError('loggedonuser','Required parameter loggedonuser was null or undefined when calling purchaseRequisitionDeletePurchaseRequisitionAttachmentPrGuidDelete.');
                    }
                    const localVarPath = `/PurchaseRequisition/DeletePurchaseRequisitionAttachment/{prGuid}`
                        .replace(`{${"prGuid"}}`, encodeURIComponent(String(prGuid)));
                    const localVarUrlObj = url.parse(localVarPath, true);
                    const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
                    const localVarHeaderParameter = {} as any;
                    const localVarQueryParameter = {} as any;
        
                    // authentication Bearer required
                    if (configuration && configuration.apiKey) {
                        const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                            ? configuration.apiKey("Authorization")
                            : configuration.apiKey;
                        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
                    }
        
                    if (attachmentGuid !== undefined) {
                        localVarQueryParameter['AttachmentGuid'] = attachmentGuid;
                    }
        
                    if (filter !== undefined) {
                        localVarQueryParameter['Filter'] = filter;
                    }
        
                    if (top !== undefined) {
                        localVarQueryParameter['Top'] = top;
                    }
        
                    if (skip !== undefined) {
                        localVarQueryParameter['Skip'] = skip;
                    }
        
                    if (take !== undefined) {
                        localVarQueryParameter['Take'] = take;
                    }
        
                    if (orderBy !== undefined) {
                        localVarQueryParameter['OrderBy'] = orderBy;
                    }
        
                    if (loggedonuser !== undefined && loggedonuser !== null) {
                        localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
                    }
        
                    localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        
                    return {
                        url: url.format(localVarUrlObj),
                        options: localVarRequestOptions,
                    };
                },
        /**
         * 
         * @param {string} poNumber 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionDetailPoNumberGet(poNumber: string, loggedonuser: string, options: any = {}): FetchArgs {
            // verify required parameter 'poNumber' is not null or undefined
            if (poNumber === null || poNumber === undefined) {
                throw new RequiredError('poNumber', 'Required parameter poNumber was null or undefined when calling purchaseRequisitionDetailPoNumberGet.');
            }
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling purchaseRequisitionDetailPoNumberGet.');
            }
            const localVarPath = `/PurchaseRequisition/Detail/{poNumber}`
                .replace(`{${"poNumber"}}`, encodeURIComponent(String(poNumber)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [actionFilter] 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {string} [columnFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionExportExcelGet(loggedonuser: string, actionFilter?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, columnFilter?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser','Required parameter loggedonuser was null or undefined when calling purchaseRequisitionExportExcelGet.');
            }
            const localVarPath = `/PurchaseRequisition/ExportExcel`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (actionFilter !== undefined) {
                localVarQueryParameter['actionFilter'] = actionFilter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (columnFilter !== undefined) {
                localVarQueryParameter['columnFilter'] = columnFilter;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [actionFilter] 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionGet(loggedonuser: string, actionFilter?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling purchaseRequisitionGet.');
            }
            const localVarPath = `/PurchaseRequisition`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (actionFilter !== undefined) {
                localVarQueryParameter['actionFilter'] = actionFilter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
                 /**
         * 
         * @param {string} prGuid 
         * @param {string} loggedonuser 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionHasPurchaseRequisitionAttachmentPermissionPrGuidGet(prGuid: string, loggedonuser: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
        // verify required parameter 'prGuid' is not null or undefined
        if (prGuid === null || prGuid === undefined) {
            throw new RequiredError('prGuid','Required parameter prGuid was null or undefined when calling purchaseRequisitionHasPurchaseRequisitionAttachmentPermissionPrGuidGet.');
        }
        // verify required parameter 'loggedonuser' is not null or undefined
        if (loggedonuser === null || loggedonuser === undefined) {
            throw new RequiredError('loggedonuser','Required parameter loggedonuser was null or undefined when calling purchaseRequisitionHasPurchaseRequisitionAttachmentPermissionPrGuidGet.');
        }
        const localVarPath = `/PurchaseRequisition/HasPurchaseRequisitionAttachmentPermission/{prGuid}`
            .replace(`{${"prGuid"}}`, encodeURIComponent(String(prGuid)));
        const localVarUrlObj = url.parse(localVarPath, true);
        const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        // authentication Bearer required
        if (configuration && configuration.apiKey) {
            const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                ? configuration.apiKey("Authorization")
                : configuration.apiKey;
            localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
        }

        if (filter !== undefined) {
            localVarQueryParameter['Filter'] = filter;
        }

        if (top !== undefined) {
            localVarQueryParameter['Top'] = top;
        }

        if (skip !== undefined) {
            localVarQueryParameter['Skip'] = skip;
        }

        if (take !== undefined) {
            localVarQueryParameter['Take'] = take;
        }

        if (orderBy !== undefined) {
            localVarQueryParameter['OrderBy'] = orderBy;
        }

        if (loggedonuser !== undefined && loggedonuser !== null) {
            localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
        }

        localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
        // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
        delete localVarUrlObj.search;
        localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

        return {
            url: url.format(localVarUrlObj),
            options: localVarRequestOptions,
        };
    },
        /**
         * 
         * @param {string} prGuid 
         * @param {string} loggedonuser 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionHasPurchaseRequisitionCommentPermissionPrGuidGet(prGuid: string, loggedonuser: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            // verify required parameter 'prGuid' is not null or undefined
            if (prGuid === null || prGuid === undefined) {
                throw new RequiredError('prGuid','Required parameter prGuid was null or undefined when calling purchaseRequisitionHasPurchaseRequisitionCommentPermissionPrGuidGet.');
            }
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser','Required parameter loggedonuser was null or undefined when calling purchaseRequisitionHasPurchaseRequisitionCommentPermissionPrGuidGet.');
            }
            const localVarPath = `/PurchaseRequisition/HasPurchaseRequisitionCommentPermission/{prGuid}`
                .replace(`{${"prGuid"}}`, encodeURIComponent(String(prGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} prGuid 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionIntegrateAuthPrGuidGet(prGuid: string, loggedonuser: string, options: any = {}): FetchArgs {
            // verify required parameter 'prGuid' is not null or undefined
            if (prGuid === null || prGuid === undefined) {
                throw new RequiredError('prGuid', 'Required parameter prGuid was null or undefined when calling purchaseRequisitionIntegrateAuthPrGuidGet.');
            }
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling purchaseRequisitionIntegrateAuthPrGuidGet.');
            }
            const localVarPath = `/PurchaseRequisition/Integrate/Auth/{prGuid}`
                .replace(`{${"prGuid"}}`, encodeURIComponent(String(prGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionKeyDelete(loggedonuser: string, key?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling purchaseRequisitionKeyDelete.');
            }
            const localVarPath = `/PurchaseRequisition({key})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionKeyGet(loggedonuser: string, key?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling purchaseRequisitionKeyGet.');
            }
            const localVarPath = `/PurchaseRequisition({key})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} poNumber 
         * @param {string} loggedonuser 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionPOHistoryPoNumberGet(poNumber: string, loggedonuser: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            // verify required parameter 'poNumber' is not null or undefined
            if (poNumber === null || poNumber === undefined) {
                throw new RequiredError('poNumber', 'Required parameter poNumber was null or undefined when calling purchaseRequisitionPOHistoryPoNumberGet.');
            }
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling purchaseRequisitionPOHistoryPoNumberGet.');
            }
            const localVarPath = `/PurchaseRequisition/POHistory/{poNumber}`
                .replace(`{${"poNumber"}}`, encodeURIComponent(String(poNumber)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {PurchaseRequisition} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionPost(loggedonuser: string, body?: PurchaseRequisition, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling purchaseRequisitionPost.');
            }
            const localVarPath = `/PurchaseRequisition`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PurchaseRequisition" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} prGuid 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionPrGuidCostpointIntegrationSkipableGet(prGuid: string, loggedonuser: string, options: any = {}): FetchArgs {
            // verify required parameter 'prGuid' is not null or undefined
            if (prGuid === null || prGuid === undefined) {
                throw new RequiredError('prGuid','Required parameter prGuid was null or undefined when calling purchaseRequisitionPrGuidCostpointIntegrationSkipableGet.');
            }
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser','Required parameter loggedonuser was null or undefined when calling purchaseRequisitionPrGuidCostpointIntegrationSkipableGet.');
            }
            const localVarPath = `/PurchaseRequisition/{prGuid}/CostpointIntegrationSkipable`
                .replace(`{${"prGuid"}}`, encodeURIComponent(String(prGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} prGuid 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionPrGuidSkipIntegrationPost(prGuid: string, loggedonuser: string, options: any = {}): FetchArgs {
            // verify required parameter 'prGuid' is not null or undefined
            if (prGuid === null || prGuid === undefined) {
                throw new RequiredError('prGuid','Required parameter prGuid was null or undefined when calling purchaseRequisitionPrGuidSkipIntegrationPost.');
            }
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser','Required parameter loggedonuser was null or undefined when calling purchaseRequisitionPrGuidSkipIntegrationPost.');
            }
            const localVarPath = `/PurchaseRequisition/{prGuid}/SkipIntegration`
                .replace(`{${"prGuid"}}`, encodeURIComponent(String(prGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        /**
         * 
         * @param {string} loggedonuser 
         * @param {PurchaseRequisition} [body] 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionPut(loggedonuser: string, body?: PurchaseRequisition, key?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling purchaseRequisitionPut.');
            }
            const localVarPath = `/PurchaseRequisition`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PurchaseRequisition" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionRejectKeyPost(loggedonuser: string, key?: string, comment?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling purchaseRequisitionRejectKeyPost.');
            }
            const localVarPath = `/PurchaseRequisition/Reject({key})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (comment !== undefined) {
                localVarQueryParameter['comment'] = comment;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} parentGuid 
         * @param {string} loggedonuser 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionRequisitionHistoryParentGuidGet(parentGuid: string, loggedonuser: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            // verify required parameter 'parentGuid' is not null or undefined
            if (parentGuid === null || parentGuid === undefined) {
                throw new RequiredError('parentGuid', 'Required parameter parentGuid was null or undefined when calling purchaseRequisitionRequisitionHistoryParentGuidGet.');
            }
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling purchaseRequisitionRequisitionHistoryParentGuidGet.');
            }
            const localVarPath = `/PurchaseRequisition/RequisitionHistory/{parentGuid}`
                .replace(`{${"parentGuid"}}`, encodeURIComponent(String(parentGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionRequisitionPermissionKeyGet(loggedonuser: string, key?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling purchaseRequisitionRequisitionPermissionKeyGet.');
            }
            const localVarPath = `/PurchaseRequisition/RequisitionPermission({key})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {PurchaseRequisition} [body] 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionReviseKeyPost(loggedonuser: string, body?: PurchaseRequisition, key?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling purchaseRequisitionReviseKeyPost.');
            }
            const localVarPath = `/PurchaseRequisition/Revise({key})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PurchaseRequisition" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
      /**
         * 
         * @param {string} loggedonuser 
         * @param {Array<FileUpload>} [body] 
         * @param {string} [purchaseRequistionGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
      purchaseRequisitionAddAttachmentPost(loggedonuser: string, body?: Array<FileUpload>, purchaseRequistionGuid?: string, options: any = {}): FetchArgs {
        // verify required parameter 'loggedonuser' is not null or undefined
        if (loggedonuser === null || loggedonuser === undefined) {
            throw new RequiredError('loggedonuser','Required parameter loggedonuser was null or undefined when calling purchaseRequisitionAddAttachmentPost.');
        }
        const localVarPath = `/PurchaseRequisition/AddAttachment`;
        const localVarUrlObj = url.parse(localVarPath, true);
        const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        // authentication Bearer required
        if (configuration && configuration.apiKey) {
            const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                ? configuration.apiKey("Authorization")
                : configuration.apiKey;
            localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
        }

        if (purchaseRequistionGuid !== undefined) {
            localVarQueryParameter['PurchaseRequistionGuid'] = purchaseRequistionGuid;
        }

        if (loggedonuser !== undefined && loggedonuser !== null) {
            localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
        }

        localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

        localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
        // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
        delete localVarUrlObj.search;
        localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
        const needsSerialization = (<any>"Array&lt;FileUpload&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
        localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

        return {
            url: url.format(localVarUrlObj),
            options: localVarRequestOptions,
        };
    },
    }
};

/**
 * PurchaseRequisitionApi - functional programming interface
 * @export
 */
export const PurchaseRequisitionApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} prGuid 
         * @param {string} loggedonuser 
         * @param {PurchaseRequisitionAdditionalComment} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionAddCommentPrGuidPost(prGuid: string, loggedonuser: string, body?: PurchaseRequisitionAdditionalComment, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseRequisitionApiFetchParamCreator(configuration).purchaseRequisitionAddCommentPrGuidPost(prGuid, loggedonuser, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionApprovalFlowKeyGet(loggedonuser: string, key?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseRequisitionApiFetchParamCreator(configuration).purchaseRequisitionApprovalFlowKeyGet(loggedonuser, key, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionApprovalFlowLogKeyGet(loggedonuser: string, key?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseRequisitionApiFetchParamCreator(configuration).purchaseRequisitionApprovalFlowLogKeyGet(loggedonuser, key, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {string} [comment] 
         * @param {number} [currentLevel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionApproveKeyPost(loggedonuser: string, key?: string, comment?: string, currentLevel?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseRequisitionApiFetchParamCreator(configuration).purchaseRequisitionApproveKeyPost(loggedonuser, key, comment, currentLevel, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [flowGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionApproverDelete(loggedonuser: string, flowGuid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseRequisitionApiFetchParamCreator(configuration).purchaseRequisitionApproverDelete(loggedonuser, flowGuid, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [requisitionGuid] 
         * @param {string} [approverGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionApproverPost(loggedonuser: string, requisitionGuid?: string, approverGuid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseRequisitionApiFetchParamCreator(configuration).purchaseRequisitionApproverPost(loggedonuser, requisitionGuid, approverGuid, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [requisitionApprovelFlowGuid] 
         * @param {string} [approverGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionApproverPut(loggedonuser: string, requisitionApprovelFlowGuid?: string, approverGuid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseRequisitionApiFetchParamCreator(configuration).purchaseRequisitionApproverPut(loggedonuser, requisitionApprovelFlowGuid, approverGuid, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
                /**
         * 
         * @param {string} prGuid 
         * @param {string} loggedonuser 
         * @param {string} [attachmentGuid] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
                purchaseRequisitionDeletePurchaseRequisitionAttachmentPrGuidDelete(prGuid: string, loggedonuser: string, attachmentGuid?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
                    const localVarFetchArgs = PurchaseRequisitionApiFetchParamCreator(configuration).purchaseRequisitionDeletePurchaseRequisitionAttachmentPrGuidDelete(prGuid, loggedonuser, attachmentGuid, filter, top, skip, take, orderBy, options);
                    return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                            if (response.status >= 200 && response.status < 300) {
                                return response;
                            } else {
                                throw response;
                            }
                        });
                    };
                },
        /**
         * 
         * @param {string} poNumber 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionDetailPoNumberGet(poNumber: string, loggedonuser: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RequisitionModelSingleResult> {
            const localVarFetchArgs = PurchaseRequisitionApiFetchParamCreator(configuration).purchaseRequisitionDetailPoNumberGet(poNumber, loggedonuser, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [actionFilter] 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {string} [columnFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionExportExcelGet(loggedonuser: string, actionFilter?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, columnFilter?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseRequisitionApiFetchParamCreator(configuration).purchaseRequisitionExportExcelGet(loggedonuser, actionFilter, search, filter, top, skip, take, orderBy, columnFilter, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [actionFilter] 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionGet(loggedonuser: string, actionFilter?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseRequisitionApiFetchParamCreator(configuration).purchaseRequisitionGet(loggedonuser, actionFilter, search, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
         /**
         * 
         * @param {string} prGuid 
         * @param {string} loggedonuser 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
         purchaseRequisitionHasPurchaseRequisitionCommentPermissionPrGuidGet(prGuid: string, loggedonuser: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseRequisitionApiFetchParamCreator(configuration).purchaseRequisitionHasPurchaseRequisitionCommentPermissionPrGuidGet(prGuid, loggedonuser, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} prGuid 
         * @param {string} loggedonuser 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionHasPurchaseRequisitionAttachmentPermissionPrGuidGet(prGuid: string, loggedonuser: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseRequisitionApiFetchParamCreator(configuration).purchaseRequisitionHasPurchaseRequisitionAttachmentPermissionPrGuidGet(prGuid, loggedonuser, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} prGuid 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionIntegrateAuthPrGuidGet(prGuid: string, loggedonuser: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseRequisitionApiFetchParamCreator(configuration).purchaseRequisitionIntegrateAuthPrGuidGet(prGuid, loggedonuser, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionKeyDelete(loggedonuser: string, key?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseRequisitionApiFetchParamCreator(configuration).purchaseRequisitionKeyDelete(loggedonuser, key, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionKeyGet(loggedonuser: string, key?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PurchaseRequisitionSingleResult> {
            const localVarFetchArgs = PurchaseRequisitionApiFetchParamCreator(configuration).purchaseRequisitionKeyGet(loggedonuser, key, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} poNumber 
         * @param {string} loggedonuser 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionPOHistoryPoNumberGet(poNumber: string, loggedonuser: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseRequisitionApiFetchParamCreator(configuration).purchaseRequisitionPOHistoryPoNumberGet(poNumber, loggedonuser, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {PurchaseRequisition} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionPost(loggedonuser: string, body?: PurchaseRequisition, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseRequisitionApiFetchParamCreator(configuration).purchaseRequisitionPost(loggedonuser, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
          /**
         * 
         * @param {string} prGuid 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
          purchaseRequisitionPrGuidCostpointIntegrationSkipableGet(prGuid: string, loggedonuser: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseRequisitionApiFetchParamCreator(configuration).purchaseRequisitionPrGuidCostpointIntegrationSkipableGet(prGuid, loggedonuser, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} prGuid 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionPrGuidSkipIntegrationPost(prGuid: string, loggedonuser: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseRequisitionApiFetchParamCreator(configuration).purchaseRequisitionPrGuidSkipIntegrationPost(prGuid, loggedonuser, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {PurchaseRequisition} [body] 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionPut(loggedonuser: string, body?: PurchaseRequisition, key?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseRequisitionApiFetchParamCreator(configuration).purchaseRequisitionPut(loggedonuser, body, key, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionRejectKeyPost(loggedonuser: string, key?: string, comment?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseRequisitionApiFetchParamCreator(configuration).purchaseRequisitionRejectKeyPost(loggedonuser, key, comment, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} parentGuid 
         * @param {string} loggedonuser 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionRequisitionHistoryParentGuidGet(parentGuid: string, loggedonuser: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseRequisitionApiFetchParamCreator(configuration).purchaseRequisitionRequisitionHistoryParentGuidGet(parentGuid, loggedonuser, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionRequisitionPermissionKeyGet(loggedonuser: string, key?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RequisitionPermissionSingleResult> {
            const localVarFetchArgs = PurchaseRequisitionApiFetchParamCreator(configuration).purchaseRequisitionRequisitionPermissionKeyGet(loggedonuser, key, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {PurchaseRequisition} [body] 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionReviseKeyPost(loggedonuser: string, body?: PurchaseRequisition, key?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseRequisitionApiFetchParamCreator(configuration).purchaseRequisitionReviseKeyPost(loggedonuser, body, key, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Array<FileUpload>} [body] 
         * @param {string} [purchaseRequistionGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionAddAttachmentPost(loggedonuser: string, body?: Array<FileUpload>, purchaseRequistionGuid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurchaseRequisitionApiFetchParamCreator(configuration).purchaseRequisitionAddAttachmentPost(loggedonuser, body, purchaseRequistionGuid, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PurchaseRequisitionApi - factory interface
 * @export
 */
export const PurchaseRequisitionApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} prGuid 
         * @param {string} loggedonuser 
         * @param {PurchaseRequisitionAdditionalComment} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionAddCommentPrGuidPost(prGuid: string, loggedonuser: string, body?: PurchaseRequisitionAdditionalComment, options?: any) {
            return PurchaseRequisitionApiFp(configuration).purchaseRequisitionAddCommentPrGuidPost(prGuid, loggedonuser, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionApprovalFlowKeyGet(loggedonuser: string, key?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return PurchaseRequisitionApiFp(configuration).purchaseRequisitionApprovalFlowKeyGet(loggedonuser, key, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionApprovalFlowLogKeyGet(loggedonuser: string, key?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return PurchaseRequisitionApiFp(configuration).purchaseRequisitionApprovalFlowLogKeyGet(loggedonuser, key, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {string} [comment] 
         * @param {number} [currentLevel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionApproveKeyPost(loggedonuser: string, key?: string, comment?: string, currentLevel?: number, options?: any) {
            return PurchaseRequisitionApiFp(configuration).purchaseRequisitionApproveKeyPost(loggedonuser, key, comment, currentLevel, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [flowGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionApproverDelete(loggedonuser: string, flowGuid?: string, options?: any) {
            return PurchaseRequisitionApiFp(configuration).purchaseRequisitionApproverDelete(loggedonuser, flowGuid, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [requisitionGuid] 
         * @param {string} [approverGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionApproverPost(loggedonuser: string, requisitionGuid?: string, approverGuid?: string, options?: any) {
            return PurchaseRequisitionApiFp(configuration).purchaseRequisitionApproverPost(loggedonuser, requisitionGuid, approverGuid, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [requisitionApprovelFlowGuid] 
         * @param {string} [approverGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionApproverPut(loggedonuser: string, requisitionApprovelFlowGuid?: string, approverGuid?: string, options?: any) {
            return PurchaseRequisitionApiFp(configuration).purchaseRequisitionApproverPut(loggedonuser, requisitionApprovelFlowGuid, approverGuid, options)(fetch, basePath);
        },
                /**
         * 
         * @param {string} prGuid 
         * @param {string} loggedonuser 
         * @param {string} [attachmentGuid] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
                purchaseRequisitionDeletePurchaseRequisitionAttachmentPrGuidDelete(prGuid: string, loggedonuser: string, attachmentGuid?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
                    return PurchaseRequisitionApiFp(configuration).purchaseRequisitionDeletePurchaseRequisitionAttachmentPrGuidDelete(prGuid, loggedonuser, attachmentGuid, filter, top, skip, take, orderBy, options)(fetch, basePath);
                },
        /**
         * 
         * @param {string} poNumber 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionDetailPoNumberGet(poNumber: string, loggedonuser: string, options?: any) {
            return PurchaseRequisitionApiFp(configuration).purchaseRequisitionDetailPoNumberGet(poNumber, loggedonuser, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [actionFilter] 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {string} [columnFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionExportExcelGet(loggedonuser: string, actionFilter?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, columnFilter?: string, options?: any) {
            return PurchaseRequisitionApiFp(configuration).purchaseRequisitionExportExcelGet(loggedonuser, actionFilter, search, filter, top, skip, take, orderBy, columnFilter, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [actionFilter] 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionGet(loggedonuser: string, actionFilter?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return PurchaseRequisitionApiFp(configuration).purchaseRequisitionGet(loggedonuser, actionFilter, search, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
              /**
         * 
         * @param {string} prGuid 
         * @param {string} loggedonuser 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionHasPurchaseRequisitionAttachmentPermissionPrGuidGet(prGuid: string, loggedonuser: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return PurchaseRequisitionApiFp(configuration).purchaseRequisitionHasPurchaseRequisitionAttachmentPermissionPrGuidGet(prGuid, loggedonuser, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} prGuid 
         * @param {string} loggedonuser 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
           purchaseRequisitionHasPurchaseRequisitionCommentPermissionPrGuidGet(prGuid: string, loggedonuser: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return PurchaseRequisitionApiFp(configuration).purchaseRequisitionHasPurchaseRequisitionCommentPermissionPrGuidGet(prGuid, loggedonuser, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} prGuid 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionIntegrateAuthPrGuidGet(prGuid: string, loggedonuser: string, options?: any) {
            return PurchaseRequisitionApiFp(configuration).purchaseRequisitionIntegrateAuthPrGuidGet(prGuid, loggedonuser, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionKeyDelete(loggedonuser: string, key?: string, options?: any) {
            return PurchaseRequisitionApiFp(configuration).purchaseRequisitionKeyDelete(loggedonuser, key, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionKeyGet(loggedonuser: string, key?: string, options?: any) {
            return PurchaseRequisitionApiFp(configuration).purchaseRequisitionKeyGet(loggedonuser, key, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} poNumber 
         * @param {string} loggedonuser 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionPOHistoryPoNumberGet(poNumber: string, loggedonuser: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return PurchaseRequisitionApiFp(configuration).purchaseRequisitionPOHistoryPoNumberGet(poNumber, loggedonuser, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {PurchaseRequisition} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionPost(loggedonuser: string, body?: PurchaseRequisition, options?: any) {
            return PurchaseRequisitionApiFp(configuration).purchaseRequisitionPost(loggedonuser, body, options)(fetch, basePath);
        },
          /**
         * 
         * @param {string} prGuid 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
          purchaseRequisitionPrGuidCostpointIntegrationSkipableGet(prGuid: string, loggedonuser: string, options?: any) {
            return PurchaseRequisitionApiFp(configuration).purchaseRequisitionPrGuidCostpointIntegrationSkipableGet(prGuid, loggedonuser, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} prGuid 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionPrGuidSkipIntegrationPost(prGuid: string, loggedonuser: string, options?: any) {
            return PurchaseRequisitionApiFp(configuration).purchaseRequisitionPrGuidSkipIntegrationPost(prGuid, loggedonuser, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {PurchaseRequisition} [body] 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionPut(loggedonuser: string, body?: PurchaseRequisition, key?: string, options?: any) {
            return PurchaseRequisitionApiFp(configuration).purchaseRequisitionPut(loggedonuser, body, key, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionRejectKeyPost(loggedonuser: string, key?: string, comment?: string, options?: any) {
            return PurchaseRequisitionApiFp(configuration).purchaseRequisitionRejectKeyPost(loggedonuser, key, comment, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} parentGuid 
         * @param {string} loggedonuser 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionRequisitionHistoryParentGuidGet(parentGuid: string, loggedonuser: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return PurchaseRequisitionApiFp(configuration).purchaseRequisitionRequisitionHistoryParentGuidGet(parentGuid, loggedonuser, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionRequisitionPermissionKeyGet(loggedonuser: string, key?: string, options?: any) {
            return PurchaseRequisitionApiFp(configuration).purchaseRequisitionRequisitionPermissionKeyGet(loggedonuser, key, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {PurchaseRequisition} [body] 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseRequisitionReviseKeyPost(loggedonuser: string, body?: PurchaseRequisition, key?: string, options?: any) {
            return PurchaseRequisitionApiFp(configuration).purchaseRequisitionReviseKeyPost(loggedonuser, body, key, options)(fetch, basePath);
        },
         /**
         * 
         * @param {string} loggedonuser 
         * @param {Array<FileUpload>} [body] 
         * @param {string} [purchaseRequistionGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
         purchaseRequisitionAddAttachmentPost(loggedonuser: string, body?: Array<FileUpload>, purchaseRequistionGuid?: string, options?: any) {
            return PurchaseRequisitionApiFp(configuration).purchaseRequisitionAddAttachmentPost(loggedonuser, body, purchaseRequistionGuid, options)(fetch, basePath);
        },
    };
};

/**
 * PurchaseRequisitionApi - object-oriented interface
 * @export
 * @class PurchaseRequisitionApi
 * @extends {BaseAPI}
 */
export class PurchaseRequisitionApi extends BaseAPI {
     /**
     * 
     * @param {string} prGuid 
     * @param {string} loggedonuser 
     * @param {PurchaseRequisitionAdditionalComment} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequisitionApi
     */
     public purchaseRequisitionAddCommentPrGuidPost(prGuid: string, loggedonuser: string, body?: PurchaseRequisitionAdditionalComment, options?: any) {
        return PurchaseRequisitionApiFp(this.configuration).purchaseRequisitionAddCommentPrGuidPost(prGuid, loggedonuser, body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [key] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequisitionApi
     */
    public purchaseRequisitionApprovalFlowKeyGet(loggedonuser: string, key?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return PurchaseRequisitionApiFp(this.configuration).purchaseRequisitionApprovalFlowKeyGet(loggedonuser, key, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [key] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequisitionApi
     */
    public purchaseRequisitionApprovalFlowLogKeyGet(loggedonuser: string, key?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return PurchaseRequisitionApiFp(this.configuration).purchaseRequisitionApprovalFlowLogKeyGet(loggedonuser, key, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [key] 
     * @param {string} [comment] 
     * @param {number} [currentLevel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequisitionApi
     */
    public purchaseRequisitionApproveKeyPost(loggedonuser: string, key?: string, comment?: string, currentLevel?: number, options?: any) {
        return PurchaseRequisitionApiFp(this.configuration).purchaseRequisitionApproveKeyPost(loggedonuser, key, comment, currentLevel, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [flowGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequisitionApi
     */
    public purchaseRequisitionApproverDelete(loggedonuser: string, flowGuid?: string, options?: any) {
        return PurchaseRequisitionApiFp(this.configuration).purchaseRequisitionApproverDelete(loggedonuser, flowGuid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [requisitionGuid] 
     * @param {string} [approverGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequisitionApi
     */
    public purchaseRequisitionApproverPost(loggedonuser: string, requisitionGuid?: string, approverGuid?: string, options?: any) {
        return PurchaseRequisitionApiFp(this.configuration).purchaseRequisitionApproverPost(loggedonuser, requisitionGuid, approverGuid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [requisitionApprovelFlowGuid] 
     * @param {string} [approverGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequisitionApi
     */
    public purchaseRequisitionApproverPut(loggedonuser: string, requisitionApprovelFlowGuid?: string, approverGuid?: string, options?: any) {
        return PurchaseRequisitionApiFp(this.configuration).purchaseRequisitionApproverPut(loggedonuser, requisitionApprovelFlowGuid, approverGuid, options)(this.fetch, this.basePath);
    }

        /**
     * 
     * @param {string} prGuid 
     * @param {string} loggedonuser 
     * @param {string} [attachmentGuid] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequisitionApi
     */
        public purchaseRequisitionDeletePurchaseRequisitionAttachmentPrGuidDelete(prGuid: string, loggedonuser: string, attachmentGuid?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return PurchaseRequisitionApiFp(this.configuration).purchaseRequisitionDeletePurchaseRequisitionAttachmentPrGuidDelete(prGuid, loggedonuser, attachmentGuid, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
        }

    /**
     * 
     * @param {string} poNumber 
     * @param {string} loggedonuser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequisitionApi
     */
    public purchaseRequisitionDetailPoNumberGet(poNumber: string, loggedonuser: string, options?: any) {
        return PurchaseRequisitionApiFp(this.configuration).purchaseRequisitionDetailPoNumberGet(poNumber, loggedonuser, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [actionFilter] 
     * @param {string} [search] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {string} [columnFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequisitionApi
     */
    public purchaseRequisitionExportExcelGet(loggedonuser: string, actionFilter?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, columnFilter?: string, options?: any) {
        return PurchaseRequisitionApiFp(this.configuration).purchaseRequisitionExportExcelGet(loggedonuser, actionFilter, search, filter, top, skip, take, orderBy, columnFilter, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [actionFilter] 
     * @param {string} [search] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequisitionApi
     */
    public purchaseRequisitionGet(loggedonuser: string, actionFilter?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return PurchaseRequisitionApiFp(this.configuration).purchaseRequisitionGet(loggedonuser, actionFilter, search, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

         /**
     * 
     * @param {string} prGuid 
     * @param {string} loggedonuser 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequisitionApi
     */
    public purchaseRequisitionHasPurchaseRequisitionAttachmentPermissionPrGuidGet(prGuid: string, loggedonuser: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return PurchaseRequisitionApiFp(this.configuration).purchaseRequisitionHasPurchaseRequisitionAttachmentPermissionPrGuidGet(prGuid, loggedonuser, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} prGuid 
     * @param {string} loggedonuser 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequisitionApi
     */
        public purchaseRequisitionHasPurchaseRequisitionCommentPermissionPrGuidGet(prGuid: string, loggedonuser: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return PurchaseRequisitionApiFp(this.configuration).purchaseRequisitionHasPurchaseRequisitionCommentPermissionPrGuidGet(prGuid, loggedonuser, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
        }

    /**
     * 
     * @param {string} prGuid 
     * @param {string} loggedonuser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequisitionApi
     */
    public purchaseRequisitionIntegrateAuthPrGuidGet(prGuid: string, loggedonuser: string, options?: any) {
        return PurchaseRequisitionApiFp(this.configuration).purchaseRequisitionIntegrateAuthPrGuidGet(prGuid, loggedonuser, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequisitionApi
     */
    public purchaseRequisitionKeyDelete(loggedonuser: string, key?: string, options?: any) {
        return PurchaseRequisitionApiFp(this.configuration).purchaseRequisitionKeyDelete(loggedonuser, key, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequisitionApi
     */
    public purchaseRequisitionKeyGet(loggedonuser: string, key?: string, options?: any) {
        return PurchaseRequisitionApiFp(this.configuration).purchaseRequisitionKeyGet(loggedonuser, key, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} poNumber 
     * @param {string} loggedonuser 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequisitionApi
     */
    public purchaseRequisitionPOHistoryPoNumberGet(poNumber: string, loggedonuser: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return PurchaseRequisitionApiFp(this.configuration).purchaseRequisitionPOHistoryPoNumberGet(poNumber, loggedonuser, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {PurchaseRequisition} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequisitionApi
     */
    public purchaseRequisitionPost(loggedonuser: string, body?: PurchaseRequisition, options?: any) {
        return PurchaseRequisitionApiFp(this.configuration).purchaseRequisitionPost(loggedonuser, body, options)(this.fetch, this.basePath);
    }
     /**
     * 
     * @param {string} prGuid 
     * @param {string} loggedonuser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequisitionApi
     */
     public purchaseRequisitionPrGuidCostpointIntegrationSkipableGet(prGuid: string, loggedonuser: string, options?: any) {
        return PurchaseRequisitionApiFp(this.configuration).purchaseRequisitionPrGuidCostpointIntegrationSkipableGet(prGuid, loggedonuser, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} prGuid 
     * @param {string} loggedonuser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequisitionApi
     */
    public purchaseRequisitionPrGuidSkipIntegrationPost(prGuid: string, loggedonuser: string, options?: any) {
        return PurchaseRequisitionApiFp(this.configuration).purchaseRequisitionPrGuidSkipIntegrationPost(prGuid, loggedonuser, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {PurchaseRequisition} [body] 
     * @param {string} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequisitionApi
     */
    public purchaseRequisitionPut(loggedonuser: string, body?: PurchaseRequisition, key?: string, options?: any) {
        return PurchaseRequisitionApiFp(this.configuration).purchaseRequisitionPut(loggedonuser, body, key, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [key] 
     * @param {string} [comment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequisitionApi
     */
    public purchaseRequisitionRejectKeyPost(loggedonuser: string, key?: string, comment?: string, options?: any) {
        return PurchaseRequisitionApiFp(this.configuration).purchaseRequisitionRejectKeyPost(loggedonuser, key, comment, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} parentGuid 
     * @param {string} loggedonuser 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequisitionApi
     */
    public purchaseRequisitionRequisitionHistoryParentGuidGet(parentGuid: string, loggedonuser: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return PurchaseRequisitionApiFp(this.configuration).purchaseRequisitionRequisitionHistoryParentGuidGet(parentGuid, loggedonuser, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequisitionApi
     */
    public purchaseRequisitionRequisitionPermissionKeyGet(loggedonuser: string, key?: string, options?: any) {
        return PurchaseRequisitionApiFp(this.configuration).purchaseRequisitionRequisitionPermissionKeyGet(loggedonuser, key, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {PurchaseRequisition} [body] 
     * @param {string} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequisitionApi
     */
    public purchaseRequisitionReviseKeyPost(loggedonuser: string, body?: PurchaseRequisition, key?: string, options?: any) {
        return PurchaseRequisitionApiFp(this.configuration).purchaseRequisitionReviseKeyPost(loggedonuser, body, key, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {Array<FileUpload>} [body] 
     * @param {string} [purchaseRequistionGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRequisitionApi
     */
    public purchaseRequisitionAddAttachmentPost(loggedonuser: string, body?: Array<FileUpload>, purchaseRequistionGuid?: string, options?: any) {
        return PurchaseRequisitionApiFp(this.configuration).purchaseRequisitionAddAttachmentPost(loggedonuser, body, purchaseRequistionGuid, options)(this.fetch, this.basePath);
    }

}
/**
 * ResourceApi - fetch parameter creator
 * @export
 */
export const ResourceApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceGet(loggedonuser: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling resourceGet.');
            }
            const localVarPath = `/Resource`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ResourceApi - functional programming interface
 * @export
 */
export const ResourceApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceGet(loggedonuser: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ResourceApiFetchParamCreator(configuration).resourceGet(loggedonuser, search, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ResourceApi - factory interface
 * @export
 */
export const ResourceApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceGet(loggedonuser: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return ResourceApiFp(configuration).resourceGet(loggedonuser, search, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
    };
};

/**
 * ResourceApi - object-oriented interface
 * @export
 * @class ResourceApi
 * @extends {BaseAPI}
 */
export class ResourceApi extends BaseAPI {
    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [search] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceApi
     */
    public resourceGet(loggedonuser: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return ResourceApiFp(this.configuration).resourceGet(loggedonuser, search, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

}
/**
 * ResourceAttributeApi - fetch parameter creator
 * @export
 */
export const ResourceAttributeApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [entity] 
         * @param {string} [resourceType] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeControllerGet(loggedonuser: string, entity?: string, resourceType?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling resourceAttributeControllerGet.');
            }
            const localVarPath = `/ResourceAttributeController`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (entity !== undefined) {
                localVarQueryParameter['entity'] = entity;
            }

            if (resourceType !== undefined) {
                localVarQueryParameter['resourceType'] = resourceType;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ResourceAttributeApi - functional programming interface
 * @export
 */
export const ResourceAttributeApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [entity] 
         * @param {string} [resourceType] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeControllerGet(loggedonuser: string, entity?: string, resourceType?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ResourceAttributeApiFetchParamCreator(configuration).resourceAttributeControllerGet(loggedonuser, entity, resourceType, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ResourceAttributeApi - factory interface
 * @export
 */
export const ResourceAttributeApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [entity] 
         * @param {string} [resourceType] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAttributeControllerGet(loggedonuser: string, entity?: string, resourceType?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return ResourceAttributeApiFp(configuration).resourceAttributeControllerGet(loggedonuser, entity, resourceType, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
    };
};

/**
 * ResourceAttributeApi - object-oriented interface
 * @export
 * @class ResourceAttributeApi
 * @extends {BaseAPI}
 */
export class ResourceAttributeApi extends BaseAPI {
    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [entity] 
     * @param {string} [resourceType] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceAttributeApi
     */
    public resourceAttributeControllerGet(loggedonuser: string, entity?: string, resourceType?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return ResourceAttributeApiFp(this.configuration).resourceAttributeControllerGet(loggedonuser, entity, resourceType, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

}
/**
 * UploadApi - fetch parameter creator
 * @export
 */
export const UploadApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} filename 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFilenameGet(filename: string, loggedonuser: string, options: any = {}): FetchArgs {
            // verify required parameter 'filename' is not null or undefined
            if (filename === null || filename === undefined) {
                throw new RequiredError('filename', 'Required parameter filename was null or undefined when calling uploadFilenameGet.');
            }
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling uploadFilenameGet.');
            }
            const localVarPath = `/Upload({filename})`
                .replace(`{${"filename"}}`, encodeURIComponent(String(filename)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} path 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPathFolderPost(path: string, loggedonuser: string, options: any = {}): FetchArgs {
            // verify required parameter 'path' is not null or undefined
            if (path === null || path === undefined) {
                throw new RequiredError('path', 'Required parameter path was null or undefined when calling uploadPathFolderPost.');
            }
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling uploadPathFolderPost.');
            }
            const localVarPath = `/Upload/{path}/folder`
                .replace(`{${"path"}}`, encodeURIComponent(String(path)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} path 
         * @param {string} loggedonuser 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPathGet(path: string, loggedonuser: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            // verify required parameter 'path' is not null or undefined
            if (path === null || path === undefined) {
                throw new RequiredError('path', 'Required parameter path was null or undefined when calling uploadPathGet.');
            }
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling uploadPathGet.');
            }
            const localVarPath = `/Upload/{path}`
                .replace(`{${"path"}}`, encodeURIComponent(String(path)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} path 
         * @param {string} loggedonuser 
         * @param {Test} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPathPost(path: string, loggedonuser: string, body?: Test, options: any = {}): FetchArgs {
            // verify required parameter 'path' is not null or undefined
            if (path === null || path === undefined) {
                throw new RequiredError('path', 'Required parameter path was null or undefined when calling uploadPathPost.');
            }
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling uploadPathPost.');
            }
            const localVarPath = `/Upload/{path}`
                .replace(`{${"path"}}`, encodeURIComponent(String(path)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Test" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} path 
         * @param {string} loggedonuser 
         * @param {RenameFolder} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPathPut(path: string, loggedonuser: string, body?: RenameFolder, options: any = {}): FetchArgs {
            // verify required parameter 'path' is not null or undefined
            if (path === null || path === undefined) {
                throw new RequiredError('path', 'Required parameter path was null or undefined when calling uploadPathPut.');
            }
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling uploadPathPut.');
            }
            const localVarPath = `/Upload/{path}`
                .replace(`{${"path"}}`, encodeURIComponent(String(path)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RenameFolder" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UploadApi - functional programming interface
 * @export
 */
export const UploadApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} filename 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFilenameGet(filename: string, loggedonuser: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UploadApiFetchParamCreator(configuration).uploadFilenameGet(filename, loggedonuser, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} path 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPathFolderPost(path: string, loggedonuser: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UploadApiFetchParamCreator(configuration).uploadPathFolderPost(path, loggedonuser, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} path 
         * @param {string} loggedonuser 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPathGet(path: string, loggedonuser: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UploadApiFetchParamCreator(configuration).uploadPathGet(path, loggedonuser, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} path 
         * @param {string} loggedonuser 
         * @param {Test} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPathPost(path: string, loggedonuser: string, body?: Test, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UploadApiFetchParamCreator(configuration).uploadPathPost(path, loggedonuser, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} path 
         * @param {string} loggedonuser 
         * @param {RenameFolder} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPathPut(path: string, loggedonuser: string, body?: RenameFolder, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UploadApiFetchParamCreator(configuration).uploadPathPut(path, loggedonuser, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UploadApi - factory interface
 * @export
 */
export const UploadApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} filename 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFilenameGet(filename: string, loggedonuser: string, options?: any) {
            return UploadApiFp(configuration).uploadFilenameGet(filename, loggedonuser, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} path 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPathFolderPost(path: string, loggedonuser: string, options?: any) {
            return UploadApiFp(configuration).uploadPathFolderPost(path, loggedonuser, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} path 
         * @param {string} loggedonuser 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPathGet(path: string, loggedonuser: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return UploadApiFp(configuration).uploadPathGet(path, loggedonuser, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} path 
         * @param {string} loggedonuser 
         * @param {Test} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPathPost(path: string, loggedonuser: string, body?: Test, options?: any) {
            return UploadApiFp(configuration).uploadPathPost(path, loggedonuser, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} path 
         * @param {string} loggedonuser 
         * @param {RenameFolder} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPathPut(path: string, loggedonuser: string, body?: RenameFolder, options?: any) {
            return UploadApiFp(configuration).uploadPathPut(path, loggedonuser, body, options)(fetch, basePath);
        },
    };
};

/**
 * UploadApi - object-oriented interface
 * @export
 * @class UploadApi
 * @extends {BaseAPI}
 */
export class UploadApi extends BaseAPI {
    /**
     * 
     * @param {string} filename 
     * @param {string} loggedonuser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public uploadFilenameGet(filename: string, loggedonuser: string, options?: any) {
        return UploadApiFp(this.configuration).uploadFilenameGet(filename, loggedonuser, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} path 
     * @param {string} loggedonuser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public uploadPathFolderPost(path: string, loggedonuser: string, options?: any) {
        return UploadApiFp(this.configuration).uploadPathFolderPost(path, loggedonuser, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} path 
     * @param {string} loggedonuser 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public uploadPathGet(path: string, loggedonuser: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return UploadApiFp(this.configuration).uploadPathGet(path, loggedonuser, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} path 
     * @param {string} loggedonuser 
     * @param {Test} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public uploadPathPost(path: string, loggedonuser: string, body?: Test, options?: any) {
        return UploadApiFp(this.configuration).uploadPathPost(path, loggedonuser, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} path 
     * @param {string} loggedonuser 
     * @param {RenameFolder} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public uploadPathPut(path: string, loggedonuser: string, body?: RenameFolder, options?: any) {
        return UploadApiFp(this.configuration).uploadPathPut(path, loggedonuser, body, options)(this.fetch, this.basePath);
    }

}
/**
 * UserApi - fetch parameter creator
 * @export
 */
export const UserApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAccountingUsersGet(loggedonuser: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling userAccountingUsersGet.');
            }
            const localVarPath = `/User/AccountingUsers`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGet(loggedonuser: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling userGet.');
            }
            const localVarPath = `/User`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} username 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userIsSuperUserUsernameGet(username: string, loggedonuser: string, options: any = {}): FetchArgs {
            // verify required parameter 'username' is not null or undefined
            if (username === null || username === undefined) {
                throw new RequiredError('username', 'Required parameter username was null or undefined when calling userIsSuperUserUsernameGet.');
            }
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling userIsSuperUserUsernameGet.');
            }
            const localVarPath = `/User/IsSuperUser/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userKeyGet(loggedonuser: string, key?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling userKeyGet.');
            }
            const localVarPath = `/User({key})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {User} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPost(loggedonuser: string, body?: User, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling userPost.');
            }
            const localVarPath = `/User`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"User" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAccountingUsersGet(loggedonuser: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).userAccountingUsersGet(loggedonuser, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGet(loggedonuser: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).userGet(loggedonuser, search, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} username 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userIsSuperUserUsernameGet(username: string, loggedonuser: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).userIsSuperUserUsernameGet(username, loggedonuser, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userKeyGet(loggedonuser: string, key?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserSingleResult> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).userKeyGet(loggedonuser, key, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {User} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPost(loggedonuser: string, body?: User, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).userPost(loggedonuser, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAccountingUsersGet(loggedonuser: string, options?: any) {
            return UserApiFp(configuration).userAccountingUsersGet(loggedonuser, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGet(loggedonuser: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return UserApiFp(configuration).userGet(loggedonuser, search, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} username 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userIsSuperUserUsernameGet(username: string, loggedonuser: string, options?: any) {
            return UserApiFp(configuration).userIsSuperUserUsernameGet(username, loggedonuser, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userKeyGet(loggedonuser: string, key?: string, options?: any) {
            return UserApiFp(configuration).userKeyGet(loggedonuser, key, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {User} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPost(loggedonuser: string, body?: User, options?: any) {
            return UserApiFp(configuration).userPost(loggedonuser, body, options)(fetch, basePath);
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {string} loggedonuser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userAccountingUsersGet(loggedonuser: string, options?: any) {
        return UserApiFp(this.configuration).userAccountingUsersGet(loggedonuser, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [search] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGet(loggedonuser: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return UserApiFp(this.configuration).userGet(loggedonuser, search, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} username 
     * @param {string} loggedonuser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userIsSuperUserUsernameGet(username: string, loggedonuser: string, options?: any) {
        return UserApiFp(this.configuration).userIsSuperUserUsernameGet(username, loggedonuser, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userKeyGet(loggedonuser: string, key?: string, options?: any) {
        return UserApiFp(this.configuration).userKeyGet(loggedonuser, key, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {User} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userPost(loggedonuser: string, body?: User, options?: any) {
        return UserApiFp(this.configuration).userPost(loggedonuser, body, options)(this.fetch, this.basePath);
    }

}
/**
 * UserGroupApi - fetch parameter creator
 * @export
 */
export const UserGroupApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Array<GroupUser>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupDelete(loggedonuser: string, body?: Array<GroupUser>, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling userGroupDelete.');
            }
            const localVarPath = `/UserGroup`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;GroupUser&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} groupGuid 
         * @param {string} loggedonuser 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupGroupGroupGuidGet(groupGuid: string, loggedonuser: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            // verify required parameter 'groupGuid' is not null or undefined
            if (groupGuid === null || groupGuid === undefined) {
                throw new RequiredError('groupGuid', 'Required parameter groupGuid was null or undefined when calling userGroupGroupGroupGuidGet.');
            }
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling userGroupGroupGroupGuidGet.');
            }
            const localVarPath = `/UserGroup/Group({groupGuid})`
                .replace(`{${"groupGuid"}}`, encodeURIComponent(String(groupGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupKeyDelete(loggedonuser: string, key?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling userGroupKeyDelete.');
            }
            const localVarPath = `/UserGroup({key})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Array<GroupPost>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupPost(loggedonuser: string, body?: Array<GroupPost>, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling userGroupPost.');
            }
            const localVarPath = `/UserGroup`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;GroupPost&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {GroupPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupUserPost(loggedonuser: string, body?: GroupPost, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling userGroupUserPost.');
            }
            const localVarPath = `/UserGroup/User`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GroupPost" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userGuid 
         * @param {string} loggedonuser 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupUserUserGuidGet(userGuid: string, loggedonuser: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            // verify required parameter 'userGuid' is not null or undefined
            if (userGuid === null || userGuid === undefined) {
                throw new RequiredError('userGuid', 'Required parameter userGuid was null or undefined when calling userGroupUserUserGuidGet.');
            }
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling userGroupUserUserGuidGet.');
            }
            const localVarPath = `/UserGroup/User({userGuid})`
                .replace(`{${"userGuid"}}`, encodeURIComponent(String(userGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserGroupApi - functional programming interface
 * @export
 */
export const UserGroupApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Array<GroupUser>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupDelete(loggedonuser: string, body?: Array<GroupUser>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserGroupApiFetchParamCreator(configuration).userGroupDelete(loggedonuser, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} groupGuid 
         * @param {string} loggedonuser 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupGroupGroupGuidGet(groupGuid: string, loggedonuser: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserGroupApiFetchParamCreator(configuration).userGroupGroupGroupGuidGet(groupGuid, loggedonuser, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupKeyDelete(loggedonuser: string, key?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserGroupApiFetchParamCreator(configuration).userGroupKeyDelete(loggedonuser, key, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Array<GroupPost>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupPost(loggedonuser: string, body?: Array<GroupPost>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserGroupApiFetchParamCreator(configuration).userGroupPost(loggedonuser, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {GroupPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupUserPost(loggedonuser: string, body?: GroupPost, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserGroupApiFetchParamCreator(configuration).userGroupUserPost(loggedonuser, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} userGuid 
         * @param {string} loggedonuser 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupUserUserGuidGet(userGuid: string, loggedonuser: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserGroupApiFetchParamCreator(configuration).userGroupUserUserGuidGet(userGuid, loggedonuser, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UserGroupApi - factory interface
 * @export
 */
export const UserGroupApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Array<GroupUser>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupDelete(loggedonuser: string, body?: Array<GroupUser>, options?: any) {
            return UserGroupApiFp(configuration).userGroupDelete(loggedonuser, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} groupGuid 
         * @param {string} loggedonuser 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupGroupGroupGuidGet(groupGuid: string, loggedonuser: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return UserGroupApiFp(configuration).userGroupGroupGroupGuidGet(groupGuid, loggedonuser, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupKeyDelete(loggedonuser: string, key?: string, options?: any) {
            return UserGroupApiFp(configuration).userGroupKeyDelete(loggedonuser, key, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Array<GroupPost>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupPost(loggedonuser: string, body?: Array<GroupPost>, options?: any) {
            return UserGroupApiFp(configuration).userGroupPost(loggedonuser, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {GroupPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupUserPost(loggedonuser: string, body?: GroupPost, options?: any) {
            return UserGroupApiFp(configuration).userGroupUserPost(loggedonuser, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} userGuid 
         * @param {string} loggedonuser 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupUserUserGuidGet(userGuid: string, loggedonuser: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return UserGroupApiFp(configuration).userGroupUserUserGuidGet(userGuid, loggedonuser, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
    };
};

/**
 * UserGroupApi - object-oriented interface
 * @export
 * @class UserGroupApi
 * @extends {BaseAPI}
 */
export class UserGroupApi extends BaseAPI {
    /**
     * 
     * @param {string} loggedonuser 
     * @param {Array<GroupUser>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public userGroupDelete(loggedonuser: string, body?: Array<GroupUser>, options?: any) {
        return UserGroupApiFp(this.configuration).userGroupDelete(loggedonuser, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} groupGuid 
     * @param {string} loggedonuser 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public userGroupGroupGroupGuidGet(groupGuid: string, loggedonuser: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return UserGroupApiFp(this.configuration).userGroupGroupGroupGuidGet(groupGuid, loggedonuser, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public userGroupKeyDelete(loggedonuser: string, key?: string, options?: any) {
        return UserGroupApiFp(this.configuration).userGroupKeyDelete(loggedonuser, key, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {Array<GroupPost>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public userGroupPost(loggedonuser: string, body?: Array<GroupPost>, options?: any) {
        return UserGroupApiFp(this.configuration).userGroupPost(loggedonuser, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {GroupPost} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public userGroupUserPost(loggedonuser: string, body?: GroupPost, options?: any) {
        return UserGroupApiFp(this.configuration).userGroupUserPost(loggedonuser, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} userGuid 
     * @param {string} loggedonuser 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public userGroupUserUserGuidGet(userGuid: string, loggedonuser: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return UserGroupApiFp(this.configuration).userGroupUserUserGuidGet(userGuid, loggedonuser, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

}
/**
 * VendorApi - fetch parameter creator
 * @export
 */
export const VendorApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorControllerGet(loggedonuser: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling vendorControllerGet.');
            }
            const localVarPath = `/VendorController`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Vendor} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorControllerPost(loggedonuser: string, body?: Vendor, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling vendorControllerPost.');
            }
            const localVarPath = `/VendorController`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Vendor" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Vendor} [body] 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorControllerPut(loggedonuser: string, body?: Vendor, key?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling vendorControllerPut.');
            }
            const localVarPath = `/VendorController`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Vendor" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
         /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [vendorID] 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
         vendorDetailGet(loggedonuser: string, vendorID?: string, companyCode?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser','Required parameter loggedonuser was null or undefined when calling vendorDetailGet.');
            }
            const localVarPath = `/Vendor/Detail`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (vendorID !== undefined) {
                localVarQueryParameter['vendorID'] = vendorID;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorKeyGet(loggedonuser: string, key?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling vendorKeyGet.');
            }
            const localVarPath = `/Vendor({key})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} type 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorTypeTypeGet(type: string, loggedonuser: string, options: any = {}): FetchArgs {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type', 'Required parameter type was null or undefined when calling vendorTypeTypeGet.');
            }
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling vendorTypeTypeGet.');
            }
            const localVarPath = `/Vendor/Type/{Type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Array<VendorAddress>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorVendorAddressPost(loggedonuser: string, body?: Array<VendorAddress>, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling vendorVendorAddressPost.');
            }
            const localVarPath = `/Vendor/VendorAddress`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;VendorAddress&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Array<AddressContact>} [body] 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorVendorContactPut(loggedonuser: string, body?: Array<AddressContact>, key?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling vendorVendorContactPut.');
            }
            const localVarPath = `/Vendor/VendorContact`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;AddressContact&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vendorID 
         * @param {string} loggedonuser 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorVendorIDGet(vendorID: string, loggedonuser: string, companyCode?: string, options: any = {}): FetchArgs {
            // verify required parameter 'vendorID' is not null or undefined
            if (vendorID === null || vendorID === undefined) {
                throw new RequiredError('vendorID', 'Required parameter vendorID was null or undefined when calling vendorVendorIDGet.');
            }
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling vendorVendorIDGet.');
            }
            const localVarPath = `/Vendor/{vendorID}`
                .replace(`{${"vendorID"}}`, encodeURIComponent(String(vendorID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [companyCode] 
         * @param {string} [materialOrService] 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorVendorListGet(loggedonuser: string, companyCode?: string, materialOrService?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling vendorVendorListGet.');
            }
            const localVarPath = `/Vendor/VendorList`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyCode !== undefined) {
                localVarQueryParameter['companyCode'] = companyCode;
            }

            if (materialOrService !== undefined) {
                localVarQueryParameter['materialOrService'] = materialOrService;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VendorApi - functional programming interface
 * @export
 */
export const VendorApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorControllerGet(loggedonuser: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = VendorApiFetchParamCreator(configuration).vendorControllerGet(loggedonuser, search, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Vendor} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorControllerPost(loggedonuser: string, body?: Vendor, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VendorAddUpdateResult> {
            const localVarFetchArgs = VendorApiFetchParamCreator(configuration).vendorControllerPost(loggedonuser, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Vendor} [body] 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorControllerPut(loggedonuser: string, body?: Vendor, key?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VendorSingleResult> {
            const localVarFetchArgs = VendorApiFetchParamCreator(configuration).vendorControllerPut(loggedonuser, body, key, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
         /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [vendorID] 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
         vendorDetailGet(loggedonuser: string, vendorID?: string, companyCode?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VendorSingleResult> {
            const localVarFetchArgs = VendorApiFetchParamCreator(configuration).vendorDetailGet(loggedonuser, vendorID, companyCode, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },

        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorKeyGet(loggedonuser: string, key?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VendorSingleResult> {
            const localVarFetchArgs = VendorApiFetchParamCreator(configuration).vendorKeyGet(loggedonuser, key, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} type 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorTypeTypeGet(type: string, loggedonuser: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = VendorApiFetchParamCreator(configuration).vendorTypeTypeGet(type, loggedonuser, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Array<VendorAddress>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorVendorAddressPost(loggedonuser: string, body?: Array<VendorAddress>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = VendorApiFetchParamCreator(configuration).vendorVendorAddressPost(loggedonuser, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Array<AddressContact>} [body] 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorVendorContactPut(loggedonuser: string, body?: Array<AddressContact>, key?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = VendorApiFetchParamCreator(configuration).vendorVendorContactPut(loggedonuser, body, key, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} vendorID 
         * @param {string} loggedonuser 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorVendorIDGet(vendorID: string, loggedonuser: string, companyCode?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VendorSingleResult> {
            const localVarFetchArgs = VendorApiFetchParamCreator(configuration).vendorVendorIDGet(vendorID, loggedonuser, companyCode, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [companyCode] 
         * @param {string} [materialOrService] 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorVendorListGet(loggedonuser: string, companyCode?: string, materialOrService?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = VendorApiFetchParamCreator(configuration).vendorVendorListGet(loggedonuser, companyCode, materialOrService, search, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * VendorApi - factory interface
 * @export
 */
export const VendorApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorControllerGet(loggedonuser: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return VendorApiFp(configuration).vendorControllerGet(loggedonuser, search, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Vendor} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorControllerPost(loggedonuser: string, body?: Vendor, options?: any) {
            return VendorApiFp(configuration).vendorControllerPost(loggedonuser, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Vendor} [body] 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorControllerPut(loggedonuser: string, body?: Vendor, key?: string, options?: any) {
            return VendorApiFp(configuration).vendorControllerPut(loggedonuser, body, key, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [vendorID] 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorDetailGet(loggedonuser: string, vendorID?: string, companyCode?: string, options?: any) {
            return VendorApiFp(configuration).vendorDetailGet(loggedonuser, vendorID, companyCode, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorKeyGet(loggedonuser: string, key?: string, options?: any) {
            return VendorApiFp(configuration).vendorKeyGet(loggedonuser, key, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} type 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorTypeTypeGet(type: string, loggedonuser: string, options?: any) {
            return VendorApiFp(configuration).vendorTypeTypeGet(type, loggedonuser, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Array<VendorAddress>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorVendorAddressPost(loggedonuser: string, body?: Array<VendorAddress>, options?: any) {
            return VendorApiFp(configuration).vendorVendorAddressPost(loggedonuser, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {Array<AddressContact>} [body] 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorVendorContactPut(loggedonuser: string, body?: Array<AddressContact>, key?: string, options?: any) {
            return VendorApiFp(configuration).vendorVendorContactPut(loggedonuser, body, key, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} vendorID 
         * @param {string} loggedonuser 
         * @param {string} [companyCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorVendorIDGet(vendorID: string, loggedonuser: string, companyCode?: string, options?: any) {
            return VendorApiFp(configuration).vendorVendorIDGet(vendorID, loggedonuser, companyCode, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [companyCode] 
         * @param {string} [materialOrService] 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorVendorListGet(loggedonuser: string, companyCode?: string, materialOrService?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
            return VendorApiFp(configuration).vendorVendorListGet(loggedonuser, companyCode, materialOrService, search, filter, top, skip, take, orderBy, options)(fetch, basePath);
        },
    };
};

/**
 * VendorApi - object-oriented interface
 * @export
 * @class VendorApi
 * @extends {BaseAPI}
 */
export class VendorApi extends BaseAPI {
    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [search] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendorApi
     */
    public vendorControllerGet(loggedonuser: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return VendorApiFp(this.configuration).vendorControllerGet(loggedonuser, search, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {Vendor} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendorApi
     */
    public vendorControllerPost(loggedonuser: string, body?: Vendor, options?: any) {
        return VendorApiFp(this.configuration).vendorControllerPost(loggedonuser, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {Vendor} [body] 
     * @param {string} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendorApi
     */
    public vendorControllerPut(loggedonuser: string, body?: Vendor, key?: string, options?: any) {
        return VendorApiFp(this.configuration).vendorControllerPut(loggedonuser, body, key, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [vendorID] 
     * @param {string} [companyCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendorApi
     */
    public vendorDetailGet(loggedonuser: string, vendorID?: string, companyCode?: string, options?: any) {
        return VendorApiFp(this.configuration).vendorDetailGet(loggedonuser, vendorID, companyCode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendorApi
     */
    public vendorKeyGet(loggedonuser: string, key?: string, options?: any) {
        return VendorApiFp(this.configuration).vendorKeyGet(loggedonuser, key, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} type 
     * @param {string} loggedonuser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendorApi
     */
    public vendorTypeTypeGet(type: string, loggedonuser: string, options?: any) {
        return VendorApiFp(this.configuration).vendorTypeTypeGet(type, loggedonuser, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {Array<VendorAddress>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendorApi
     */
    public vendorVendorAddressPost(loggedonuser: string, body?: Array<VendorAddress>, options?: any) {
        return VendorApiFp(this.configuration).vendorVendorAddressPost(loggedonuser, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {Array<AddressContact>} [body] 
     * @param {string} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendorApi
     */
    public vendorVendorContactPut(loggedonuser: string, body?: Array<AddressContact>, key?: string, options?: any) {
        return VendorApiFp(this.configuration).vendorVendorContactPut(loggedonuser, body, key, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} vendorID 
     * @param {string} loggedonuser 
     * @param {string} [companyCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendorApi
     */
    public vendorVendorIDGet(vendorID: string, loggedonuser: string, companyCode?: string, options?: any) {
        return VendorApiFp(this.configuration).vendorVendorIDGet(vendorID, loggedonuser, companyCode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [companyCode] 
     * @param {string} [materialOrService] 
     * @param {string} [search] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendorApi
     */
    public vendorVendorListGet(loggedonuser: string, companyCode?: string, materialOrService?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return VendorApiFp(this.configuration).vendorVendorListGet(loggedonuser, companyCode, materialOrService, search, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

}
//Personnel Requisition API
/**
 * 
 * @export
 * @interface PersonnelRequisition
 */
export interface PersonnelRequisition {
    
    
    // General
    prrNumber: string;
    createdBy: string;
    createdOn: Date;
    dateNeeded: Date;
    employeeType: string | boolean;
    companyCode: string;
    employingCompany: string
    projectNumber: string;
    projectName: string;
    chargeNumber: string;
    requestReason: string;
    replacementEmployee: string;
    replacingWho?: string;
    // Position Info
    employmentType: string;
    jobTitle: string;
    jobDescription: string;
    attachmentList: any[];
    additionalAttachmentFileList: any[];
    locationList: string;
    laborGroup: string;
    organization: string;
    vacancies: number;
    isHiringManager: boolean;
    hiringManager: number;
    department: string;
    // Additional Info
    wageType?: string;
    wageMaximum?: number;
    wageMinimum?: number;
    yearsExperience?: string;
    education?: string;
    travelRequired?: boolean;
    travelAmount?: string;
    requiredSkills?: string;
    requiredLicenses?: string;
    desiredSkills?: string;
    securityAccessRequirements?: string;
    specialSkillsTraining?: string;
    comments?: string;
    // Hidden fields
    requisitionType?: string | undefined;
    isCompleted?: string;
    prrTypeDetail?: EnPrrTypeDetail;
    personnelRequisitionGuid?: string;
    parentGuid?: string;
    status?: string;
    prrType?: string;
}

/**
 * 
 */
export interface PersonnelRequisitionSingleResult {
    /**
     * 
     * @type {Array<PersonnelRequisition>}
     * @memberof PersonnelRequisitionSingleResult
     */
    queryable?: Array<PersonnelRequisition>;
}

export class PersonnelRequisitionApi extends BaseAPI {
    /**
     * The function returns a function call to the PersonnelRequisitionApiFp API function, 
     * passing in the configuration object to set up the API connection.
     * 
     * @param {string} loggedonuser 
     * @param {string} [key] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonnelRequisitionApi
     */
    public personnelRequisitionApprovalFlowKeyGet(loggedonuser: string, key?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return PersonnelRequisitionApiFp(this.configuration).personnelRequisitionApprovalFlowKeyGet(loggedonuser, key, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [key] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonnelRequisitionApi
     */
    public personnelRequisitionApprovalFlowLogKeyGet(loggedonuser: string, key?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return PersonnelRequisitionApiFp(this.configuration).personnelRequisitionApprovalFlowLogKeyGet(loggedonuser, key, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * Approves a personnel requisition key based on specific parameters passed to it.
     * @param {string} loggedonuser 
     * @param {string} [key] 
     * @param {string} [comment] 
     * @param {number} [currentLevel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonnelRequisitionApi
     */
    public personnelRequisitionApproveKeyPost(loggedonuser: string, key?: string, comment?: string, currentStage?: number, options?: any) {
        return PersonnelRequisitionApiFp(this.configuration).personnelRequisitionApproveKeyPost(loggedonuser, key, comment, currentStage, options)(this.fetch, this.basePath);
    }

    /**
     * Removes an approver from a personnel requisition request.
     * @param {string} loggedonuser 
     * @param {string} [flowGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonnelRequisitionApi
     */
    public personnelRequisitionApproverDelete(loggedonuser: string, flowGuid?: string, options?: any) {
        return PersonnelRequisitionApiFp(this.configuration).personnelRequisitionApproverDelete(loggedonuser, flowGuid, options)(this.fetch, this.basePath);
    }

    /**
     * Sets approver to a personnel requisition request.
     * @param {string} loggedonuser 
     * @param {string} [requisitionGuid] 
     * @param {string} [approverGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonnelRequisitionApi
     */
    public personnelRequisitionApproverPost(loggedonuser: string, requisitionGuid?: string, approverGuid?: string, options?: any) {
        return PersonnelRequisitionApiFp(this.configuration).personnelRequisitionApproverPost(loggedonuser, requisitionGuid, approverGuid, options)(this.fetch, this.basePath);
    }

    /**
     * Appends an approver to a personnel requisition request.
     * @param {string} loggedonuser 
     * @param {string} [requisitionApprovelFlowGuid] 
     * @param {string} [approverGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonnelRequisitionApi
     */
    public personnelRequisitionApproverPut(loggedonuser: string, requisitionApprovelFlowGuid?: string, approverGuid?: string, options?: any) {
        return PersonnelRequisitionApiFp(this.configuration).personnelRequisitionApproverPut(loggedonuser, requisitionApprovelFlowGuid, approverGuid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [company]
     * @param {string} [actionFilter] 
     * @param {string} [search] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonnelRequisitionApi
     */
    public personnelRequisitionGet(loggedonuser: string, actionFilter?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return PersonnelRequisitionApiFp(this.configuration).personnelRequisitionGet(loggedonuser, actionFilter, search, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} prrGuid 
     * @param {string} loggedonuser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonnelRequisitionApi
     */
    public personnelRequisitionIntegrateAuthPrrGuidGet(prrGuid: string, loggedonuser: string, options?: any) {
        return PersonnelRequisitionApiFp(this.configuration).personnelRequisitionIntegrateAuthPrrGuidGet(prrGuid, loggedonuser, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonnelRequisitionApi
     */
    public personnelRequisitionKeyDelete(loggedonuser: string, key?: string, options?: any) {
        return PersonnelRequisitionApiFp(this.configuration).personnelRequisitionKeyDelete(loggedonuser, key, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonnelRequisitionApi
     */
    public personnelRequisitionKeyGet(loggedonuser: string, key?: string, options?: any) {
        return PersonnelRequisitionApiFp(this.configuration).personnelRequisitionKeyGet(loggedonuser, key, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {PersonnelRequisition} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonnelRequisitionApi
     */
    public personnelRequisitionPost(loggedonuser: string, body?: PersonnelRequisition, options?: any) {
        return PersonnelRequisitionApiFp(this.configuration).personnelRequisitionPost(loggedonuser, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {PersonnelRequisition} [body] 
     * @param {string} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonnelRequisitionApi
     */
    public personnelRequisitionPut(loggedonuser: string, body?: PersonnelRequisition, key?: string, options?: any) {
        return PersonnelRequisitionApiFp(this.configuration).personnelRequisitionPut(loggedonuser, body, key, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [key] 
     * @param {string} [comment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonnelRequisitionApi
     */
    public personnelRequisitionCancelPost(loggedonuser: string, key?: string, comment?: string, options?: any) {
        return PersonnelRequisitionApiFp(this.configuration).personnelRequisitionCancelPost(loggedonuser, key, comment, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [key] 
     * @param {string} [comment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonnelRequisitionApi
     */
    public personnelRequisitionRejectKeyPost(loggedonuser: string, key?: string, comment?: string, options?: any) {
        return PersonnelRequisitionApiFp(this.configuration).personnelRequisitionRejectKeyPost(loggedonuser, key, comment, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} parentGuid 
     * @param {string} loggedonuser 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonnelRequisitionApi
     */
    public personnelRequisitionRequisitionHistoryParentGuidGet(parentGuid: string, loggedonuser: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return PersonnelRequisitionApiFp(this.configuration).personnelRequisitionRequisitionHistoryParentGuidGet(parentGuid, loggedonuser, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonnelRequisitionApi
     */
    public personnelRequisitionRequisitionPermissionKeyGet(loggedonuser: string, key?: string, options?: any) {
        return PersonnelRequisitionApiFp(this.configuration).personnelRequisitionRequisitionPermissionKeyGet(loggedonuser, key, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [key] 
     * @param {string} [comment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonnelRequisitionApi
     */
    public personnelRequisitionReviseKeyPost(loggedonuser: string, key?: string, comment?: string, options?: any) {
        return PersonnelRequisitionApiFp(this.configuration).personnelRequisitionReviseKeyPost(loggedonuser, key, comment, options)(this.fetch, this.basePath);
    }

}
export const PersonnelRequisitionApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelRequisitionApprovalFlowKeyGet(loggedonuser: string, key?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PersonnelRequisitionApiFetchParamCreator(configuration).personnelRequisitionApprovalFlowKeyGet(loggedonuser, key, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelRequisitionApprovalFlowLogKeyGet(loggedonuser: string, key?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PersonnelRequisitionApiFetchParamCreator(configuration).personnelRequisitionApprovalFlowLogKeyGet(loggedonuser, key, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {string} [comment] 
         * @param {number} [currentLevel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelRequisitionApproveKeyPost(loggedonuser: string, key?: string, comment?: string, currentStage?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PersonnelRequisitionApiFetchParamCreator(configuration).personnelRequisitionApproveKeyPost(loggedonuser, key, comment, currentStage, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [flowGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelRequisitionApproverDelete(loggedonuser: string, flowGuid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PersonnelRequisitionApiFetchParamCreator(configuration).personnelRequisitionApproverDelete(loggedonuser, flowGuid, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [requisitionGuid] 
         * @param {string} [approverGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelRequisitionApproverPost(loggedonuser: string, requisitionGuid?: string, approverGuid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PersonnelRequisitionApiFetchParamCreator(configuration).personnelRequisitionApproverPost(loggedonuser, requisitionGuid, approverGuid, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [requisitionApprovelFlowGuid] 
         * @param {string} [approverGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelRequisitionApproverPut(loggedonuser: string, requisitionApprovelFlowGuid?: string, approverGuid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PersonnelRequisitionApiFetchParamCreator(configuration).personnelRequisitionApproverPut(loggedonuser, requisitionApprovelFlowGuid, approverGuid, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [company]
         * @param {string} [actionFilter] 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelRequisitionGet(loggedonuser: string, actionFilter?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PersonnelRequisitionApiFetchParamCreator(configuration).personnelRequisitionGet(loggedonuser, actionFilter, search, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} prrGuid 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelRequisitionIntegrateAuthPrrGuidGet(prrGuid: string, loggedonuser: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PersonnelRequisitionApiFetchParamCreator(configuration).personnelRequisitionIntegrateAuthPrrGuidGet(prrGuid, loggedonuser, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelRequisitionKeyDelete(loggedonuser: string, key?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PersonnelRequisitionApiFetchParamCreator(configuration).personnelRequisitionKeyDelete(loggedonuser, key, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelRequisitionKeyGet(loggedonuser: string, key?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PersonnelRequisitionSingleResult> {
            const localVarFetchArgs = PersonnelRequisitionApiFetchParamCreator(configuration).personnelRequisitionKeyGet(loggedonuser, key, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {PersonnelRequisition} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelRequisitionPost(loggedonuser: string, body?: PersonnelRequisition, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PersonnelRequisitionApiFetchParamCreator(configuration).personnelRequisitionPost(loggedonuser, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {PersonnelRequisition} [body] 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelRequisitionPut(loggedonuser: string, body?: PersonnelRequisition, key?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PersonnelRequisitionApiFetchParamCreator(configuration).personnelRequisitionPut(loggedonuser, body, key, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        personnelRequisitionCancelPost(loggedonuser: string, key?: string, comment?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PersonnelRequisitionApiFetchParamCreator(configuration).personnelRequisitionCancelPost(loggedonuser, key, comment,  options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelRequisitionRejectKeyPost(loggedonuser: string, key?: string, comment?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PersonnelRequisitionApiFetchParamCreator(configuration).personnelRequisitionRejectKeyPost(loggedonuser, key, comment, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} parentGuid 
         * @param {string} loggedonuser 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelRequisitionRequisitionHistoryParentGuidGet(parentGuid: string, loggedonuser: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PersonnelRequisitionApiFetchParamCreator(configuration).personnelRequisitionRequisitionHistoryParentGuidGet(parentGuid, loggedonuser, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelRequisitionRequisitionPermissionKeyGet(loggedonuser: string, key?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = PersonnelRequisitionApiFetchParamCreator(configuration).personnelRequisitionRequisitionPermissionKeyGet(loggedonuser, key, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelRequisitionReviseKeyPost(loggedonuser: string, key?: string, comment?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PersonnelRequisitionApiFetchParamCreator(configuration).personnelRequisitionReviseKeyPost(loggedonuser, key, comment, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

export const PersonnelRequisitionApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Builds the fetch request with paramaters and returns it as url
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelRequisitionApprovalFlowKeyGet(loggedonuser: string, key?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling personnelRequisitionApprovalFlowKeyGet.');
            }
            const localVarPath = `/PersonnelRequisition/ApprovalFlow({key})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelRequisitionApprovalFlowLogKeyGet(loggedonuser: string, key?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling personnelRequisitionApprovalFlowLogKeyGet.');
            }
            const localVarPath = `/PersonnelRequisition/ApprovalFlowLog({key})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {string} [comment] 
         * @param {number} [currentLevel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelRequisitionApproveKeyPost(loggedonuser: string, key?: string, comment?: string, currentStage?: number, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling personnelRequisitionApproveKeyPost.');
            }
            const localVarPath = `/PersonnelRequisition/Approve({key})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (comment !== undefined) {
                localVarQueryParameter['comment'] = comment;
            }

            if (currentStage !== undefined) {
                localVarQueryParameter['currentStage'] = currentStage;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [flowGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelRequisitionApproverDelete(loggedonuser: string, flowGuid?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling personnelRequisitionApproverDelete.');
            }
            const localVarPath = `/PersonnelRequisition/Approver`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (flowGuid !== undefined) {
                localVarQueryParameter['flowGuid'] = flowGuid;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [requisitionGuid] 
         * @param {string} [approverGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelRequisitionApproverPost(loggedonuser: string, requisitionGuid?: string, approverGuid?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling personnelRequisitionApproverPost.');
            }
            const localVarPath = `/PersonnelRequisition/Approver`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (requisitionGuid !== undefined) {
                localVarQueryParameter['requisitionGuid'] = requisitionGuid;
            }

            if (approverGuid !== undefined) {
                localVarQueryParameter['approverGuid'] = approverGuid;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [requisitionApprovelFlowGuid] 
         * @param {string} [approverGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelRequisitionApproverPut(loggedonuser: string, requisitionApprovelFlowGuid?: string, approverGuid?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling personnelRequisitionApproverPut.');
            }
            const localVarPath = `/PersonnelRequisition/Approver`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (requisitionApprovelFlowGuid !== undefined) {
                localVarQueryParameter['requisitionApprovelFlowGuid'] = requisitionApprovelFlowGuid;
            }

            if (approverGuid !== undefined) {
                localVarQueryParameter['approverGuid'] = approverGuid;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [company]
         * @param {string} [actionFilter] 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelRequisitionGet(loggedonuser: string, actionFilter?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling personnelRequisitionGet.');
            }
            //Building the request url
            const localVarPath = `/PersonnelRequisition`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            //loading any query parameters
            if (actionFilter !== undefined) {
                localVarQueryParameter['actionFilter'] = actionFilter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} prrGuid 
         * @param {string} loggedonuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelRequisitionIntegrateAuthPrrGuidGet(prrGuid: string, loggedonuser: string, options: any = {}): FetchArgs {
            // verify required parameter 'prGuid' is not null or undefined
            if (prrGuid === null || prrGuid === undefined) {
                throw new RequiredError('prrGuid', 'Required parameter prGuid was null or undefined when calling personnelRequisitionIntegrateAuthPrrGuidGet.');
            }
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling personnelRequisitionIntegrateAuthPrrGuidGet.');
            }
            const localVarPath = `/PersonnelRequisition/Integrate/Auth/${prrGuid}`
                .replace(`{${"prGuid"}}`, encodeURIComponent(String(prrGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelRequisitionKeyDelete(loggedonuser: string, key?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling personnelRequisitionKeyDelete.');
            }
            const localVarPath = `/PersonnelRequisition({key})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelRequisitionKeyGet(loggedonuser: string, key?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling personnelRequisitionKeyGet.');
            }
            const localVarPath = `/PersonnelRequisition({key})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            console.log()
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {PersonnelRequisition} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelRequisitionPost(loggedonuser: string, body?: PersonnelRequisition, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling personnelRequisitionPost.');
            }
            const localVarPath = `/PersonnelRequisition`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PersonnelRequisition" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {PersonnelRequisition} [body] 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelRequisitionPut(loggedonuser: string, body?: PersonnelRequisition, key?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling personnelRequisitionPut.');
            }
            const localVarPath = `/PersonnelRequisition`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PersonnelRequisition" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        personnelRequisitionCancelPost(loggedonuser: string, key?: string, comment?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling personnelRequisitionRejectKeyPost.');
            }
            const localVarPath = `/PersonnelRequisition/Cancel({key})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            console.log("Here 1")
            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }
            if (comment !== undefined) {
                localVarQueryParameter['comment'] = comment;
            }
            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelRequisitionRejectKeyPost(loggedonuser: string, key?: string, comment?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling personnelRequisitionRejectKeyPost.');
            }
            const localVarPath = `/PersonnelRequisition/Reject({key})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (comment !== undefined) {
                localVarQueryParameter['comment'] = comment;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} parentGuid 
         * @param {string} loggedonuser 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelRequisitionRequisitionHistoryParentGuidGet(parentGuid: string, loggedonuser: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            // verify required parameter 'parentGuid' is not null or undefined
            if (parentGuid === null || parentGuid === undefined) {
                throw new RequiredError('parentGuid', 'Required parameter parentGuid was null or undefined when calling personnelRequisitionRequisitionHistoryParentGuidGet.');
            }
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling personnelRequisitionRequisitionHistoryParentGuidGet.');
            }
            const localVarPath = `/PersonnelRequisition/RequisitionHistory/{parentGuid}`
                .replace(`{${"parentGuid"}}`, encodeURIComponent(String(parentGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelRequisitionRequisitionPermissionKeyGet(loggedonuser: string, key?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling personnelRequisitionRequisitionPermissionKeyGet.');
            }
            const localVarPath = `/PersonnelRequisition/RequisitionPermission({key})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [key] 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelRequisitionReviseKeyPost(loggedonuser: string, key?: string, comment?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling personnelRequisitionReviseKeyPost.');
            }
            const localVarPath = `/PersonnelRequisition/Revise({key})`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (comment !== undefined) {
                localVarQueryParameter['comment'] = comment;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
    
};

//Location API
/**
 * 
 * @export
 * @interface Location
 */
export interface Location {

    id?: string;
    stateCountry?: string;
    region?: string;
}

/**
 * 
 */
export interface LocationsnSingleResult {
    /**
     * 
     * @type {Array<Location>}
     * @memberof LocationsnSingleResult
     */
    queryable?: Array<Location>;
}

export class LocationApi extends BaseAPI {
    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [company]
     * @param {string} [actionFilter] 
     * @param {string} [search] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonnelRequisitionApi
     */
    public locationsGet(loggedonuser: string, actionFilter?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return LocationApiFp(this.configuration).locationsGet(loggedonuser, search, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

}
export const LocationApiFp = function (configuration?: Configuration) {
    return {
        locationsGet(loggedonuser: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = LocationsApiFetchParamCreator(configuration).locationsGet(loggedonuser, search, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }      
};

export const LocationsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [company]
         * @param {string} [actionFilter] 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsGet(loggedonuser: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling personnelRequisitionGet.');
            }
            //Building the request url
            const localVarPath = `/Locations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            //loading any query parameters

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
    
};
//Department API
/**
 * 
 * @export
 * @interface Department
 */
export interface Department {

    id?: string;
    name?: string;
}

/**
 * 
 */
export interface DepartmentsSingleResult {
    /**
     * 
     * @type {Array<Department>}
     * @memberof DepartmentsSingleResult
     */
    queryable?: Array<Department>;
}

export class DepartmentApi extends BaseAPI {
    /**
     * 
     * @param {string} loggedonuser 
     * @param {string} [company]
     * @param {string} [actionFilter] 
     * @param {string} [search] 
     * @param {string} [filter] 
     * @param {number} [top] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonnelRequisitionApi
     */
    public departmentsGet(loggedonuser: string, actionFilter?: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any) {
        return departmentApiFp(this.configuration).departmentsGet(loggedonuser, search, filter, top, skip, take, orderBy, options)(this.fetch, this.basePath);
    }

}
export const departmentApiFp = function (configuration?: Configuration) {
    return {
        departmentsGet(loggedonuser: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = departmentsApiFetchParamCreator(configuration).departmentsGet(loggedonuser, search, filter, top, skip, take, orderBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }      
};

export const departmentsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        
        /**
         * 
         * @param {string} loggedonuser 
         * @param {string} [company]
         * @param {string} [actionFilter] 
         * @param {string} [search] 
         * @param {string} [filter] 
         * @param {number} [top] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentsGet(loggedonuser: string, search?: string, filter?: string, top?: number, skip?: number, take?: number, orderBy?: string, options: any = {}): FetchArgs {
            // verify required parameter 'loggedonuser' is not null or undefined
            if (loggedonuser === null || loggedonuser === undefined) {
                throw new RequiredError('loggedonuser', 'Required parameter loggedonuser was null or undefined when calling personnelRequisitionGet.');
            }
            //Building the request url
            const localVarPath = `/Departments`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            //loading any query parameters

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (top !== undefined) {
                localVarQueryParameter['Top'] = top;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (loggedonuser !== undefined && loggedonuser !== null) {
                localVarHeaderParameter['loggedonuser'] = String(loggedonuser);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
    
};