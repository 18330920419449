import { Form } from "react-bootstrap";

export default function FormLabelMutedText(props) {
  return (
    <Form.Label className="text-muted text-truncate px-sm-3 w-100" column>
      {props.value}
    </Form.Label>
  );
}

export function FormLabelMutedTextJobDescriptionAndComments(props) {
  return (
    <Form.Label className="text-muted px-sm-3 w-100 job-description-and-comments" column>
      {props.value}
    </Form.Label>
  );
}

export function FormLabelMutedFullText(props) {
  return (
    <Form.Label className="text-muted px-sm-3 w-100" column>
      {props.value}
    </Form.Label>
  );
}

export function FormLabelMutedAmount(props) {
  return (
    <span className="text-muted text-truncate p-2 d-block">{props.value}</span>
  );
}
