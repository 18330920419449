import { XtField } from "nwcommon";
import { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";

import { IXtContext, XtContext } from "../../../../../Xt/Contexts/XtContext";
import { DateUtil } from "../../../../../Xt/Utils/DateUtil";
import FormLabelMutedText from "../../PurchaseRequisition/Common/FormLabelMutedText";
import FormField, { RequiredFormField } from "../Components/FormField";
import PopOverIcon from "../Components/PopOverIcon";
import { EnPrrAction, State } from "./PrrReducer";
import { employeeTypes, requestReasons } from "../Interfaces/Constants";
import {
  getCompanyList,
  getProjectList,
  getChargeCodeList,
} from "../../../../../api/react-query";
import { MINUTE } from "../../../../../utils/constants";

export default function NwGeneralInfo(props) {
  const state: State = props.state;
  const dispatch: any = props.dispatch;

  const context: IXtContext = useContext(XtContext);

  const token = context?.tokens?.accessToken;
  const username = context?.loggedOnUser?.username;
  const companyCode = state?.companyCode;
  const isIndirect = state?.employeeType === "Indirect";

  const {
    data: companyList,
    isLoading: isCompanyListLoading,
    isFetching: isCompanyListFetching,
  } = useQuery(["companyList", token, username], getCompanyList, {
    enabled: !!token && !!username,
    placeholderData: [],
    staleTime: Infinity,
    cacheTime: 20 * MINUTE,
  });

  const {
    data: projectList,
    isLoading: isProjectListLoading,
    isFetching: isProjectListFetching,
  } = useQuery(["projectList", token, username, companyCode], getProjectList, {
    enabled: !!token && !!username && !!companyCode && !isIndirect,
    placeholderData: [],
  });

  const {
    data: chargeCodeList,
    isLoading: isChargeCodeListLoading,
    isFetching: isChargeCodeListFetching,
  } = useQuery(
    ["chargeCodeList", token, username, companyCode],
    getChargeCodeList,
    {
      enabled: !!token && !!username && !!companyCode && isIndirect,
      placeholderData: [],
    }
  );

  const employeeTypeInfo = (
    <PopOverIcon header="Info">
      <div>
        <p>Direct - Personnel hired directly for a North Wind project.</p>
        <p>
          Indirect - Personnel considered Overhead (OVHD) or General &
          Administrative (G&A).
        </p>
      </div>
    </PopOverIcon>
  );

  return (
    <>
      <Row>
        <Col>
          <FormField label="PRR No.">
            <FormLabelMutedText value={state?.prrNumber ?? "Auto generated"} />
          </FormField>

          <FormField label="Created By">
            <FormLabelMutedText
              value={
                state?.employeeName
                  ? state?.employeeName
                  : context.loggedOnUser && context.loggedOnUser.displayname
              }
            />
          </FormField>

          <FormField label="Date Created">
            <FormLabelMutedText
              value={DateUtil.formatmmDdYYY(state?.createdOn || new Date())}
            />
          </FormField>

          <RequiredFormField label="Date Needed">
            <XtField
              type="datepicker"
              name="dateNeeded"
              value={new Date(state?.dateNeeded) || new Date()}
              required={true}
              errormessage="Date Needed is required and cannot be in the past."
              minDate={new Date()}
              weekNumber={false}
              onChange={(e) => {
                dispatch({
                  type: EnPrrAction.DATE_NEEDED_CHANGE,
                  payload: e?.value ? e.value : undefined,
                });
              }}
            />
          </RequiredFormField>
        </Col>
        <Col>
          <RequiredFormField label="Employee Type" info={employeeTypeInfo}>
            <XtField
              type="radiogroup"
              name="employeeType"
              layout="horizontal"
              textField="text"
              items={employeeTypes}
              value={
                state?.employeeType !== undefined
                  ? state?.employeeType === "Direct"
                    ? employeeTypes[0]?.text
                    : employeeTypes[1]?.text
                  : undefined
              }
              onChange={(e) => {
                dispatch({
                  type: EnPrrAction.EMPLOYEE_TYPE_CHANGE,
                  payload: e?.value ? e.value : undefined,
                });
              }}
              required={true}
              errormessage="Employment Type is required"
            />
          </RequiredFormField>

          <RequiredFormField label="Employing Company">
            <XtField
              type="dropdown"
              name="companyCode"
              items={companyList}
              textField="text"
              value={
                companyList.find((c) => c?.value === state?.companyCode) || null
              }
              onChange={(e) => {
                dispatch({
                  type: EnPrrAction.COMPANY_CODE_CHANGE,
                  payload: {
                    companyCode: e?.value ? e.value.value : undefined,
                    companyBambooName: e?.value
                      ? e.value.bambooName
                      : undefined,
                    employingCompany: e?.value ? e.value.text : undefined,
                  },
                });
              }}
              required={true}
              errormessage="Company is required"
              loading={isCompanyListLoading || isCompanyListFetching}
            />
          </RequiredFormField>

          {isIndirect && companyCode && (
            <>
              <RequiredFormField label="Charge No.">
                <XtField
                  type="dropdown"
                  name="chargeNumber"
                  items={chargeCodeList}
                  value={
                    chargeCodeList.find(
                      (c) => c.value === state?.projectNumber
                    ) || null
                  }
                  textField="text"
                  required={true}
                  errormessage="Charge number is required"
                  onChange={(e: any) =>
                    dispatch({
                      type: EnPrrAction.PROJECT_NUMBER_CHANGE,
                      payload: e?.value ? e.value : null,
                    })
                  }
                  loading={isChargeCodeListFetching || isChargeCodeListLoading}
                />
              </RequiredFormField>
            </>
          )}

          {!isIndirect && companyCode && (
            <>
              <RequiredFormField label="Project No.">
                <XtField
                  type="dropdown"
                  name="projectNumber"
                  items={projectList}
                  value={
                    projectList.find((c) => c.value === state?.projectNumber) ||
                    null
                  }
                  textField="text"
                  required={true}
                  errormessage="Project number is required"
                  onChange={(e) => {
                    dispatch({
                      type: EnPrrAction.PROJECT_NUMBER_CHANGE,
                      payload: e?.value
                        ? e.value
                        : {
                            value: undefined,
                            accountGrpCode: e?.accountGrpCode,
                          },
                    });
                  }}
                  loading={isProjectListLoading || isProjectListFetching}
                />
              </RequiredFormField>
            </>
          )}

          <RequiredFormField label="Reason for Request">
            <XtField
              type="radiogroup"
              name="requestReason"
              layout="horizontal"
              items={requestReasons}
              value={
                requestReasons.find((c) => c.value === state?.requestReason)
                  ?.text || undefined
              }
              onChange={(e) => {
                dispatch({
                  type: EnPrrAction.REASON_FOR_REQUEST_CHANGE,
                  payload: e?.value ? e.value : undefined,
                });
              }}
              required={true}
              errormessage="Reason for request is required"
            />
          </RequiredFormField>

          {state?.requestReason === "Replacement" && (
            <RequiredFormField label="Replacement">
              <XtField
                type="text"
                name="replacementEmployee"
                textField="text"
                value={state?.replacementEmployee || ""}
                onChange={(e) => {
                  dispatch({
                    type: EnPrrAction.REPLACEMENT_EMPLOYEE_CHANGE,
                    payload: e?.target?.value,
                  });
                }}
                required={true}
                errormessage="Replacement employee is required"
              />
            </RequiredFormField>
          )}
        </Col>
      </Row>
    </>
  );
}
