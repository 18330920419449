import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Col, Row } from "react-bootstrap";

import AttachmentListReadOnly from "../../PurchaseRequisition/ApprovalView/AttachmentListReadOnly";
import FormField from "../Components/FormField";
import FormLabelMutedText, { FormLabelMutedTextJobDescriptionAndComments } from "../Components/FormLabelMutedText";

export default function PrrPositionInfoReadOnly(props) {
  const { prr } = props;

  return (
    <>
      <Row>
        <Col md={6}>
          <FormField label="Employment Type">
            <FormLabelMutedText value={prr?.employmentType} />
          </FormField>
          <FormField label="Job Title">
            <FormLabelMutedText value={prr?.jobTitle} />
          </FormField>
          <FormField label="Job Description">
            <FormLabelMutedTextJobDescriptionAndComments value={prr?.jobDescription} />
          </FormField>
          <FormField label="Attachments">
            <AttachmentListReadOnly files={prr?.attachmentList} />
          </FormField>
          <Grid
            data={prr?.locationList ? prr?.locationList : []}
            style={{ marginTop: "20px" }}
          >
            <GridColumn field="city" title="City" />
            <GridColumn field="state" title="State/Country" />
          </Grid>
        </Col>
        <Col md={6}>
          <FormField label="Labor Group">
            <FormLabelMutedText value={prr?.laborGroup} />
          </FormField>
          <FormField label="# of Vacancies">
            <FormLabelMutedText value={prr?.vacancies} />
          </FormField>
          <FormField label="Hiring Manager">
            <FormLabelMutedText value={prr?.hiringManagerDisplayName} />
          </FormField>
          {/* Commented out per mvp model
              Will be added back in future release 
              Reference: https://dev.azure.com/NorthWindGroup/NW-ESS/_workitems/edit/495/
          <FormField label="Department">
            <FormLabelMutedText value={prr?.department} />
          </FormField> */}
        </Col>
      </Row>
    </>
  );
}
