import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import { XtForm } from "nwcommon";
import { useContext, useEffect, useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import { Redirect, useParams } from "react-router-dom";
import XtAccordianItem from "../PurchaseRequisition/Common/XtAccordianItem";
import CpIntegrationPurchaseOrderDetails from "./CpIntegrationPurchaseOrderDetails";
import CpIntegrationVendorDetails from "./CpIntegrationVendorDetails";

import { PrApiService } from "../PurchaseRequisition/Common/PrApiService";

import { useMutation } from "@tanstack/react-query";
import { savePrWithPoNumber } from "../../../../api/react-query";
import {
	APIFactory,
	PurchaseOrderApi,
	PurchaseOrderItemPost,
	PurchaseOrderPost,
	PurchaseRequisitionApi,
	VendorPost,
} from "../../../../Xt/ApiClients";
import { IXtContext, XtContext } from "../../../../Xt/Contexts/XtContext";
import { Loading } from "../../../../Xt/Layout/Loading";
import { XtBreadcrumbs } from "../../../../Xt/Layout/XtBreadcrumbs";
import { ErrorUtil } from "../../../../Xt/Utils/ErrorUtil";
import LoadingDailog, {
	EnConfirmationMessageType,
	LoadingDailogProps,
} from "../../Dialogs/Common/LoadingDailog";
import NwPurchaseItemReadOnly from "../PurchaseRequisition/ApprovalView/PurchaseItemReadOnly";
import { enum_request_type } from "../PurchaseRequisition/Common/NwPrConstants";
import CpIntegrationDefaults from "./CpIntegrationDefaults";

declare var window: any;

export default function CostPointIntegrationLandingPage() {
	let { id } = useParams();
	let factory: APIFactory;

	const context: IXtContext = useContext(XtContext);

	const [vendorId, setVendorId] = useState(null);
	const redirectTo = { shouldRedirect: false, url: "" };
	const [redirect, setRedirect] = useState(redirectTo);
	const [pr, setPr] = useState(null);
	const [po, setPo] = useState(null);
	const [loading, setLoading] = useState<LoadingDailogProps>({ show: false });
	const [pageLoading, setPageLoading] = useState(0);

	const [selectedPaymentAddress, setSelectedPaymentAddress] = useState(null);
	const [selectedOrderAddress, setSelectedOrderAddress] = useState(null);
	const [selectedPaymentTerms, setSelectedPaymentTerm] = useState(null);
	const [selectedApAccount, setSelectedAppAccount] = useState(null);
	const [selectedCountry, setSelectedCountry] = useState(null);
	const [orderDate, setOrderDate] = useState(new Date());
	const [creditCardIntegration, setCreditCardIntegration] = useState(false);
	const [prType, setPrType] = useState(null);

	useEffect(() => {
		if (id && context?.loggedOnUser?.username) {
			let prApi: PurchaseRequisitionApi = factory.create(
				PurchaseRequisitionApi,
			);
			let poApi: PurchaseOrderApi = factory.create(PurchaseOrderApi);
			prApi
				.purchaseRequisitionIntegrateAuthPrGuidGet(
					id,
					context?.loggedOnUser?.username,
				)
				.then((d) => {
					if (id && context?.loggedOnUser) {
						setPageLoading((pageLoading) => pageLoading + 1);
						PrApiService.getPrByGuid(context, prApi, id)
							.then((prData) => {
								let prFromApi: any = prData;
								setPr(prFromApi);
								if (
									prFromApi?.prType === enum_request_type.PO_CHANGE_REQUEST &&
									prFromApi?.poNumber
								) {
									setPageLoading((pageLoading) => pageLoading + 1);
									poApi
										.purchaseOrderPoIDGet(
											prFromApi?.poNumber,
											context?.loggedOnUser?.username,
										)
										.then(async (poData) => {
											var poDataFromApi: any = poData;

											const updatedLineItems = prFromApi?.purchaseItem.map(
												(p) => {
													const poItem = poDataFromApi.poLineItemList.find(
														(po) => po.lineNumber === p.lineNumber,
													);
													return {
														...p,
														itemId: poItem?.itemID,
													};
												},
											);

											//console.log(updatedLineItems)
											// setPr({...pr,purchaseItem:updatedLineItems});
											setPo(poDataFromApi);
											setOrderDate(new Date(poDataFromApi.orderDate));
											setPr({ ...prFromApi, purchaseItem: updatedLineItems });
										})
										.catch((e) => console.log(e))
										.finally(() =>
											setPageLoading((pageLoading) => pageLoading - 1),
										);
								}

								setPrType(prData.prType);
								prApi
									.purchaseRequisitionPrGuidCostpointIntegrationSkipableGet(
										prFromApi.purchaseRequisitionGuid,
										context?.loggedOnUser?.username,
									)
									.then((result) => {
										return result.json();
									})
									.then((data) => setCreditCardIntegration(data?.result))
									.catch((err) => console.log(err));
							})
							.finally(() => setPageLoading((pageLoading) => pageLoading - 1));
					}
				})
				.catch((d) => {
					if (d.status === 403 || d.status === 401) {
						window.location = "/Error403";
					}
				});
		}
	}, [context?.loggedOnUser?.username]);

	const onItemIdChange = (itemId, lineItemIdNumber) => {
		var itemIndex = pr?.purchaseItem.findIndex(
			(x) => x.lineItemNumber === lineItemIdNumber,
		);

		if (pr.purchaseItem[itemIndex]["itemId"] === itemId) {
			pr.purchaseItem[itemIndex] = {
				...pr?.purchaseItem[itemIndex],
				isUpdated: pr.purchaseItem[itemIndex]["isUpdated"],
			};
		} else {
			pr.purchaseItem[itemIndex] = {
				...pr?.purchaseItem[itemIndex],
				itemId: itemId,
				isUpdated: true,
			};
		}
		setPr(pr);
	};

	const onSubmit = async (e) => {
		try {
			setLoading({
				show: true,
				message: ["Please wait for costpoint integration....."],
				messageType: EnConfirmationMessageType.Wait,
			});
			let oldVendorID = pr?.vendorCode;
			if (e.postData.vendorCode === "") oldVendorID = "";
			const newVendor: VendorPost = {
				apAccountDesc: selectedApAccount,
				autoVoucherFlag: e.postData?.allowAutoVoucher === "true" ? "Y" : "N", //single char value i.e Y/N
				cageCD: e.postData.cageCD,

				cashAccountDesc: e.postData.cashAccountDesc,
				dunsNo: e.postData.dunsNumber,
				voucherPayVendorFlag:
					e.postData?.allowEditToPayOnVoucher === "true" ? "Y" : "N", //single char value i.e Y/N
				termsCode: selectedPaymentTerms,
				vendor1099Name: e.postData.vendor1099Name,
				vendorID: e.postData.vendorID,
				oldVendorID: oldVendorID,
				vendorLongName: e.postData.vendorLongName,
				vendorName: e.postData.vendName,
				vendorAddressList: [
					{
						addressCode: e.postData.addressCode,
						oldAddressCode: e.postData.vendAddressCode,
						cityName: e.postData.vendCity,
						countryCode: e.postData.vendCountryCode,
						emailID: e.postData.vendContactEmail, //e.postData.emailID,
						addressLine1: e.postData.vendAddress1,
						addressLine2: e.postData.vendAddress2,
						addressLine3: e.postData.vendAddress3,
						mailStateCode: e.postData.vendState,
						otherPhoneID: "",
						phoneID: e.postData.vendContactPhone, // e.postData.phoneID,
						postalCode: e.postData.vendZipCode,
						orderAddressCode: selectedOrderAddress, //single char value i.e D
						pmtAddressCode: selectedPaymentAddress, //single char value i.e D
						contactList: [
							{
								firstName: e.postData.vendContactFirstname,
								lastName: e.postData.vendContactLastname,
								titleName: "",
								emailID: e.postData.vendContactEmail,
								phoneID: e.postData.vendContactPhone,
								sequenceNo: e.postData.sequenceNo,
								tranType: e.postData.tranType,
							},
						],
					},
				],
			};

			// const maxDateRecord = pr?.purchaseItem.reduce(function (prev, current) {
			//  return prev.endDate > current.endDate ? prev : current;
			// });

			//console.log(maxDateRecord.endDate);

			///console.log(e.postData);

			const dueDate = new Date(pr?.dateNeeded);

			let poApi: PurchaseOrderApi = factory.create(PurchaseOrderApi);

			const lineItems: PurchaseOrderItemPost[] = pr?.purchaseItem.map((p) => {
				// console.log(p)
				return {
					itemID: p.itemId, //1120 for now, other is not accepted by cp
					revisionID: "Rev 1",
					Quantity: p.quantity,
					origDueDate: dueDate,
					autoVoucherFlag: e.postData?.allowAutoVoucher == true ? "Y" : "N", //e.postData.allowAutoVoucher, //single char value i.e Y/N
					desiredDate: dueDate,
					polnDueDate: dueDate,
					orderDate: dueDate,
					performanceEndDate: p.endDate,
					performanceStartDate: p.startDate,
					lineDesc: p.description,
					lineNumber: p.lineNumber,
					lineTax: "",
					unitAmount: p.unitPrice,
					netAmount: p.unitPrice,
					extraAmount: p.unitPrice,
					lineCode: p.unit,
					confirmFlag: "N", //single char value i.e Y/N
					requiredFlag: "N", //single char value i.e Y/N
					inspFlag: "N", //single char value i.e Y/N
					tranType: p.tranType,
					isUpdated: p.isUpdated,
					lineItemAccountList: [
						{
							projID: p.chargeCode, //charge code from line item
							accountID: p.accountNumber, //
							orgID: p.orgId,
						},
					],
				};
			});

			const purchaseOrder: PurchaseOrderPost = {
				buyerID: e.postData.buyer,
				desiredDate: dueDate,
				dueDate: dueDate,
				orderDate: new Date(e.postData.orderDate),
				poStatusType: "O", //single char value i.e O
				taxableType: "I", //single char value i.e I
				poType: "P", //single char value i.e P
				termsDC: selectedPaymentTerms,
				//vendorID: "XTVENDOR01",
				vendorID: e.postData.vendorID,
				vendor: newVendor,
				poNumber: pr?.poNumber,
				purchaseOrderItemList: lineItems,
			};

			let poResult = null;

			if (pr?.prType === enum_request_type.PO_CHANGE_REQUEST)
				poResult = await poApi.purchaseOrderPut(
					context?.loggedOnUser?.username,
					purchaseOrder,
					pr?.prNumber,
				);
			else
				poResult = await poApi.purchaseOrderPost(
					context?.loggedOnUser?.username,
					purchaseOrder,
					pr?.prNumber,
				);

			if (poResult.ok) {
				let poResponseData = await poResult.json();
				if (poResponseData.isSucceed)
					setDailogSuccess(
						[
							"Purchase order " +
								poResponseData?.poNumber +
								" has been created.",
						],
						poResponseData?.poNumber,
						"Costpoint Integration Successful",
					);
				else {
					//partial success
					if (
						poResponseData?.poNumber !== "" ||
						poResponseData?.returnValue !== ""
					) {
						setDailogWarningWithCostPointSuccess(
							ErrorUtil.getErrorMessageFromCostPointResponse(
								poResponseData.responseMsg,
							),
							poResponseData?.poNumber,
						);
					} else
						setDailogError(
							ErrorUtil.getErrorMessageFromCostPointResponse(
								poResponseData.responseMsg,
							),
						);
				}
			} else {
				setDailogError([
					"Costpoint Server Might not be available, please try again later.",
				]);
			}
		} catch (error) {
			setDailogError([
				"Costpoint Server Might not be available, please try again later.",
			]);
		}
	};

	const setDailogWarningWithCostPointSuccess = (
		message: string[],
		poNumber: string,
	) => {
		setLoading({
			show: true,
			title: "Costpoint Integration Successful with warnings",
			messageType: EnConfirmationMessageType.Warning,
			message: message,
			successMessageOnWarning:
				"Purchase order " + poNumber + " has been created.",
			onClose: (e) => {
				setRedirect({ shouldRedirect: true, url: "/po/details/" + poNumber });
				setLoading({ show: false });
			},
		});
	};

	const setDailogSuccess = (
		message: string[],
		poNumber: string,
		title: string,
	) => {
		setLoading({
			show: true,
			message: message,
			title: title,
			messageType: EnConfirmationMessageType.Success,
			onClose: (e) => {
				setRedirect({ shouldRedirect: true, url: "/po/details/" + poNumber });
				setLoading({ show: false });
			},
		});
	};

	const setSuccessDialogForPoNumberUpdate = (
		message: string[],
		poNumber: string,
		title: string,
	) => {
		setLoading({
			show: true,
			message: message,
			title: title,
			messageType: EnConfirmationMessageType.Success,
			onClose: () => {
				setRedirect({ shouldRedirect: true, url: "/pr" });
				setLoading({ show: false });
			},
		});
	};

	const setDailogError = (errorMessage: string[]) => {
		setLoading({
			show: true,
			title: "Error",
			messageType: EnConfirmationMessageType.Error,
			message: errorMessage,
			onClose: (e) => {
				setLoading({ show: false });
			},
		});
	};

	const activeSections = ["0", "1", "2", "3"];
	const breadCrumbs = {
		breadCrumbs: [
			{
				href: "/pr",
				text: "Purchase Requisitions List",
				isActive: false,
			},
			{
				href: "/cp/landingpage/",
				text: "Costpoint Integration Details",
				isActive: true,
			},
		],
	};
	const setCreditCardPayment = async () => {
		const prApi: PurchaseRequisitionApi = factory.create(
			PurchaseRequisitionApi,
		);
		await prApi.purchaseRequisitionPrGuidSkipIntegrationPost(
			pr.purchaseRequisitionGuid,
			context?.loggedOnUser?.username,
		);
		setRedirect({ shouldRedirect: true, url: "/" });
	};

	const setSyncCostPointAmount = async () => {
		const poApi: PurchaseOrderApi = factory.create(PurchaseOrderApi);
		try {
			setLoading({
				show: true,
				message: ["Please wait for Costpoint sync..."],
				messageType: EnConfirmationMessageType.Wait,
			});
			let poRes = await poApi.purchaseOrderPoNumberPrNumberCompanyCodeSyncPost(
				pr.poNumber,
				pr.prNumber,
				pr.companyCode,
				context?.loggedOnUser?.username,
			);
			if (poRes.ok) {
				setDailogSuccess(
					["Purchase order " + pr?.poNumber + " has been synced."],
					pr?.poNumber,
					"Costpoint Sync Successful",
				);
			} else {
				setDailogError(["Costpoint sync error."]);
			}
		} catch (error) {
			setDailogError([
				"Costpoint Server Might not be available, please try again later.",
			]);
		}
	};

	const savePrWithPoNumberMutation = useMutation(savePrWithPoNumber, {
		onMutate: () => {
			setLoading({
				show: true,
				message: ["Updating PO Number..."],
				messageType: EnConfirmationMessageType.Wait,
			});
		},
		onSuccess: () => {
			setSuccessDialogForPoNumberUpdate(
				[`PR has been saved successfully with PO Number ${pr?.poNumber}.`],
				pr?.poNumber,
				"Success",
			);
		},
		onError: (error) => {
			console.log(error);
			setDailogError(["Internal Server Error. Please contact support."]);
		},
	});

	const handleSave = () => {
		savePrWithPoNumberMutation.mutate({
			token: context?.tokens.accessToken,
			loggedOnUserName: context?.loggedOnUser?.username,
			pr: pr,
			prGuid: pr?.purchaseRequisitionGuid,
		});
	};

	const handlePoNumberChange = (e: string) => {
		setPo({ ...po, poNumber: e });
		setPr({ ...pr, poNumber: e });
	};

	return (
		<>
			{redirect.shouldRedirect && <Redirect push to={redirect.url} />}
			<APIFactory
				ref={(e) => {
					factory = e;
				}}
			/>

			<XtBreadcrumbs {...breadCrumbs} />

			<div className="pr-create-new">
				<XtForm onSubmit={onSubmit}>
					<Accordion defaultActiveKey={activeSections} alwaysOpen>
						<XtAccordianItem header="" eventKey="0">
							<CpIntegrationPurchaseOrderDetails
								prNumber={pr?.prNumber}
								orderDate={orderDate}
								onOrderDateChange={setOrderDate}
								onPoNumberChange={handlePoNumberChange}
								companyCode={pr?.companyCode}
								buyerId={po?.buyerID}
								poNumber={po?.poNumber}
								prType={pr?.prType}
							/>
						</XtAccordianItem>
						<XtAccordianItem header="Vendor " eventKey="1">
							<CpIntegrationVendorDetails
								vendorCode={pr?.vendorCode}
								onVendorIdChange={setVendorId}
								onPaymentAddressChagne={setSelectedPaymentAddress}
								onOrderAddressChagne={setSelectedOrderAddress}
								onCountryChange={setSelectedCountry}
								selectedOrderAddress={selectedOrderAddress}
								selectedPaymentAddress={selectedPaymentAddress}
								selectedCountry={selectedCountry}
								companyCode={pr?.companyCode}
								requisitionType={pr?.requisitionType}
								addressContactGuid={pr?.addressContactGuid}
								vendorAddressGuid={pr?.vendorAddressGuid}
							/>
						</XtAccordianItem>
						<XtAccordianItem header="Defaults " eventKey="2">
							<CpIntegrationDefaults
								vendorId={vendorId}
								companyCode={pr?.companyCode}
								selectedApAccount={selectedApAccount}
								onApAccountChange={setSelectedAppAccount}
								selectedPaymentTerms={selectedPaymentTerms}
								onPymentTermChange={setSelectedPaymentTerm}
							/>
						</XtAccordianItem>

						<XtAccordianItem header="LINE ITEM" eventKey="3">
							<NwPurchaseItemReadOnly
								pr={pr}
								IsLandingpage={true}
								onItemIdChange={onItemIdChange}
							/>
						</XtAccordianItem>
					</Accordion>
					<DialogActionsBar layout="end">
						{prType !== "PO Change Request" && (
							<Button type="button" variant="primary" onClick={handleSave}>
								Save
							</Button>
						)}

						<Button
							type="button"
							variant="secondary"
							onClick={() => setRedirect({ shouldRedirect: true, url: "/" })}
							disabled={loading?.show}
						>
							Cancel
						</Button>

						<>
							{creditCardIntegration && (
								<Button
									type="button"
									variant="primary"
									onClick={() => setCreditCardPayment()}
									disabled={loading?.show}
								>
									Completed by Credit Card
								</Button>
							)}
						</>
						{prType === "PO Change Request" ? (
							<Button
								type="button"
								variant="primary"
								onClick={() => setSyncCostPointAmount()}
								disabled={loading?.show}
							>
								Costpoint Sync &darr;
							</Button>
						) : null}
						<>
							<Button
								type="submit"
								variant="primary"
								id="submit"
								disabled={loading?.show}
							>
								Submit To Costpoint
							</Button>
						</>
					</DialogActionsBar>
				</XtForm>
				{loading && loading.show && <LoadingDailog {...loading} />}
				{pageLoading > 0 && <Loading />}
			</div>
		</>
	);
}
